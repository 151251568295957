import React, { useEffect, useState } from 'react';

import { Button, Input, Panel, Radio, Row, Select, Switch, Toast } from '@optimuminterfaces/revex-react-components/dist/components';
import { translation as t } from '@optimuminterfaces/revex-react-components/dist/translation/index';
import { copyObjectJSON } from '@optimuminterfaces/revex-react-components/dist/utils/DeveloperUtil';

import styles from './New.module.scss';
import { SituationType } from '../../../../models/SituationType';
import { fetchESocial, postSituationType } from '../../../../services/situationTypes.services';

interface NewProps{
	Container?: any,
	noPanel?: boolean,
	saveAction: Function,
	cancelAction: Function
}

const initialOptions = [
    { key: '1', value: t('SHADOWCAT.SITUATION_TYPE.NO_LINK_ESOCIAL'), selected: true },
    { key: '2', value: t('SHADOWCAT.SITUATION_TYPE.LINK_ESOCIAL'), selected: false }
];

const initialOptionsEventType = [
    { key: 'ALLOWANCE', value: t('SHADOWCAT.SITUATION_TYPE.EVENT_TYPE.ALLOWANCE'), selected: true },
    { key: 'PAYMENT', value: t('SHADOWCAT.SITUATION_TYPE.EVENT_TYPE.PAYMENT'), selected: false },
	{ key: 'DISCOUNT_ON_BANK_OF_HOURS', value: t('SHADOWCAT.SITUATION_TYPE.EVENT_TYPE.DISCOUNT_ON_BANK_OF_HOURS'), selected: false }
];

const New = ({noPanel,saveAction, cancelAction, Container='div'}: NewProps) => {
	const [buttonDisabled, setButtonDisabled] = useState(false);
	const [buttonLoading, setButtonLoading] = useState(false);
	const [situationType, setSituationType] = useState<SituationType>({name:'', code:'', workdaysOnly: false, offScale: true, deleteCompensationDate: true});
	const [eSocialValue, setESocialValue] = useState<any>();
	const [eventTypeValue, setEventTypeValue] = useState<any>();
	const [optionsESocial, setOptionsESocial] = useState<any[]>();
	const [options, setOptions] = useState(initialOptions);
	const [optionsEventType, setOptionsEventType] = useState(initialOptionsEventType);
	const [toastAlert, setToastAlert] = useState<{show:boolean, title: string, message: string, danger: boolean}>({show: false, title: '', message: '', danger: false})

	useEffect(() => {
		(async () => {
			//setSituationTypeStatus('waiting');
			try{
				const jsonReturned =  await fetchESocial();
				if(jsonReturned){
					let optesocial:any[] = [];
					jsonReturned.eSocialSituationTypes.forEach((eSocialSituationType:any) => {
						optesocial.push({key: eSocialSituationType.id, value: eSocialSituationType.name, selected: false})
					});
					setOptionsESocial(optesocial);
					//setSituationTypes(jsonReturned.situationTypes);
					//setSituationTypeStatus('success');
				}
			}catch(error){
				console.log(error);
			}
			
		})();

		// Default options
		setOptions(copyObjectJSON(initialOptions));
    }, []);


	const save = async (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
       
		if(!situationType.name || situationType.name==='' ){
			setToastAlert({...toastAlert, 
							danger: true, 
							title: t('SHADOWCAT.EMPLOYEE_SITUATION.VALIDATION_ERROR'),
							message:t('SHADOWCAT.SITUATION_TYPE.INFORM_NAME'),
							show: true
						});
			return;
		}
		if(!situationType.code || situationType.code==='' ){
			setToastAlert({...toastAlert, 
							danger: true, 
							title: t('SHADOWCAT.EMPLOYEE_SITUATION.VALIDATION_ERROR'),
							message:t('SHADOWCAT.SITUATION_TYPE.INFORM_CODE'),
							show: true
						});
			return;
		}
		if(!eventTypeValue ){
			setToastAlert({...toastAlert, 
							danger: true, 
							title: t('SHADOWCAT.EMPLOYEE_SITUATION.VALIDATION_ERROR'),
							message:t('SHADOWCAT.SITUATION_TYPE.INFORM_EVENT_TYPE'),
							show: true
						});
			return;
		}
		if(options[1].selected && !eSocialValue ){
			setToastAlert({...toastAlert, 
							danger: true, 
							title: t('SHADOWCAT.EMPLOYEE_SITUATION.VALIDATION_ERROR'),
							message:t('SHADOWCAT.SITUATION_TYPE.INFORM_ESOCIAL'),
							show: true
						});
			return;
		}
		setButtonLoading(true);
        try{
			const jsonReturned =  await postSituationType({...situationType});
			if(jsonReturned){
				if(jsonReturned.status==='ERROR'){
					setToastAlert({...toastAlert, 
									danger: true, 
									title: t('SHADOWCAT.EMPLOYEE_SITUATION.VALIDATION_ERROR'),
									message:jsonReturned.message,
									show: true
								});
					setButtonLoading(false);
				}else{
					setToastAlert({...toastAlert, 
									danger: false, 
									title: t('GENERIC.TEXT.OPERATION_PERFORMED'),
									message:jsonReturned.message,
									show: true
								});
					setTimeout(() => { 
						setButtonLoading(false); 
						if(saveAction){
							saveAction({name: 'success', data: jsonReturned.data});
						}
					}, 3000);
				}
			}else{
			}
		}catch(error){
			console.log(error);
		}
    }

	const changeInput = (e: React.ChangeEvent<HTMLInputElement>, inputName?: string) => {
		const target = e.target;
        let value = target?(target.type === 'checkbox' ? target.checked : (target.type === 'number' && !!target.value) ? parseInt(target.value) : target.value):e;
        let name = target?target.name:inputName!;

		switch(name){
			case 'esocialRadio':
				let optionsRadius = [...options];

				for (var i = 0; i < options.length; i++) {
					if (optionsRadius[i].key === value) {
						optionsRadius[i].selected = true;
						value = optionsRadius[i].key;
						//Limpa valor do esocial
						setESocialValue(undefined);
						//handleChange(undefined, "typeCalculateEnd", value);
					} else {
						optionsRadius[i].selected = false;
					}
				}

				setOptions(optionsRadius);
				break;
			case 'eSocialSituationTypeId':
				let types = [...optionsESocial!];

				for (var i = 0; i < types.length; i++) {
					if (types[i].key === value) {
						types[i].selected = true;
						setESocialValue(types[i]);
					} else {
						types[i].selected = false;
					}
				}

				setOptionsESocial(types);
				break;
			case 'eventType':
				
				let eventTypes = [...optionsEventType!];

				for (var i = 0; i < eventTypes.length; i++) {
					if (eventTypes[i].key === value) {
						eventTypes[i].selected = true;
						setEventTypeValue(eventTypes[i]);
					} else {
						eventTypes[i].selected = false;
					}
				}

				setOptionsEventType(eventTypes);
				break;
			default:
				break;

		}
		if(name !== 'esocialRadio'){
			let st:SituationType = {...situationType, [name]: value};
			setSituationType({...st});
		}else{
			let st:SituationType = {...situationType};
			delete(st.eSocialSituationTypeId);
			setSituationType({...st});
		}
    };

	const renderForm = () => {
		return (
			<form onSubmit={e => e.preventDefault()} className={styles['form-situation-type-new']}>
				<Row>
					<Row.Item sm={12} md={12} lg={12}>
						<Input
							id="name"
							title={t('GENERIC.TEXT.NAME')}
							medium
							placeholder={t('GENERIC.TEXT.NAME')}
							name="name"
							value={situationType?.name}
							status={(!!situationType.name && situationType.name.length > 0 && situationType.name.length < 250) ? 'success' : 'error'}
							handleChange={changeInput}
							fullWidth />
					</Row.Item>
					<Row.Item sm={12} md={12} lg={12}>
						<Input
							id="code"
							title={t('GENERIC.TEXT.CODE')}
							medium
							placeholder={t('GENERIC.TEXT.CODE')}
							name="code"
							value={situationType?.code}
							status={(!!situationType.code && situationType.code.length > 0 && situationType.code.length < 10) ? 'success' : 'error'}
							handleChange={changeInput}
							fullWidth />
					</Row.Item>

					<Row.Item sm={12} md={12} lg={12}>
						<Select medium 
								fullWidth 
								name="eventType" 
								className={styles['form-select-event-type']}
								value={eventTypeValue?.value} 
								title={t('GENERIC.TEXT.EVENT_TYPE')}
								options={optionsEventType!} 
								status={!!eventTypeValue && !!eventTypeValue.value ? 'success' : 'error'}
								handleChange={(e) => changeInput(e, 'eventType')} />
					</Row.Item>

					<Row.Item sm={12} md={12} lg={12}>
						<Container className={styles['form-option-off-scale']}>
							<Switch iconOff='times' 
									iconOn='check' 
									colorOn='#0353B5'
									name='offScale'
									text={t('SHADOWCAT.SITUATION_TYPE.OFF_SCALE')}
									sm 
									value={situationType.offScale}
									handleChange={(e: React.ChangeEvent<HTMLInputElement>) => changeInput(e)}/>
						</Container>
					</Row.Item>

					<Row.Item sm={12} md={12} lg={12}>
						<Container className={styles['form-option-workdays-only']}>
							<Switch iconOff='times' 
									iconOn='check'
									colorOn='#0353B5'
									name='workdaysOnly'
									text={t('SHADOWCAT.SITUATION_TYPE.WORKDAYS_ONLY')} 
									sm 
									handleChange={(e: React.ChangeEvent<HTMLInputElement>) => changeInput(e)} />
						</Container>
					</Row.Item>

					<Row.Item sm={12} md={12} lg={12}>
						<Container className={styles['form-option-delete-compensation-date']}>
							<Switch iconOff='times' 
									iconOn='check'
									colorOn='#0353B5'
									name='deleteCompensationDate'
									text={t('SHADOWCAT.SITUATION_TYPE.DELETE_COMPENSATION_DATE_QUESTION')} 
									sm 
									value={situationType.deleteCompensationDate}
									handleChange={(e: React.ChangeEvent<HTMLInputElement>) => changeInput(e)} />
						</Container>
					</Row.Item>

					<Row.Item sm={12} md={12} lg={12}>
						<Radio small
                            name='esocialRadio'
                            title='E-Social'
                            options={options}
                            handleChange={(e) => { changeInput(e) }}
                            disableAll={false} />
					</Row.Item>

					{options[1].selected &&
						<Row.Item sm={12} md={12} lg={12}>
							<Select medium 
										
										fullWidth 
										name='eSocialSituationTypeId'
										value={eSocialValue?.value} 
										title={`${t('ABSENCE.TEXT.ABSENCE_TYPE')} eSocial`}
										options={optionsESocial!} 
										status={!!eSocialValue && !!eSocialValue.value ? 'success' : 'error'}
										handleChange={(e) => changeInput(e, 'eSocialSituationTypeId')} />
						</Row.Item>
					}
					
				</Row>

				<Container className={styles['form-situation-type-new-actions']}>
					<Button
						danger
						icon='times'
						title={t('GENERIC.BUTTON.CANCEL.TEXT')}
						className={styles['btn-rt']}
						action={(e) => {
							e.preventDefault();
							if(cancelAction){
								cancelAction({name: 'cancel'});
							}
						}} />

					<Button
						primary
						title={t('GENERIC.BUTTON.SAVE.TEXT')}
						disabled={buttonDisabled}
						loading={buttonLoading}
						icon="save"
						className={styles['btn-rt']}
						action={(e) => save(e)} />

				</Container>
			</form>
		)

	}
	return (
		<Container className={styles['situation-types-rt-new']}>
			{noPanel && renderForm()}
			{!noPanel && <Panel title={t('SHADOWCAT.SITUATION_TYPE.NEW.TITLE')}><>{renderForm()}</></Panel> }

			{toastAlert.show &&
				<Toast 
					success={!toastAlert.danger}
					danger={toastAlert.danger}
					title={toastAlert.title}
					icon={toastAlert.danger?'exclamation-circle':'check'}
					positionTopRight
					closeChange={() => setToastAlert({...toastAlert, show: false})}
					message={toastAlert.message} 
					showTime={5000}/>
			}
		</Container>
	);
}

export default New;