import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import moment from 'moment';

import { Button, DateTimePicker, ImportPicture, Input, Row, Select, Switch, TextArea, Toast } from "@optimuminterfaces/revex-react-components/dist/components";

import { translation as t } from "@optimuminterfaces/revex-react-components/dist/translation";

import { Cid } from "../../../../models/Cid";
import { MedicalCertificate } from "../../../../models/MedicalCertificate";

import { postMedicalCertificate } from "../../../../services/medicalCertificates.services";

import { validateMedicalCertificateData } from "../../../../utils/MedicalCertificateUtil";

import styles from './New.module.scss';

interface NewProps {
    Container?: any;
    saveAbsencesWithTime: boolean;
    isOnlyEmployee: boolean;
    employeeId: string;
    listCids: SelectProps[];
    listEmployees: SelectProps[];
    handleClose: Function;
}

interface SelectProps {
    key: string;
    value: string;
    selected: boolean;
}

interface ToastMessage {
    show: boolean;
    title: string;
    message: string;
    danger: boolean;
}

const New = ({
    Container = 'div',
    saveAbsencesWithTime,
    isOnlyEmployee,
    employeeId,
    listCids,
    listEmployees,
    handleClose
}: NewProps) => {

    const [medicalCertificate, setMedicalCertificate] = useState<MedicalCertificate | null>(null);
    const [haveCid, setHaveCid] = useState<boolean>(false);
    const [employees, setEmployees] = useState<SelectProps[]>([]);
    const [employeeNameSelected, setEmployeeNameSelected] = useState<string | null>(null);
    const [cids, setCids] = useState<SelectProps[]>([]);
    const [cidSelected, setCidSelected] = useState<Cid | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [toastAlert, setToastAlert] = useState<ToastMessage>({
        show: false,
        title: '',
        message: '',
        danger: false
    });

    useEffect(() => {
        if (!isOnlyEmployee) {
            setMedicalCertificate(buildDefault(''));

            let newEmployeeList: SelectProps[] = [];
            listEmployees.forEach(employee => {
                newEmployeeList.push({
                    key: employee.key,
                    value: employee.value,
                    selected: false
                });
            });
            setEmployees(newEmployeeList);

        } else {
            setMedicalCertificate(buildDefault(employeeId));
        }

        let newList: SelectProps[] = [];
        listCids.forEach(cid => {
            newList.push({
                key: cid.key,
                value: cid.value,
                selected: false
            });
        });
        setCids(newList);
    }, []);

    const saveMedicalCertificate = async (medicalCertificateToSave: MedicalCertificate) => {

        setLoading(true);

        let toast: ToastMessage = {
            show: true,
            title: t('GENERIC.TEXT.ERROR_OCURRED'),
            message: t('GENERIC.TEXT.ERROR_OCURRED'),
            danger: true
        };

        try {
            const returnValidateData: string | null = validateMedicalCertificateData(medicalCertificateToSave, false);

            if (returnValidateData === null) {
                const jsonReturned = await postMedicalCertificate(medicalCertificate);

                if (jsonReturned) {
                    if (String(jsonReturned.status).toLowerCase() === 'success') {
                        toast.danger = false;
                        toast.title = t('GENERIC.TEXT.OPERATION_PERFORMED');
                        toast.message = '';
                        setToastAlert(toast);

                        setTimeout(() => {
                            medicalCertificateToSave.id = jsonReturned.data.id;
                            medicalCertificateToSave.medicalCertificateStatus = jsonReturned.data.medicalCertificateStatus;
                            medicalCertificateToSave.description = jsonReturned.data.description;
                            medicalCertificateToSave.descriptionMap = jsonReturned.data.descriptionMap;
                            handleClose(medicalCertificateToSave);
                        }, 5000);

                    } else {
                        toast.message = jsonReturned.message;
                        setToastAlert(toast);
                        setLoading(false);
                    }

                } else {
                    setToastAlert(toast);
                    setLoading(false);
                }

            } else {
                toast.message = returnValidateData;
                setToastAlert(toast);
                setLoading(false);
            }

        } catch (error) {
            setToastAlert(toast);
            setLoading(false);
            console.log(error);
        }
    };

    const buildDefault = (employeeId: string) => {
        let medicalCertificateDefault: MedicalCertificate = {
            id: null,
            cid: null,
            employeeId: employeeId,
            employeeName: null,
            instanceId: null,
            description: '',
            descriptionMap: '',
            haveSchedule: false,
            startDate: moment().format('YYYY-MM-DD'),
            endDate: null,
            medicalCertificateStatus: !isOnlyEmployee ? 'APPROVED' : 'WITHOUT_STATUS',
            countyNumber: '',
            professionalName: '',
            fileName: null,
            fileContentType: null, 
            created: null,
            createdBy: null,
            modified: null,
            modifiedBy: null,
            file: null
        };

        return medicalCertificateDefault;
    };

    const handleChangeEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
        const target = e.target;
        const name = target.name;
        let value;

        if (target.type === 'checkbox') {
            value = target.checked;

        } else if (target.type === 'number' && !!target.value) {
            value = parseInt(target.value);

        } else {
            value = target.value;
        }

        handleChange(name, value);
    };

    const handleChange = (name: string, value: string | number | boolean | Cid | null) => {
        if (!!medicalCertificate) {
            if (name === 'haveSchedule') {
                let haveSchedule: boolean = value === true ? true : false;
                let startDate: string = haveSchedule === true ? '' :
                    moment().format('YYYY-MM-DD');

                setMedicalCertificate({ ...medicalCertificate, haveSchedule: haveSchedule, startDate: startDate, endDate: '' });

            } else {
                setMedicalCertificate({ ...medicalCertificate, [name]: value });
            }
        }
    };

    const handleChangeHaveCid = (checkHaveCid: boolean) => {
        if (!checkHaveCid) {
            handleChange('cid', null);
            setCidSelected(null);
        }

        setHaveCid(checkHaveCid);
    };

    const handleChangeEmployeeSelect = (key: string) => {
        let newList: SelectProps[] = employees;
        newList.forEach(item => {
            if (key === item.key) {
                item.selected = true;

                if (!!medicalCertificate) {
                    let mc: MedicalCertificate = medicalCertificate;
                    mc.employeeId = item.key;
                    mc.employeeName = item.value;

                    setMedicalCertificate(mc);
                }

                setEmployeeNameSelected(item.value);

            } else {
                item.selected = false;
            }
        });
        setEmployees(newList);
    };

    const handleChangeCidSelect = (key: string) => {
        let newList: SelectProps[] = cids;
        newList.forEach(item => {
            if (key === item.key) {
                item.selected = true;

                let cidDescription = item.value.split(' - ');

                let cid: Cid = {
                    id: item.key,
                    code: cidDescription[0],
                    description: cidDescription[1]
                };

                setCidSelected(cid);
                handleChange('cid', cid);

            } else {
                item.selected = false;
            }
        });
        setCids(newList);
    };

    const handleChangeImportPicture = ({ name, value }: any) => {
        try {
            if (name === 'ImportPicture.imgBase64') {
                setMedicalCertificate(medicalCertificate => ({ ...medicalCertificate!, file: value }));
            }else if (name === 'ImportPicture.file') {
                setMedicalCertificate(medicalCertificate => ({ ...medicalCertificate!, fileName: value.name }));
            }

        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Container className={styles['medical-certificate-new-rt']}>
            {!!medicalCertificate &&
                <Row>
                    {!isOnlyEmployee &&
                        <Row.Item sm={12} md={12} lg={12}>
                            <Select
                                medium
                                fullWidth
                                title={t('GENERIC.TEXT.EMPLOYEES')}
                                name="selectEmployees"
                                value={employeeNameSelected != null ? employeeNameSelected : ''}
                                options={employees}
                                disabled={employees.length === 0 ? true : false}
                                handleChange={handleChangeEmployeeSelect} />
                        </Row.Item>
                    }

                    {saveAbsencesWithTime &&
                        <Row.Item sm={12} md={12} lg={12}>
                            <Switch
                                sm
                                name="haveSchedule"
                                text={t('SHADOWCAT.MEDICAL_CERTIFICATE.NEW.HAVE_SCHEDULE_QUESTION')}
                                value={!!medicalCertificate ? medicalCertificate.haveSchedule : false}
                                iconOff="times"
                                iconOn="check"
                                colorOn="#3b51ed"
                                handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeEvent(e)} />
                        </Row.Item>
                    }

                    <Row.Item sm={12} md={12} lg={12}>
                        <Row>
                            <Row.Item sm={12} md={6} lg={6}>
                                <DateTimePicker
                                    inline
                                    fullWidth
                                    title={t('GENERIC.TEXT.START_DATE')}
                                    name="startDate"
                                    value={!!medicalCertificate && !!medicalCertificate.startDate ? medicalCertificate?.startDate : null}
                                    type={medicalCertificate?.haveSchedule ? "datetime" : "date"}
                                    mask
                                    noSeconds
                                    handleChange={(e) => { handleChange("startDate", e) }} />
                            </Row.Item>

                            <Row.Item sm={12} md={6} lg={6}>
                                <DateTimePicker
                                    inline
                                    fullWidth
                                    title={t('GENERIC.TEXT.END_DATE')}
                                    name="endDate"
                                    value={!!medicalCertificate && !!medicalCertificate.endDate ? medicalCertificate?.endDate : null}
                                    type={medicalCertificate?.haveSchedule ? "datetime" : "date"}
                                    mask
                                    noSeconds
                                    handleChange={(e) => { handleChange("endDate", e) }} />
                            </Row.Item>
                        </Row>
                    </Row.Item>

                    <Row.Item sm={12} md={12} lg={12}>
                        <Input
                            id="professionalName"
                            type="text"
                            medium
                            fullWidth
                            name="professionalName"
                            title={t('SHADOWCAT.MEDICAL_CERTIFICATE.GENERIC.PROFESSIONAL_NAME')}
                            placeholder={t('SHADOWCAT.MEDICAL_CERTIFICATE.GENERIC.PROFESSIONAL_NAME')}
                            value={medicalCertificate?.professionalName || ''}
                            handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                e?.preventDefault?.();
                                handleChangeEvent(e);
                            }} />
                    </Row.Item>

                    <Row.Item sm={12} md={12} lg={12}>
                        <Input
                            id="countyNumber"
                            type="text"
                            medium
                            fullWidth
                            name="countyNumber"
                            title={t('SHADOWCAT.MEDICAL_CERTIFICATE.GENERIC.PROFESSIONAL_REGISTRATION')}
                            placeholder={t('SHADOWCAT.MEDICAL_CERTIFICATE.GENERIC.PROFESSIONAL_REGISTRATION')}
                            value={medicalCertificate?.countyNumber || ''}
                            handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                e?.preventDefault?.();
                                handleChangeEvent(e);
                            }} />
                    </Row.Item>

                    <Row.Item sm={12} md={12} lg={12}>
                        <Switch
                            sm
                            name="haveCid"
                            text={t('SHADOWCAT.MEDICAL_CERTIFICATE.NEW.HAVE_CID_QUESTION')}
                            value={haveCid}
                            iconOff="times"
                            iconOn="check"
                            colorOn="#3b51ed"
                            handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeHaveCid(e.target.checked)} />
                    </Row.Item>

                    {haveCid &&
                        <Row.Item sm={12} md={12} lg={12}>
                            <Select
                                medium
                                fullWidth
                                title={t('SHADOWCAT.MEDICAL_CERTIFICATE.GENERIC.CID')}
                                name="selectCids"
                                value={cidSelected != null ? `${cidSelected.code} - ${cidSelected.description}` : ''}
                                options={cids}
                                disabled={cids.length === 0 ? true : false}
                                handleChange={handleChangeCidSelect} />
                        </Row.Item>
                    }

                    <Row.Item sm={12} md={12} lg={12}>
                        <TextArea
                            name="description"
                            title={t('GENERIC.TEXT.DESCRIPTION')}
                            placeholder={t('GENERIC.TEXT.DESCRIPTION')}
                            fullWidth
                            rows={5}
                            value={!!medicalCertificate && !!medicalCertificate.description ? medicalCertificate.description : ''}
                            handleChange={(e) => handleChangeEvent(e)} />
                    </Row.Item>

                    <Row.Item sm={12} md={12} lg={12}>
                        <Container className={styles['select-photo']}>
                            <ImportPicture
                                accept={'image/*,application/pdf'}
                                canvasWidth={400}
                                canvasHeight={500}
                                croppedImageWidth={400}
                                croppedImageHeigth={500}
                                imagePreviewWidth={400}
                                imagePreviewHeight={500}
                                imageSelectedHeight={500}
                                showButtons={false}
                                handleChanges={handleChangeImportPicture} />
                        </Container>
                    </Row.Item>

                    <Row.Item sm={12} md={12} lg={12}>
                        <Container className={styles['button-actions']}>
                            <Button
                                danger
                                md
                                title={t('GENERIC.BUTTON.CANCEL.TEXT')}
                                icon="times"
                                loading={loading}
                                action={() => handleClose()} />

                            <Button
                                primary
                                md
                                title={t('GENERIC.BUTTON.SAVE.TEXT')}
                                icon="save"
                                className={styles['last-button']}
                                loading={loading}
                                action={() => saveMedicalCertificate(medicalCertificate)} />
                        </Container>
                    </Row.Item>
                </Row>
            }

            {!!toastAlert && toastAlert.show &&
                <Toast
                    danger={toastAlert.danger}
                    success={!toastAlert.danger}
                    icon={toastAlert.danger ? "times" : "check"}
                    title={toastAlert.title}
                    positionTopRight
                    message={toastAlert.message}
                    closeChange={() => setToastAlert({ ...toastAlert, show: false })}
                    showTime={5000} />
            }
        </Container>
    );

};

New.defaultProps = {
    saveAbsencesWithTime: false,
    isOnlyEmployee: false,
    listCids: [],
    listEmployees: []
};

New.propTypes = {
    saveAbsencesWithTime: PropTypes.bool,
    isOnlyEmployee: PropTypes.bool,
    employeeId: PropTypes.string,
    listCids: PropTypes.arrayOf(PropTypes.object),
    listEmployees: PropTypes.arrayOf(PropTypes.object),
    handleClose: PropTypes.func.isRequired
};

export default New;