import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Button, DateTimePicker, Input, Radio, Row, Select, Switch, Toast } from '@optimuminterfaces/revex-react-components/dist/components';
import { copyObjectJSON } from '@optimuminterfaces/revex-react-components/dist/utils/DeveloperUtil';

import { EmployeeSituation } from '../../../../models/EmployeeSituation';
import { SituationType } from '../../../../models/SituationType';
import { validateEmployeeSituation, setWindowShadowcatUpdateAbsence } from '../../../../utils/EmployeeSituationUtil';
import { buildSituationTypesSelect } from '../../../../utils/SituationTypeUtil';
import { postEmployeeSituation } from '../../../../services/employeeSituations.services';
import { fetchSituationsTypes } from '../../../../services/situationTypes.services';

import styles from './New.module.scss';

import { translation as t } from '@optimuminterfaces/revex-react-components/dist/translation/index'

interface NewProps {
    employeeId: string;
    employeeName: string;
    saveAbsencesWithTime: boolean;
    showTitle: boolean;
    handleClose: Function;
    Container?: any;
    H2?: any;
};

interface SituationTypeSelect {
    key: string;
    value: string;
    selected: boolean;
}

interface ToastMessage {
    show: boolean;
    title: string;
    message: string;
    danger: boolean;
};

const initialOptionsWithoutTime = [
    { key: "1", value: t('ABSENCE.DURATION_OPTIONS.INFORM_END_DATE'), selected: true },
    { key: "2", value: t('ABSENCE.DURATION_OPTIONS.INFORM_DAYS'), selected: false },
    { key: "3", value: t('ABSENCE.DURATION_OPTIONS.NO_INFORM'), selected: false }
];

const initialOptionsWithTime = [
    { key: "1", value: t('ABSENCE.DURATION_OPTIONS.INFORM_END_DATE_AND_TIME'), selected: true },
    { key: "2", value: t('ABSENCE.DURATION_OPTIONS.INFORM_HOURS'), selected: false }
];

const New = ({
    employeeId,
    employeeName,
    saveAbsencesWithTime,
    showTitle,
    handleClose,
    Container = 'div',
    H2 = 'h2' }: NewProps) => {

    const [employeeSituation, setEmployeeSituation] = useState<EmployeeSituation>();
    const [situationTypes, setSituationTypes] = useState<SituationTypeSelect[]>([]);
    const [situationTypeSelected, setSituationTypeSelected] = useState<SituationType>();
    const [optionsWithoutTime, setOptionsWithoutTime] = useState(copyObjectJSON(initialOptionsWithoutTime));
    const [optionsWithTime, setOptionsWithTime] = useState(copyObjectJSON(initialOptionsWithTime));
    const [loadingButtonSave, setLoadingButtonSave] = useState<boolean>(false);
    const [toastAlert, setToastAlert] = useState<ToastMessage>({
        show: false,
        title: '',
        message: '',
        danger: false
    });

    useEffect(() => {
        defaultEmployeeSituation(false);

        (async () => {
            try {
                const jsonReturned = await fetchSituationsTypes({ showDisabled: false });

                if (jsonReturned) {
                    let situationTypesInDataBase: SituationType[] = jsonReturned.situationTypes;
                    let buildSelect = buildSituationTypesSelect(situationTypesInDataBase, undefined);
                    setSituationTypes(buildSelect);
                } else {
                    setSituationTypes([]);
                }

            } catch (error) {
                console.log(error);
                setSituationTypes([]);
            }
        })();

    }, []);

    const defaultEmployeeSituation = (haveSchedule: boolean) => {
        let defaultAbsence: EmployeeSituation = {
            id: undefined,
            employeeId: employeeId,
            employeeName: employeeName,
            instanceId: undefined,
            situationType: situationTypeSelected,
            medicalCertificateId: null,
            haveSchedule: haveSchedule,
            startDate: haveSchedule ? moment().format('YYYY-MM-DD HH:mm') : moment().format('YYYY-MM-DD'),
            endDate: undefined,
            created: undefined,
            createdBy: undefined,
            modified: undefined,
            modifiedBy: undefined,
            typeCalculateEnd: "1",
            endDateDays: 1,
            endDateHours: 1,
            dailyHours: null
        };

        setEmployeeSituation(defaultAbsence);
    };

    const handleSave = async () => {
        setLoadingButtonSave(true);

        let toast: ToastMessage = {
            show: true,
            title: t('SHADOWCAT.EMPLOYEE_SITUATION.VALIDATION_ERROR'),
            message: "",
            danger: true
        };

        if (!!employeeSituation) {
            let messageReturn = validateEmployeeSituation(employeeSituation, false);


            if (!!messageReturn) {
                toast.message = messageReturn;
            } else {
                try {
                    const jsonReturned = await postEmployeeSituation(employeeSituation);

                    if (String(jsonReturned.status).toLowerCase() === 'success') {
                        toast.danger = false;
                        toast.title = t('GENERIC.TEXT.OPERATION_PERFORMED');

                        // Sinaliza atualização de dados no Revex
                        setWindowShadowcatUpdateAbsence(true);
                    }

                    toast.message = jsonReturned.message;

                } catch (error) {
                    console.log(error);
                    toast.message = t('SHADOWCAT.EMPLOYEE_SITUATION.ERROR_WHILE_SAVE_ABSENCE');
                }
            }

        } else {
            toast.message = t('SHADOWCAT.EMPLOYEE_SITUATION.FILL_REQUIRED_FIELDS');
        }

        setToastAlert(toast);

        if (!toast.danger) {
            setTimeout(() => {
                handleClose(employeeSituation);
            }, 5000);
        } else {
            setLoadingButtonSave(false);
        }

    };

    const handleChange = (e?: React.ChangeEvent<HTMLInputElement> | undefined, name?: string, value?: string | number | boolean) => {
        if (e) {
            const target = e.target;
            value = target.type === 'checkbox' ? target.checked : (target.type === 'number' && !!target.value) ? parseInt(target.value) : target.value;
            name = target.name;
        }

        if (name === 'haveSchedule') {
            defaultEmployeeSituation(value === true ? true : false);
        } else {
            setEmployeeSituation({ ...employeeSituation!, [name!]: value });
        }
    };

    const handleChangeSelect = (key: string) => {
        for (var i = 0; i < situationTypes.length; i++) {
            if (situationTypes[i].key === key) {
                situationTypes[i].selected = true;

                let situationType: SituationType = {
                    id: key,
                    name: situationTypes[i].value,
                    legend: null,
                    disabled: false
                };

                setSituationTypeSelected(situationType);
                setEmployeeSituation({ ...employeeSituation!, situationType: situationType });
            } else {
                situationTypes[i].selected = false;
            }
        }
    };

    const handleChangeRadioWithTime = (key: string) => {
        let options = copyObjectJSON(optionsWithTime);

        for (var i = 0; i < options.length; i++) {
            if (options[i].key === key) {
                options[i].selected = true;
                handleChange(undefined, "typeCalculateEnd", key);
            } else {
                options[i].selected = false;
            }
        }

        setOptionsWithTime(copyObjectJSON(options));
    };

    const handleChangeRadioWithoutTime = (key: string) => {
        let options = copyObjectJSON(optionsWithoutTime);

        for (var i = 0; i < options.length; i++) {
            if (options[i].key === key) {
                options[i].selected = true;
                handleChange(undefined, "typeCalculateEnd", key);
            } else {
                options[i].selected = false;
            }
        }

        setOptionsWithoutTime(copyObjectJSON(options));
    };

    const renderForm = (haveSchedule: boolean) => {
        if (saveAbsencesWithTime && haveSchedule) {
            return renderAbsenceWithTime();

        } else {
            return renderAbsenceWithoutHours();
        }
    };

    const renderAbsenceWithoutHours = () => {
        return (
            <Container className={styles['form-employee-situation']}>
                <Row>
                    <Row.Item sm={12} md={12} lg={12}>
                        <DateTimePicker inline
                            title={t('SHADOWCAT.EMPLOYEE_SITUATION.START_DATE_ABSENCE')}
                            name="startDate"
                            value={!!employeeSituation && !!employeeSituation.startDate ? employeeSituation?.startDate : null}
                            type="date"
                            mask
                            handleChange={(e) => { handleChange(undefined, "startDate", e) }} />
                    </Row.Item>

                    <Row.Item sm={12} md={12} lg={12}>
                        <Radio inlineAll
                            small={false}
                            medium
                            name="selectTypeEndAbsence"
                            title={t('GENERIC.TEXT.DURATION')}
                            options={optionsWithoutTime}
                            handleChange={(e) => { handleChangeRadioWithoutTime(e.target.id) }}
                            disableAll={false} />
                    </Row.Item>

                    {!!optionsWithoutTime && optionsWithoutTime[0].selected &&
                        <Row.Item sm={12} md={12} lg={12}>
                            <DateTimePicker inline
                                title={t('SHADOWCAT.EMPLOYEE_SITUATION.END_DATE_ABSENCE')}
                                name="endDate"
                                value={!!employeeSituation && !!employeeSituation.endDate ? employeeSituation?.endDate : null}
                                type="date"
                                mask
                                disabled={!!employeeSituation && !!employeeSituation?.startDate ? false : true}
                                handleChange={(e) => { handleChange(undefined, "endDate", e) }} />
                        </Row.Item>
                    }

                    {!!optionsWithoutTime && optionsWithoutTime[1].selected &&
                        <Row.Item sm={12} md={12} lg={12}>
                            <Input
                                id="endDateDays"
                                medium
                                inline
                                name="endDateDays"
                                title={t('SHADOWCAT.EMPLOYEE_SITUATION.DAYS_TO_THE_END')}
                                value={employeeSituation?.endDateDays}
                                type="number"
                                minValue={1}
                                maxValue={2000}
                                status={!!employeeSituation?.endDateDays && employeeSituation.endDateDays > 0 ? "" : "error"}
                                disabled={!!employeeSituation && !!employeeSituation?.startDate ? false : true}
                                handleChange={(e) => handleChange(e)} />
                        </Row.Item>
                    }
                </Row>
            </Container>
        )
    };

    const renderAbsenceWithTime = () => {
        return (
            <Container className={styles['form-employee-situation']}>
                <Row>
                    <Row.Item sm={12} md={12} lg={12}>
                        <DateTimePicker inline
                            title={t('SHADOWCAT.EMPLOYEE_SITUATION.START_DATE_AND_TIME')}
                            name="startDate"
                            value={!!employeeSituation && !!employeeSituation.startDate ? employeeSituation?.startDate : null}
                            type="datetime"
                            mask
                            noSeconds
                            handleChange={(e) => { handleChange(undefined, "startDate", e) }} />
                    </Row.Item>

                    <Row.Item sm={12} md={12} lg={12}>
                        <Radio inlineAll
                            small={false}
                            medium
                            name="selectTypeEndAbsence"
                            title={t('GENERIC.TEXT.DURATION')}
                            options={optionsWithTime}
                            handleChange={(e) => { handleChangeRadioWithTime(e.target.id) }}
                            disableAll={false} />
                    </Row.Item>

                    {!!optionsWithTime && optionsWithTime[0].selected &&
                        <Row.Item sm={12} md={12} lg={12}>
                            <DateTimePicker inline
                                title={t('SHADOWCAT.EMPLOYEE_SITUATION.END_DATE_AND_TIME')}
                                name="endDate"
                                value={!!employeeSituation && !!employeeSituation.endDate ? employeeSituation?.endDate : null}
                                type="datetime"
                                mask
                                noSeconds
                                disabled={!!employeeSituation && !!employeeSituation?.startDate ? false : true}
                                handleChange={(e) => { handleChange(undefined, "endDate", e) }} />
                        </Row.Item>
                    }

                    {!!optionsWithTime && optionsWithTime[1].selected &&
                        <Row.Item sm={12} md={12} lg={12}>
                            <Input
                                id="endDateHours"
                                medium
                                inline
                                name="endDateHours"
                                title={t('SHADOWCAT.EMPLOYEE_SITUATION.HOURS_TO_THE_END')}
                                value={employeeSituation?.endDateHours}
                                type="number"
                                minValue={1}
                                maxValue={24}
                                status={!!employeeSituation?.endDateHours && employeeSituation.endDateHours > 0 ? "" : "error"}
                                disabled={!!employeeSituation && !!employeeSituation?.startDate ? false : true}
                                handleChange={(e) => handleChange(e)} />
                        </Row.Item>
                    }
                </Row>
            </Container>
        )
    };

    return (
        <Container className={styles['employee-situation-new-rt']}>
            <Row>
                {showTitle &&
                    <Row.Item sm={12} md={12} lg={12}>
                        <H2>{t('ABSENCE.TEXT.NEW_ABSENCE')}</H2>
                    </Row.Item>
                }

                {saveAbsencesWithTime &&
                    <Row.Item sm={12} md={12} lg={12}>
                        <Switch sm
                            name="haveSchedule"
                            text={t('SHADOWCAT.EMPLOYEE_SITUATION.ABSENCE_TIMETABLE_QUESTION')}
                            value={!!employeeSituation ? employeeSituation.haveSchedule : false}
                            iconOff="times"
                            iconOn="check"
                            colorOn="#3b51ed"
                            handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)} />
                    </Row.Item>
                }

                <Row.Item sm={12} md={12} lg={12}>
                    <Select medium fullWidth
                        name="situationTypes"
                        title={t('ABSENCE.TEXT.ABSENCE_TYPE')}
                        options={situationTypes!}
                        value={situationTypeSelected?.name}
                        disabled={!!situationTypes && situationTypes.length > 0 ? false : true}
                        handleChange={handleChangeSelect} />
                </Row.Item>

                <Row.Item sm={12} md={12} lg={12}>
                    {renderForm(employeeSituation ? employeeSituation.haveSchedule : false)}
                </Row.Item>

                <Row.Item sm={12} md={12} lg={12}>
                    <Container className={styles['group-buttons']}>
                        <Button danger md
                            title={t('GENERIC.BUTTON.CANCEL.TEXT')}
                            icon="times"
                            className={styles['button-action']}
                            action={() => handleClose()} />
                        <Button primary md
                            title={t('GENERIC.BUTTON.SAVE.TEXT')}
                            icon="save"
                            className={styles['button-action']}
                            loading={loadingButtonSave}
                            action={() => handleSave()} />
                    </Container>
                </Row.Item>
            </Row>

            {!!toastAlert && toastAlert.show &&
                <Toast
                    danger={toastAlert.danger}
                    success={!toastAlert.danger}
                    icon={toastAlert.danger ? "times" : "check"}
                    title={toastAlert.title}
                    positionTopRight
                    message={toastAlert.message}
                    closeChange={() => setToastAlert({ ...toastAlert, show: false })}
                    showTime={5000} />
            }

        </Container>
    );

};

New.defaultProps = {
    saveAbsencesWithTime: false,
    showTitle: false
};

New.propTypes = {
    employeeId: PropTypes.string.isRequired,
    employeeName: PropTypes.string.isRequired,
    saveAbsencesWithTime: PropTypes.bool,
    showTitle: PropTypes.bool,
    handleClose: PropTypes.func.isRequired
};

export default New;