import API from "./core";

export const TOKEN_KEY = "TOKEN_KEY";
export const SHADOWCAT_ENVIRONMENT = "SHADOWCAT_ENVIRONMENT";

export const isAuthenticated = () => sessionStorage.getItem(TOKEN_KEY) !== null;

export const getToken = () => sessionStorage.getItem(TOKEN_KEY);

export const setLogin = (token: string) => {
    sessionStorage.setItem(TOKEN_KEY, token);
};

export const setLogout = () => {
    sessionStorage.removeItem(TOKEN_KEY);
    sessionStorage.removeItem(SHADOWCAT_ENVIRONMENT);
};

export const getEnvironment = () => {
    const json = sessionStorage.getItem(SHADOWCAT_ENVIRONMENT);

    if (json) {
        return JSON.parse(json);
    } else {
        return '';
    }
};

export const setEnvironment = (data: any) => {
    sessionStorage.setItem(SHADOWCAT_ENVIRONMENT, JSON.stringify(data));
};

export const fetchEnvironment = async () => {
    try {
        return API.get('/rest/absencesAndHolidays/environment')
            .then(response => {
                if (response.data.status === 'ERROR') {
                    return null;

                } else {
                    return response.data.data;
                }

            }, error => {
                console.log(error);
                return null;
            });

    } catch (error) {
        return error;
    }
};