import API from "./core";

const HEADER_JSON = {
    headers: {
        'Content-Type': 'application/json'
    }
};

export const fetchMedicalCertificates = async (params?: any) => {
    try {
        return API.get('/rest/absencesAndHolidays/medicalCertificates', { params })
            .then(
                response => {
                    if (String(response.data.status).toLowerCase() === 'error') {
                        return null;

                    } else {
                        return response.data.data;
                    }

                }, error => {
                    console.log(error);
                    return null;
                }
            );

    } catch (error) {
        console.log(error);
        return error;
    }
};

export const fetchAdditionalData = async () => {
    try {
        return API.get('/rest/absencesAndHolidays/medicalCertificates/getAdditionalData')
            .then(
                response => {
                    if (String(response.data.status).toLowerCase() === 'error') {
                        return null;

                    } else {
                        return response.data.data;
                    }

                }, error => {
                    console.log(error);
                    return null;
                }
            );

    } catch (error) {
        console.log(error);
        return null;
    }
};

export const getPhoto = async (medicalCertificateId: string, fileContentType: string) => {
    try {
        const params  = { fileContentType };
        return API.get(`/rest/absencesAndHolidays/medicalCertificates/getPhoto/${medicalCertificateId}`, { params })
            .then(
                response => {
                    if (String(response.data.status).toLowerCase() === 'error') {
                        return null;

                    } else {
                        return response.data.data;
                    }

                }, error => {
                    console.log(error);
                    return null;
                }
            );

    } catch (error) {
        console.log(error);
        return null;
    }
};

export const postMedicalCertificate = async (params: any) => {
    try {
        return API.post('/rest/absencesAndHolidays/medicalCertificates', JSON.stringify(params), HEADER_JSON)
            .then(
                response => {
                    return response.data;

                }, error => {
                    console.log(error);
                    return null;
                }
            );

    } catch (error) {
        return error;
    }
};

export const putMedicalCertificate = async (medicalCertificateId: string, params: any) => {
    try {
        return API.put(`/rest/absencesAndHolidays/medicalCertificates/${medicalCertificateId}`, JSON.stringify(params), HEADER_JSON)
            .then(
                response => {
                    return response.data;

                }, error => {
                    console.log(error);
                    return null;
                }
            );

    } catch (error) {
        console.log(error);
        return error;
    }
};

export const deleteMedicalCertificate = async (id: string) => {
    try {
        return API.delete(`/rest/absencesAndHolidays/medicalCertificates/${id}`)
            .then(
                response => {
                    if (String(response.data.status).toLowerCase() === 'error') {
                        return null;
                    } else {
                        return response.data;
                    }
                }, error => {
                    console.log(error);
                    return null;
                }
            )
    } catch (error) {
        console.log(error);
        return error;
    }
};

export const postUpdateStatus = async (medicalCertificateId: string, params: any) => {
    try {
        return API.post(`/rest/absencesAndHolidays/medicalCertificates/updateStatus/${medicalCertificateId}`, JSON.stringify(params), HEADER_JSON)
            .then(
                response => {
                    return response.data;

                }, error => {
                    console.log(error);
                    return null;
                }
            )

    } catch (error) {
        console.log(error);
        return error;
    }
};