import { HashHistory } from 'history';
import React from 'react';
import ReactDOM from 'react-dom';
import App from '../src/components/templates/App/App';
import './index.scss';

declare global {
  interface Window {
    renderShadowcat: any;
    unmountShadowcat: any;
  }
};

window.renderShadowcat = (containerId: string, history: HashHistory, store: any): Boolean => {
  ReactDOM.render(
    <App history={history} />,
    document.getElementById(containerId)
  );

  return true;
};

window.unmountShadowcat = (containerId: string): Boolean => {
  const domElement = document.getElementById(containerId) as Element;
  ReactDOM.unmountComponentAtNode(domElement);
  return true;
};

if (document.getElementById('shadowcat-container') == null) {
  ReactDOM.render(
    <React.StrictMode>
      <App showMenu = {process.env.NODE_ENV === 'development'} />
    </React.StrictMode>,
    document.getElementById('root')
  );
};
