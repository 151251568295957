import React from 'react';
import { Route, HashRouter as Router, Routes } from 'react-router-dom';
import { createHashHistory, HashHistory } from 'history';

import Dashboard from './components/pages/Dashboard/Dashboard';
import { default as EmployeeSituationList } from './components/pages/EmployeeSituation/List/List';
import { default as EmployeeSituationListAllByInstance } from './components/pages/EmployeeSituation/ListAllByInstance/ListAllByInstance';

import { default as SituationTypeList } from './components/pages/SituationType/List/List';

import { default as MedicalCertificateList } from './components/pages/MedicalCertificates/List/List';

const defaultHistory = createHashHistory();

interface ApplicationRouterProps {
    history: HashHistory
};

const ApplicationRouter = ({ history = defaultHistory }: ApplicationRouterProps) => {

    return (
        <Router>
            <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/absencesAndHolidaysMF" element={<Dashboard />} />
                <Route path="/absencesAndHolidaysMF/employeeSituations/listByEmployee/:id" element={<EmployeeSituationList />} />
                <Route path="/employees/show/:id" element={<EmployeeSituationList />} />
                <Route path="/absencesAndHolidaysMF/employeeSituations" element={<EmployeeSituationListAllByInstance />} />
                <Route path="/absencesAndHolidaysMF/situationTypes" element={<SituationTypeList />} />
                <Route path="/absencesAndHolidaysMF/medicalCertificates" element={<MedicalCertificateList />} />

                {process.env.NODE_ENV === 'development' &&
                    <Route path="/auth" element={<>Auth</>} />
                }
            </Routes>
        </Router>

    )

};

export default ApplicationRouter;