import API from "./core";

export const fetchEmployeeById = async (params?: any) => {
    try {
        return API.get('/rest/absencesAndHolidays/employeeSituations/getEmployee', { params })
            .then(
                response => {
                    if (String(response.data.status).toLowerCase() === 'error') {
                        return null;
                    }

                    return response.data.data;

                }, error => {
                    console.log(error);
                    return null;
                }
            );
    } catch (error) {
        console.log(error);
        return null;
    }
};