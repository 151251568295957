import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Button, Loading, Modal, Pagination, Panel, PerPage, Row, Select, Table, TextInformation, Toast } from '@optimuminterfaces/revex-react-components/dist/components';
import { formatLocalDate } from '@optimuminterfaces/revex-react-components/dist/utils/DateTimeUtil';

import Edit from '../Edit/Edit';
import New from '../New/New';
import { Employee } from '../../../../models/Employee';
import { EmployeeSituation } from '../../../../models/EmployeeSituation';
import { fetchEmployeeById } from '../../../../services/employees.services';
import { fetchEmployeeSituationsByEmployeeId, deleteEmployeeSituation } from '../../../../services/employeeSituations.services';
import { fetchAllWorkingHoursByInstanceId } from '../../../../services/workingHours.services';

import { copyObjectJSON } from '@optimuminterfaces/revex-react-components/dist/utils/DeveloperUtil';
import { setWindowShadowcatUpdateAbsence } from '../../../../utils/EmployeeSituationUtil';
import { translateEventType } from '../../../../utils/SituationTypeUtil';
import { buildSelectWorkingHours } from '../../../../utils/WorkingHourUtil';
import { getEnvironment } from '../../../../services/auth.services';

import styles from './List.module.scss';

import { translation as t } from '@optimuminterfaces/revex-react-components/dist/translation/index'

interface ListProps {
    Container?: any;
    H4?: any;
};

interface ListUrlParams {
    id?: string;
};

interface SelectWorkingHours {
    key: string;
    value: string;
    selected: boolean;
}

interface ToastMessage {
    show: boolean;
    title: string;
    message: string;
    danger: boolean;
};

const List = ({ Container = 'div', H4 = 'h4' }: ListProps) => {

    const [employeeSituations, setEmployeeSituations] = useState<EmployeeSituation[]>();
    const [employeeSituationsStatus, setEmployeeSituationsStatus] = useState<string>('waiting');
    const [employee, setEmployee] = useState<Employee | null>(null);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [quantityPerPage, setQuantityPerPage] = useState<number>(10);
    const [totalElements, setTotalElements] = useState<number>(0);
    const [showModalNewEmployeeSituation, setShowModalNewEmployeeSituation] = useState<boolean>(false);
    const [employeeSituationToEdit, setEmployeeSituationToEdit] = useState<EmployeeSituation | null | undefined>(null);
    const [employeeSituationToDelete, setEmployeeSituationToDelete] = useState<EmployeeSituation | null | undefined>(null);
    const [loadingButtonDelete, setLoadingButtonDelete] = useState<boolean>(false);
    const [workingHours, setWorkingHours] = useState<SelectWorkingHours[]>([]);
    const [workingHourSelected, setWorkingHourSelected] = useState<string | null>(null);
    const [toastAlert, setToastAlert] = useState<ToastMessage>({
        show: false,
        title: '',
        message: '',
        danger: false
    });

    // Parâmetros da URL
    const urlParams: ListUrlParams = useParams();

    // Lista de ações permitidas
    const permissions = getEnvironment().actions;
    const saveAbsencesWithTime = getEnvironment().saveAbsencesWithTime;

    useEffect(() => {
        getByEmployeeId(urlParams.id!, 1, 10);
        getEmployee(urlParams.id!);
        getWorkingHoursByEmployeeId();
    }, [urlParams]);

    const getByEmployeeId = (employeeId: string, pageNumber: number, quantityPerPage: number) => {
        setEmployeeSituationsStatus('waiting');

        (async () => {
            try {
                const jsonReturned = await fetchEmployeeSituationsByEmployeeId({ employeeId, pageNumber, quantityPerPage });

                if (jsonReturned) {
                    setEmployeeSituations(jsonReturned.employeeSituations);
                    setTotalElements(jsonReturned.employeeSituationsQuantity);
                    setEmployeeSituationsStatus('success');
                } else {
                    setEmployeeSituations([]);
                    setEmployeeSituationsStatus('error');
                }
            } catch (error) {
                console.log(error);
                setEmployeeSituations([]);
                setEmployeeSituationsStatus('error');
            }
        })();
    };

    const getWorkingHoursByEmployeeId = () => {
        (async () => {
            try {
                const jsonReturned = await fetchAllWorkingHoursByInstanceId();

                if (jsonReturned) {
                    setWorkingHours(buildSelectWorkingHours(jsonReturned.workingHours));
                } else {
                    setWorkingHours([]);
                }
            } catch (error) {
                console.log(error);
                setWorkingHours([]);
            }
        })();
    };

    const getEmployee = (employeeId: string) => {
        (async () => {

            let employeeDefault: Employee = {
                id: employeeId,
                name: employeeId,
                workShift: null,
                instanceId: null,
                occupationId: null,
                scaleNameId: null
            }

            try {
                const jsonReturned = await fetchEmployeeById({ employeeId });

                if (jsonReturned) {
                    setEmployee(jsonReturned.employee);
                } else {
                    setEmployee(employeeDefault);
                }
            } catch (error) {
                setEmployee(employeeDefault);
                console.log(error);
            }
        })();
    };

    const deleteByEmployeeSituationId = async () => {
        setLoadingButtonDelete(true);

        let toast: ToastMessage = {
            title: t('SHADOWCAT.EMPLOYEE_SITUATION.DELETION_ERROR'),
            message: t('SHADOWCAT.EMPLOYEE_SITUATION.ERROR_TRYING_DELETE'),
            danger: true,
            show: true
        };

        if (!!employeeSituationToDelete && !!employeeSituationToDelete.id) {
            try {
                const jsonReturned = await deleteEmployeeSituation(employeeSituationToDelete.id, {
                    dailyHours: workingHourSelected,
                    employeeName: employee?.name
                });

                if (jsonReturned) {
                    removeListById(employeeSituationToDelete.id);
                    setWorkingHourSelected(null);
                    toast.title = t('GENERIC.TEXT.OPERATION_PERFORMED');
                    toast.message = t('ABSENCE.TEXT.DELETED_ABSENCE');
                    toast.danger = false;

                    // Sinaliza atualização de dados no Revex
                    setWindowShadowcatUpdateAbsence(true);
                } else {
                    toast.message = jsonReturned.message;
                }
            } catch (error) {
                console.log(error);
            }
        }

        setToastAlert(toast);
        setLoadingButtonDelete(false);
    };

    const handlePageChanged = (page: number) => {
        setCurrentPage(page);
        getByEmployeeId(urlParams.id!, page, quantityPerPage);
    };

    const handlePageSize = (size: number) => {
        setQuantityPerPage(size);
        getByEmployeeId(urlParams.id!, 1, size);
    };

    const handleShowModalNew = (newEmployeeSituation: EmployeeSituation | null) => {
        setShowModalNewEmployeeSituation(!showModalNewEmployeeSituation);

        if (!!newEmployeeSituation) {
            getByEmployeeId(urlParams.id!, 1, 10);
        }
    };

    const handleCloseModalEdit = (reloadPage: boolean) => {
        if (reloadPage) {
            getByEmployeeId(urlParams.id!, 1, 10);

        }

        setEmployeeSituationToEdit(null);
    };

    const buildTextModalConfirmationDelete = (absence: EmployeeSituation) => {
        // Verifica se ausência de horas, para formatação correta
        let dateTimeFormat = absence.haveSchedule ? 'DD/MM/yyyy HH:mm' : 'DD/MM/yyyy';

        let start = formatLocalDate(absence.startDate, true, 'yyyy-MM-DDTHH:mm:ss.sss', dateTimeFormat);
        let end = !!absence.endDate ? `em ${formatLocalDate(absence.endDate, true, 'yyyy-MM-DDTHH:mm:ss.sss', dateTimeFormat)}` : t('SHADOWCAT.EMPLOYEE_SITUATION.UNINFORMED');

        return t('SHADOWCAT.EMPLOYEE_SITUATION.DELETE_ABSENCE_QUESTION', undefined, (absence.situationType?.name), start, end);
    };


    const removeListById = (id: string) => {
        let newList: EmployeeSituation[] = [];

        employeeSituations?.forEach(employeeSituation => {
            if (employeeSituation.id !== id) {
                newList.push(employeeSituation);
            }
        });

        setEmployeeSituations(copyObjectJSON(newList));
        setEmployeeSituationToDelete(null);
        setLoadingButtonDelete(false);
    };

    const handleChangeSelect = (key: string) => {
        for (var i = 0; i < workingHours.length; i++) {
            if (workingHours[i].key === key) {
                workingHours[i].selected = true;
                setWorkingHourSelected(key);
            } else {
                workingHours[i].selected = false;
            }
        }
    };

    const renderEmployeeSituationItem = (employeeSituation: EmployeeSituation) => {
        // Verifica se ausência de horas, para formatação correta
        let dateTimeFormat = employeeSituation.haveSchedule ? 'DD/MM/yyyy HH:mm' : 'DD/MM/yyyy';
        return (
            <Table.BodyItem key={`employee-situation-${employeeSituation.id}`}>
                <Table.BodyItem.Item dataColumn={t('ABSENCE.TEXT.ABSENCE_TYPE')}>
                    <abbr title={employeeSituation.situationType?.name}>
                        <Container className={styles['text-table']}>
                            {`${employeeSituation.situationType?.code} - ${employeeSituation.situationType?.name}`}
                        </Container>
                    </abbr>
                </Table.BodyItem.Item>
                <Table.BodyItem.Item dataColumn={t('GENERIC.TEXT.EVENT_TYPE')} align="center">{!!employeeSituation.situationType ? translateEventType(employeeSituation.situationType.eventType) : '-'}</Table.BodyItem.Item>
                <Table.BodyItem.Item dataColumn={t('ABSENCE.TEXT.ESOCIAL_ID')}>
                    <abbr title={!!employeeSituation.situationType?.esocial ? `${employeeSituation.situationType?.esocial?.eSocialId} - ${employeeSituation.situationType?.esocial?.name}` : '-'}>
                        <Container className={styles['text-table']}>
                            {!!employeeSituation.situationType?.esocial ? `${employeeSituation.situationType?.esocial?.eSocialId} - ${employeeSituation.situationType?.esocial?.name}` : '-'}
                        </Container>
                    </abbr>
                </Table.BodyItem.Item>
                <Table.BodyItem.Item dataColumn={t('GENERIC.TEXT.START')} align="center">{formatLocalDate(employeeSituation.startDate, true, 'yyyy-MM-DDTHH:mm:ss.sss', dateTimeFormat)}</Table.BodyItem.Item>
                <Table.BodyItem.Item dataColumn={t('GENERIC.TEXT.END')} align="center">{!!employeeSituation.endDate ? formatLocalDate(employeeSituation.endDate, true, 'yyyy-MM-DDTHH:mm:ss.sss', dateTimeFormat) : t('SREP.LIST.UNINFORMED_END')}</Table.BodyItem.Item>
                <Table.BodyItem.Item dataColumn={t('SHADOWCAT.EMPLOYEE_SITUATION.HAVE_A_MEDICAL_CERTIFICATE')} align="center">
                    {employeeSituation.medicalCertificateId != null ? t('GENERIC.TEXT.YES') : t('GENERIC.TEXT.NO')}
                </Table.BodyItem.Item>
                <Table.BodyItem.Item dataColumn={t('GENERIC.TEXT.OPTIONS')}>
                    <>
                        {permissions.includes(15) && employeeSituation.medicalCertificateId === null &&
                            <Button warn md
                                title={t('GENERIC.BUTTON.EDIT.TEXT')}
                                icon="edit"
                                className={styles['button-action']}
                                action={() => setEmployeeSituationToEdit({ ...employeeSituation, employeeName: employee?.name })} />
                        }
                        {permissions.includes(17) && employeeSituation.medicalCertificateId === null &&
                            <Button danger md
                                title={t('GENERIC.BUTTON.DELETE.TEXT')}
                                icon="trash"
                                className={styles['button-action']}
                                action={() => setEmployeeSituationToDelete({ ...employeeSituation, employeeName: employee?.name })} />
                        }
                    </>
                </Table.BodyItem.Item>
            </Table.BodyItem>
        );
    };

    return (
        <Container className={styles['employee-situation-list-employee-rt']}>
            <Container className={styles['panel-list-employee-situation']}>
                <Panel title={!!employee ? `${t('SHADOWCAT.EMPLOYEE_SITUATION.EMPLOYEE_ABSENCES')} ${employee.name}` : t('GENERIC.TEXT.ABSENCES')}
                    noPadding
                    noTitleBorder
                    className='clearfix'
                    actions={permissions.includes(14) ? [
                        {
                            title: t('ABSENCE.TEXT.NEW_ABSENCE'),
                            icon: "plus",
                            primary: true,
                            action: () => { setShowModalNewEmployeeSituation(true) }
                        }
                    ] : []}>

                    <Row>
                        {/* Lista não carregada ainda */}
                        {((!employeeSituations || employeeSituations.length === 0) && employeeSituationsStatus === 'waiting') &&
                            <Row.Item lg={12} md={12} sm={12}>
                                <Loading text={t('GENERIC.TEXT.LOADING')} />
                            </Row.Item>
                        }
                        {/* Quando ocorre algum erro na busca */}
                        {((!employeeSituations || employeeSituations.length === 0) && employeeSituationsStatus === 'error') &&
                            <Row.Item lg={12} md={12} sm={12}>
                                <TextInformation
                                    icon="exclamation-circle"
                                    text={t('GENERIC.TEXT.UNABLE_RETURN')} />
                            </Row.Item>
                        }
                        {/* Lista vazia */}
                        {(!!employeeSituations && employeeSituations?.length === 0 && employeeSituationsStatus === 'success') &&
                            <Row.Item lg={12} md={12} sm={12}>
                                <TextInformation
                                    icon="search"
                                    text={t('ABSENCE.TEXT.EMPTY_LIST')} />
                            </Row.Item>
                        }
                        {/* Lita com pelo menos um item */}
                        {(!!employeeSituations && employeeSituations.length > 0 && employeeSituationsStatus === 'success') &&
                            <Container>
                                <Row.Item lg={12} md={12} sm={12}>
                                    <Container className={styles['pagination']}>
                                        <PerPage
                                            selected={quantityPerPage}
                                            handlePerPageChanged={(value) => { handlePageSize(value) }} />

                                        <Pagination.Compact
                                            currentPage={currentPage}
                                            pageLimit={quantityPerPage}
                                            totalRecords={totalElements}
                                            handlePageChanged={handlePageChanged} />
                                    </Container>
                                </Row.Item>
                                <Row.Item lg={12} md={12} sm={12}>
                                    <Table striped primary responsible>
                                        <Table.Header>
                                            <Table.HeaderItem>
                                                <Table.HeaderItem.Item>{t('ABSENCE.TEXT.ABSENCE_TYPE')}</Table.HeaderItem.Item>
                                                <Table.HeaderItem.Item>{t('GENERIC.TEXT.EVENT_TYPE')}</Table.HeaderItem.Item>
                                                <Table.HeaderItem.Item>{t('ABSENCE.TEXT.ESOCIAL_ID')}</Table.HeaderItem.Item>
                                                <Table.HeaderItem.Item>{t('GENERIC.TEXT.START')}</Table.HeaderItem.Item>
                                                <Table.HeaderItem.Item>{t('GENERIC.TEXT.END')}</Table.HeaderItem.Item>
                                                <Table.HeaderItem.Item>{t('SHADOWCAT.EMPLOYEE_SITUATION.HAVE_A_MEDICAL_CERTIFICATE')}</Table.HeaderItem.Item>
                                                <Table.HeaderItem.Item>{t('GENERIC.TEXT.OPTIONS')}</Table.HeaderItem.Item>
                                            </Table.HeaderItem>
                                        </Table.Header>
                                        <Table.Body>
                                            {employeeSituations.map(employeeSituation => {
                                                return renderEmployeeSituationItem(employeeSituation);
                                            })}
                                        </Table.Body>
                                    </Table>
                                </Row.Item>
                            </Container>
                        }
                    </Row>
                </Panel>
            </Container>

            {/*Modal nova ausência*/}
            {showModalNewEmployeeSituation &&
                <Modal md showClose title={t('ABSENCE.TEXT.NEW_ABSENCE')} handleClose={() => setShowModalNewEmployeeSituation(false)}>
                    <New
                        employeeId={urlParams.id!}
                        employeeName={!!employee ? employee.name : urlParams.id!}
                        saveAbsencesWithTime={saveAbsencesWithTime}
                        handleClose={handleShowModalNew} />
                </Modal>
            }
            {/*Modal edição de ausência*/}
            {!!employeeSituationToEdit &&
                <Modal md showClose title={t('SHADOWCAT.EMPLOYEE_SITUATION.ABSENCE_EDITION')} handleClose={() => setEmployeeSituationToEdit(null)}>
                    <Edit
                        employeeSituation={employeeSituationToEdit}
                        workingHoursList={workingHours}
                        handleClose={handleCloseModalEdit} />
                </Modal>
            }
            {/*Modal de confirmação de exclusão*/}
            {!!employeeSituationToDelete &&
                <Modal sm showClose title={t('SHADOWCAT.EMPLOYEE_SITUATION.DELETE_CONFIRMATION')} handleClose={() => setEmployeeSituationToDelete(null)}>
                    <Container>
                        <Row>
                            {!employeeSituationToDelete.haveSchedule &&
                                <Row.Item sm={12} md={12} lg={12}>
                                    <Select medium fullWidth
                                        name="workingHours"
                                        title={t('SHADOWCAT.EMPLOYEE_SITUATION.WORK_TIMETABLE_INFO')}
                                        options={workingHours!}
                                        value={workingHourSelected}
                                        disabled={!!workingHours && workingHours.length > 0 ? false : true}
                                        handleChange={handleChangeSelect} />
                                </Row.Item>
                            }
                            <Row.Item sm={12} md={12} lg={12}>
                                <H4 className={styles['modal-delete-employee-situation']}>{buildTextModalConfirmationDelete(employeeSituationToDelete)}</H4>
                            </Row.Item>
                            <Row.Item sm={12} md={12} lg={12}>
                                <Container className={styles['modal-delete-employee-situation']}>
                                    <Button primary sm
                                        title={t('GENERIC.TEXT.NO')}
                                        icon="times"
                                        className={styles['button-action']}
                                        action={() => setEmployeeSituationToDelete(null)} />
                                    <Button danger sm
                                        title={t('GENERIC.TEXT.YES')}
                                        icon="trash"
                                        loading={loadingButtonDelete}
                                        className={styles['button-action']}
                                        disabled={!!workingHourSelected || employeeSituationToDelete.haveSchedule ? false : true}
                                        action={() => deleteByEmployeeSituationId()} />
                                </Container>
                            </Row.Item>
                        </Row>
                    </Container>
                </Modal>
            }
            {/*Alertas*/}
            {!!toastAlert && toastAlert.show &&
                <Toast
                    positionTopRight
                    title={toastAlert.title}
                    message={toastAlert.message}
                    icon={toastAlert.danger ? "times" : "check"}
                    success={!toastAlert.danger}
                    danger={toastAlert.danger}
                    showTime={5000}
                    closeChange={() => setToastAlert({ ...toastAlert, show: false })} />
            }
        </Container>
    )
}

export default List;