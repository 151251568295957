import React, { useEffect, useState } from "react";

import { Button, Input, Panel, Radio, Row, Select, Switch, Toast } from "@optimuminterfaces/revex-react-components/dist/components";
import { translation as t } from "@optimuminterfaces/revex-react-components/dist/translation";

import { SituationType } from "../../../../models/SituationType";
import { fetchESocial, putSituationType } from "../../../../services/situationTypes.services";

import styles from './Edit.module.scss';

interface EditProps {
    situationType: SituationType;
    showPanel: boolean;
    callBack: Function;
    cancelAction: Function;
    Container?: any;
};

// Radio button vinculação eSocial
const initialOptions = [
    { key: "1", value: "Não vincular com e-social", selected: true },
    { key: "2", value: "Vincular com e-social", selected: false }
];

const initialOptionsEventType = [
    { key: 'ALLOWANCE', value: 'Abono', selected: true },
    { key: 'PAYMENT', value: 'Pagamento em Folha', selected: false },
    { key: 'DISCOUNT_ON_BANK_OF_HOURS', value: 'Desconto em Banco de Horas', selected: false }
];

const Edit = ({ situationType, showPanel, callBack, cancelAction, Container = 'div' }: EditProps) => {

    const [situationTypeEdit, setSituationTypeEdit] = useState<SituationType>(situationType);
    const [eSocialValue, setESocialValue] = useState<any>();
    const [eventTypeValue, setEventTypeValue] = useState<any>();
    const [optionsESocial, setOptionsESocial] = useState<any[]>();
    const [options, setOptions] = useState(initialOptions);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [optionsEventType, setOptionsEventType] = useState(initialOptionsEventType);
    const [toastAlert, setToastAlert] = useState<{ show: boolean, title: string, message: string, danger: boolean }>({ show: false, title: '', message: '', danger: false })

    useEffect(() => {
        if (!!situationType.eSocialSituationTypeId) {
            changeRadio("2");
        } else {
            changeRadio("1");
        }

        (async () => {
            try {
                const jsonReturned = await fetchESocial();

                if (jsonReturned) {
                    let optionsESocial: any[] = [];
                    let eSocialSituationTypeId: string | undefined = situationType.eSocialSituationTypeId;
                    let eventType: string = situationType.eventType!;
                    optionsEventType.forEach(option => {
                        if (option.key === eventType) {
                            option.selected = true;
                            setEventTypeValue(option);
                        }
                    })

                    jsonReturned.eSocialSituationTypes.forEach((eSocialSituationType: any) => {
                        let option = {
                            key: eSocialSituationType.id,
                            value: eSocialSituationType.name,
                            selected: (!!eSocialSituationTypeId && eSocialSituationTypeId === eSocialSituationType.id) ? true : false
                        };

                        optionsESocial.push(option);

                        if (!!eSocialSituationTypeId && eSocialSituationTypeId === eSocialSituationType.id) {
                            setESocialValue(option);
                        }
                    });

                    setOptionsESocial(optionsESocial);
                }

            } catch (error) {
                console.log(error);
            }
        })();
    }, [situationType]);

    const save = async (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();

        if (!situationTypeEdit.name || situationTypeEdit.name === '') {
            setToastAlert({
                ...toastAlert,
                danger: true,
                title: t('SHADOWCAT.EMPLOYEE_SITUATION.VALIDATION_ERROR'),
                message: t('SHADOWCAT.SITUATION_TYPE.NEW.INFORM_NAME'),
                show: true
            });
            return;
        }

        if (!situationTypeEdit.code || situationTypeEdit.code === '') {
            setToastAlert({
                ...toastAlert,
                danger: true,
                title: t('SHADOWCAT.EMPLOYEE_SITUATION.VALIDATION_ERROR'),
                message: t('SHADOWCAT.SITUATION_TYPE.NEW.INFORM_CODE'),
                show: true
            });
            return;
        }

        if (!eventTypeValue) {
            setToastAlert({
                ...toastAlert,
                danger: true,
                title: t('SHADOWCAT.EMPLOYEE_SITUATION.VALIDATION_ERROR'),
                message: t('SHADOWCAT.SITUATION_TYPE.NEW.INFORM_EVENT_TYPE'),
                show: true
            });
            return;
        }

        if (options[1].selected && !eSocialValue) {
            setToastAlert({
                ...toastAlert,
                danger: true,
                title: t('SHADOWCAT.EMPLOYEE_SITUATION.VALIDATION_ERROR'),
                message: t('SHADOWCAT.SITUATION_TYPE.NEW.INFORM_ESOCIAL'),
                show: true
            });
            return;
        }

        setButtonLoading(true);

        try {
            const jsonReturned = await putSituationType(situationTypeEdit.id!, { ...situationTypeEdit });

            if (jsonReturned) {
                if (String(jsonReturned.status).toUpperCase() === 'ERROR') {
                    setToastAlert({
                        ...toastAlert,
                        danger: true,
                        title: t('SHADOWCAT.EMPLOYEE_SITUATION.VALIDATION_ERROR'),
                        message: jsonReturned.message,
                        show: true
                    });

                    setButtonLoading(false);

                } else {
                    setToastAlert({
                        ...toastAlert,
                        danger: false,
                        title: t('GENERIC.TEXT.OPERATION_PERFORMED'),
                        message: jsonReturned.message,
                        show: true
                    });

                    setTimeout(() => {
                        setButtonLoading(false);
                        if (callBack) {
                            callBack({ name: 'success', data: jsonReturned.data });
                        }
                    }, 3000);
                }
            }

        } catch (error) {
            console.log(error);
        }
    };

    const changeInput = (e: React.ChangeEvent<HTMLInputElement>, inputName?: string) => {
        const target = e.target;
        let value = target?(target.type === 'checkbox' ? target.checked : (target.type === 'number' && !!target.value) ? parseInt(target.value) : target.value):e;
        let name = target?target.name:inputName!;

        switch (name) {
            case 'eSocialRadio':
                let v = value.toString()
                changeRadio(v);
                //Caso atualize para não usar esocial, remove entrada do objeto
                if (v === '1') {
                    setESocialValue(null);
                    let st: SituationType = { ...situationTypeEdit };
                    delete st.eSocialSituationTypeId;
                    setSituationTypeEdit({ ...st });
                }

                break;

            case 'eSocialSituationTypeId':
                let types = [...optionsESocial!];

                for (var i = 0; i < types.length; i++) {
                    if (types[i].key === value) {
                        types[i].selected = true;
                        setESocialValue(types[i]);
                    } else {
                        types[i].selected = false;
                    }
                }

                setOptionsESocial(types);

                break;
            case 'eventType':

                let eventTypes = [...optionsEventType!];

                for (var i = 0; i < eventTypes.length; i++) {
                    if (eventTypes[i].key === value) {
                        eventTypes[i].selected = true;
                        setEventTypeValue(eventTypes[i]);
                    } else {
                        eventTypes[i].selected = false;
                    }
                }

                setOptionsEventType(eventTypes);
                break;

            default:
                break;
        }

        if (name !== 'eSocialRadio') {
            let st: SituationType = { ...situationTypeEdit, [name]: value };
            setSituationTypeEdit({ ...st });
        }else{
			let st:SituationType = {...situationType};
			delete(st.eSocialSituationTypeId);
			setSituationTypeEdit({...st});
		}
    };

    const changeRadio = (key: string) => {
        let optionsRadius = [...options];

        for (var i = 0; i < optionsRadius.length; i++) {
            if (optionsRadius[i].key === key) {
                optionsRadius[i].selected = true;
            } else {
                optionsRadius[i].selected = false;
            }
        }

        setOptions(optionsRadius);
    };

    const renderForm = () => {
        return (
            <form onSubmit={e => e.preventDefault()} className={styles['form-situation-type-edit']}>
                <Row>
                    <Row.Item sm={12} md={12} lg={12}>
                        <Input
                            id="name"
                            title={t('GENERIC.TEXT.NAME')}
                            medium
                            placeholder={t('GENERIC.TEXT.NAME')}
                            name="name"
                            value={situationTypeEdit?.name}
                            status={(!!situationTypeEdit.name && situationTypeEdit.name.length > 0 && situationTypeEdit.name.length < 250) ? 'success' : 'error'}
                            handleChange={changeInput}
                            fullWidth />
                    </Row.Item>
                    <Row.Item sm={12} md={12} lg={12}>
                        <Input
                            id="code"
                            title={t('GENERIC.TEXT.CODE')}
                            medium
                            placeholder={t('GENERIC.TEXT.CODE')}
                            name="code"
                            value={situationTypeEdit?.code}
                            status={(!!situationTypeEdit.code && situationTypeEdit.code.length > 0 && situationTypeEdit.code.length < 10) ? 'success' : 'error'}
                            handleChange={changeInput}
                            fullWidth />
                    </Row.Item>
                    <Row.Item sm={12} md={12} lg={12}>
                        <Select medium
                            fullWidth
                            name="eventType"
                            className={styles['form-select-event-type']}
                            value={eventTypeValue?.value}
                            title={t('ABSENCE.TEXT.ABSENCE_TYPE')}
                            options={optionsEventType!}
                            status={!!eventTypeValue && !!eventTypeValue.value ? 'success' : 'error'}
                            handleChange={(e) => changeInput(e, 'eventType')} />
                    </Row.Item>

                    <Row.Item sm={12} md={12} lg={12}>
						<Container className={styles['form-option-off-scale']}>
							<Switch iconOff='times' 
									iconOn='check' 
                                    colorOn='#0353B5'
									name='offScale'
									text={t('SHADOWCAT.SITUATION_TYPE.OFF_SCALE')}
									sm
                                    value={situationTypeEdit?.offScale}
									handleChange={(e: React.ChangeEvent<HTMLInputElement>) => changeInput(e)}/>
						</Container>
					</Row.Item>

					<Row.Item sm={12} md={12} lg={12}>
						<Container className={styles['form-option-workdays-only']}>
							<Switch iconOff='times' 
									iconOn='check'
                                    colorOn='#0353B5'
									name='workdaysOnly'
									text={t('SHADOWCAT.SITUATION_TYPE.WORKDAYS_ONLY')} 
									sm
                                    value={situationTypeEdit?.workdaysOnly}
									handleChange={(e: React.ChangeEvent<HTMLInputElement>) => changeInput(e)} />
						</Container>
					</Row.Item>

                    <Row.Item sm={12} md={12} lg={12}>
						<Container className={styles['form-option-delete-compensation-date']}>
							<Switch iconOff='times' 
									iconOn='check'
									colorOn='#0353B5'
									name='deleteCompensationDate'
									text={t('SHADOWCAT.SITUATION_TYPE.DELETE_COMPENSATION_DATE_QUESTION')} 
									sm 
									value={situationTypeEdit?.deleteCompensationDate}
									handleChange={(e: React.ChangeEvent<HTMLInputElement>) => changeInput(e)} />
						</Container>
					</Row.Item>

                    <Row.Item sm={12} md={12} lg={12}>
                        <Radio inlineAll small
                            name="eSocialRadio"
                            title="eSocial"
                            options={options}
                            handleChange={(e) => { changeInput(e) }}
                            disableAll={false} />
                    </Row.Item>

                    {options[1].selected &&
                        <Row.Item sm={12} md={12} lg={12}>
                            <Select
                                medium
                                fullWidth
                                name="eSocialSituationTypeId"
                                value={eSocialValue?.value}
                                title={`${t('ABSENCE.TEXT.ABSENCE_TYPE')} eSocial`}
                                options={optionsESocial!}
                                status={!!eSocialValue && !!eSocialValue.value ? 'success' : 'error'}
                                handleChange={(e) => changeInput(e, 'eSocialSituationTypeId')} />
                        </Row.Item>
                    }
                </Row>

                <Container className={styles['form-situation-type-edit-actions']}>
                    <Button
                        danger
                        icon='times'
                        title={t('GENERIC.BUTTON.CANCEL.TEXT')}
                        className={styles['btn-rt']}
                        action={(e) => {
                            e.preventDefault();
                            cancelAction();
                        }} />

                    <Button
                        primary
                        title={t('GENERIC.BUTTON.SAVE.TEXT')}
                        loading={buttonLoading}
                        icon="save"
                        className={styles['btn-rt']}
                        action={(e) => save(e)} />

                </Container>
            </form>
        );
    };

    return (
        <Container className={styles['situation-types-rt-edit']}>
            {showPanel &&
                <Panel title={t('SHADOWCAT.SITUATION_TYPE.EDIT')}>
                    <>
                        {renderForm()}
                    </>
                </Panel>
            }

            {!showPanel &&
                renderForm()
            }

            {toastAlert.show &&
                <Toast
                    success={!toastAlert.danger}
                    danger={toastAlert.danger}
                    title={toastAlert.title}
                    icon={toastAlert.danger ? 'exclamation-circle' : 'check'}
                    positionTopRight
                    closeChange={() => setToastAlert({ ...toastAlert, show: false })}
                    message={toastAlert.message}
                    showTime={5000} />
            }
        </Container>
    );

};

export default Edit;