import moment from 'moment';

import { formatLocalDate } from '@optimuminterfaces/revex-react-components/dist/utils/DateTimeUtil';
import { translation as t } from '@optimuminterfaces/revex-react-components/dist/translation';

import { EmployeeSituation } from "../models/EmployeeSituation";
import { isAfterYear, isBeforeDate } from './DateTimeUtil';

export const validateEmployeeSituation = (employeeSituation: EmployeeSituation, updateOperation: boolean) => {
    
    if (updateOperation && !employeeSituation.haveSchedule && (employeeSituation.dailyHours === null || employeeSituation.dailyHours === undefined)) {
        return t('SHADOWCAT.EMPLOYEE_SITUATION.SELECT_WORKING_HOUR');
    }

    if (!!employeeSituation.situationType) {
       

        if (employeeSituation.startDate === null || employeeSituation.startDate === undefined) {
            return t('ABSENCE.DURATION_OPTIONS.INFORM_START_DATE');
        }

        if(isAfterYear(employeeSituation.startDate, employeeSituation.endDate!)){
            return t('SHADOWCAT.EMPLOYEE_SITUATION.LIMIT_YEAR', undefined, 2000);
        }

        if (employeeSituation.typeCalculateEnd === "1") {
            if (employeeSituation.endDate === null || employeeSituation.endDate === undefined) {
                return employeeSituation.haveSchedule ? t('ABSENCE.DURATION_OPTIONS.INFORM_END_DATE_AND_TIME') : t('ABSENCE.DURATION_OPTIONS.INFORM_END_DATE');

            } else if (!!employeeSituation.endDate && isBeforeDate(employeeSituation.endDate, employeeSituation.startDate,
                employeeSituation.haveSchedule ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD')) {
                return employeeSituation.haveSchedule ? t('SHADOWCAT.EMPLOYEE_SITUATION.END_DATE_TIME_BEFORE_START_DATE_TIME') :
                    t('SHADOWCAT.EMPLOYEE_SITUATION.END_DATE_BEFORE_START_DATE');

            } else if (employeeSituation.haveSchedule) {
                let startDate = moment.utc(employeeSituation.startDate, 'YYYY-MM-DD HH:mm');
                let endDate = moment.utc(employeeSituation.endDate, 'YYYY-MM-DD HH:mm');
                let duration = moment.duration(endDate.diff(startDate));

                if (duration.asHours() > 24) {
                    return t('SHADOWCAT.EMPLOYEE_SITUATION.END_DATE_MUST_EQUAL_START_DATE');
                }
            }

        } else if (employeeSituation.typeCalculateEnd === "2") {
            if (employeeSituation.endDateDays === null || employeeSituation.endDateDays === undefined ||
                employeeSituation.endDateHours === null || employeeSituation.endDateHours === undefined) {
                return employeeSituation.haveSchedule ? t('SHADOWCAT.EMPLOYEE_SITUATION.INFORM_HOURS_QUANTITY') :
                    t('SHADOWCAT.EMPLOYEE_SITUATION.INFORM_DAYS_QUANTITY');

            } else if (employeeSituation.haveSchedule && employeeSituation.endDateHours > 24) {
                return t('SHADOWCAT.EMPLOYEE_SITUATION.MAX_LIMIT_HOURS', undefined, 24);

            } else if (!employeeSituation.haveSchedule && employeeSituation.endDateDays > 2000) {
                return t('SHADOWCAT.EMPLOYEE_SITUATION.MAX_LIMIT_DAYS', undefined, 2000);

            } else if (employeeSituation.haveSchedule && employeeSituation.endDateHours < 1) {
                return t('SHADOWCAT.EMPLOYEE_SITUATION.MIN_LIMIT_HOURS', undefined, 1);

            } else if (!employeeSituation.haveSchedule && employeeSituation.endDateDays < 1) {
                return t('SHADOWCAT.EMPLOYEE_SITUATION.MIN_LIMIT_DAYS', undefined, 1);
            }
        }

        return null;

    } else {
        return t('SHADOWCAT.SITUATION_TYPE.SELECT_SITUATION_TYPE');
    }
};

// seta variável no window para auxiliar na atualização dos dados no Revex
export const setWindowShadowcatUpdateAbsence = (value: boolean) => {
    let myWindow = window as any;
    myWindow.shadowcat = {
        updateAbsence: value
    };
};