import React, { useEffect, useState } from 'react';

import { Button, Input, Loading, Modal, PageTitle, Panel, Radio, Row, Select, TextInformation, Toast } from '@optimuminterfaces/revex-react-components/dist/components';
import { translation as t } from '@optimuminterfaces/revex-react-components/dist/translation/index';
import { downloadCsv } from '@optimuminterfaces/revex-react-components/dist/utils/FileUtil';
import { SituationType } from '../../../../models/SituationType';

import { fetchESocial } from '../../../../services/situationTypes.services';
import { fetchSituationsTypes, putHideSituationType, generateReportSituationTypes } from '../../../../services/situationTypes.services';
import { getEnvironment } from '../../../../services/auth.services';
import TableListPagination from '../../../organism/TableListPagination/TableListPagination';
import New from '../New/New';
import Edit from '../Edit/Edit';

import styles from './List.module.scss';

interface ListProps{
	Container?: any
};

interface Filter{
	nameFilter: string | null,
	showDisabledFilter: boolean
};

interface SelectObject {
    key: string;
    value: string;
    selected: boolean;
};

const List = ({Container='div'}: ListProps) => {
	// estado do componente
	const [situationTypes, setSituationTypes] = useState<SituationType[]>();
	const [situationTypeStatus, setSituationTypeStatus] = useState<string>('waiting');
	const [selectESocial, setSelectESocial] = useState<SelectObject[]>([]);
	const [filters, setFilters] = useState<Filter>({ nameFilter: null, showDisabledFilter: false });
	const [showModalNewSituationType, setShowModalNewSituationType] = useState<Boolean>(false);
	const [situationTypeToEdit, setSituationTypeToEdit] = useState<SituationType | null>(null);
	const [loadingButtonHide, setLoadingButtonHide] = useState<boolean>(false);
	const [loadingButtonCsv, setLoadingButtonCsv] = useState<boolean>(false);
	const [toastAlert, setToastAlert] = useState<{show:boolean, title: string, message: string, danger: boolean}>({show: false, title: '', message: '', danger: false});
	const [options, setOptions] = useState<SelectObject[]>([
		{
			key: 'able',
			value: t('GENERIC.TEXT.ACTIVE'),
			selected: true
		},
		{
			key: 'disabled',
			value: t('GENERIC.TEXT.HIDDEN'),
			selected: false
		}
	]);

	// Lista de ações permitidas
    const permissions = getEnvironment().actions;

	useEffect(() => {
		findSituationTypes(null);

		// Busca tipos de ausência do eSocial
		(async () => {
			try {
				const jsonReturned = await fetchESocial();

				if (jsonReturned) {
					let listESocial: SelectObject[] = [];

					jsonReturned.eSocialSituationTypes.forEach((eSocialSituationType:any) => {
						listESocial.push({
							key: eSocialSituationType.id, 
							value: `${eSocialSituationType.eSocialId} - ${eSocialSituationType.name}`, 
							selected: false
						});
					});

					setSelectESocial(listESocial);
				}

			} catch (error) {
				console.log(error);
			}
		})();

    }, []);

	const findSituationTypes = (showDisabled: boolean | null) => {
		setSituationTypeStatus('waiting');

		(async () => {
			try {
				let eSocialSituationTypeIds: string[] = [];

				if (selectESocial.length > 0) {
					eSocialSituationTypeIds = selectESocial
						.filter(eSocial => eSocial.selected === true)
						.map(eSocial => eSocial.key);
				}

				const jsonReturned = await fetchSituationsTypes({
					situationTypeName: filters.nameFilter,
					showDisabled: showDisabled != null ? showDisabled : filters.showDisabledFilter,
					eSocialSituationTypeIds: eSocialSituationTypeIds.length > 0 ? eSocialSituationTypeIds.join(",") : null
				});

				if(jsonReturned){
					setSituationTypes(jsonReturned.situationTypes);
					setSituationTypeStatus('success');

				}else{
					setSituationTypeStatus('error');
				}

			} catch (error) {
				setSituationTypeStatus('error');
				console.log(error);
			}
		})();
	};

	const generateCsv = () => {
		setLoadingButtonCsv(true);

		(async () => {
			try {
				let fileName = `${t('SHADOWCAT.SITUATION_TYPE.TITLE')}.csv`;
				let eSocialSituationTypeIds: string[] = [];

				if (selectESocial.length > 0) {
					eSocialSituationTypeIds = selectESocial
						.filter(eSocial => eSocial.selected === true)
						.map(eSocial => eSocial.key);
				}

				const jsonReturned = await generateReportSituationTypes({
					situationTypeName: filters.nameFilter,
					showDisabled: filters.showDisabledFilter,
					eSocialSituationTypeIds: eSocialSituationTypeIds.length > 0 ? eSocialSituationTypeIds.join(",") : null
				});

				if(jsonReturned){
					downloadCsv(jsonReturned, fileName);
					setLoadingButtonCsv(false);
				}

			} catch (error) {
				console.log(error);
			}
		})();
	};

	const hideSituationType = (id?: string) => {
		if (!!id) {
			setLoadingButtonHide(true);

			(async () => {
				try {
					const jsonReturned = await putHideSituationType(id);

					if (jsonReturned) {
						if (String(jsonReturned.status).toLowerCase() === 'error') {
							setToastAlert({
								...toastAlert,
								show: true,
								title: t('SHADOWCAT.EMPLOYEE_SITUATION.VALIDATION_ERROR'),
								message: jsonReturned.message,
								danger: true
							});

						} else {
							let listSituationTypes: SituationType[] = [];

							situationTypes?.forEach(situtationType => {
								if (situtationType.id !== id) {
									listSituationTypes.push(situtationType);
								}
							});

							setSituationTypes(listSituationTypes);

							setToastAlert({
								...toastAlert,
								show: true,
								title: t('GENERIC.TEXT.OPERATION_PERFORMED'),
								message: jsonReturned.message,
								danger: false
							});
						}
					}

				} catch (error) {
					console.log(error);
				}

				setLoadingButtonHide(false);
			})();
		}
	};

	const handleColumnRender = ( columnValue:any, columnName:string, columnIndex:number, row: SituationType ) => {
		switch(columnName){
			case 'name':
				return (
					<abbr title={columnValue}>
						<Container className={styles['text-table']}>
							{columnValue}
						</Container>
					</abbr>
				)
			case 'disabled':
				return columnValue===false?t('GENERIC.TEXT.ABLE'):t('GENERIC.TEXT.DISABLED');

			case 'eSocialSituationTypeId':
				return (
					<abbr title={columnValue ? row.esocial.name : '-'}>
						<Container className={styles['text-table']}>
							{columnValue ? row.esocial.name : '-'}
						</Container>
					</abbr>
				)

			case 'additionalData':
				return <Container>
					<Container>
						<span>{t('GENERIC.TEXT.EVENT_TYPE')}:</span><span className={styles['bold']}>{row.eventType?t('SHADOWCAT.SITUATION_TYPE.EVENT_TYPE.'+ row.eventType):'-'}</span>
					</Container>
					<Container>
						<span>{t('SHADOWCAT.SITUATION_TYPE.OFF_SCALE')}:</span><span className={styles['bold']}>{row.offScale===true?t('GENERIC.TEXT.YES'):t('GENERIC.TEXT.NO')}</span>
					</Container>
					<Container>
						<span>{t('SHADOWCAT.SITUATION_TYPE.WORKDAYS_ONLY')}:</span><span className={styles['bold']}>{row.workdaysOnly===true?t('GENERIC.TEXT.YES'):t('GENERIC.TEXT.NO')}</span>
					</Container>
					<Container>
						<span>{t('SHADOWCAT.SITUATION_TYPE.DELETE_COMPENSATION_DATE')}:</span><span className={styles['bold']}>{row.deleteCompensationDate===true?t('GENERIC.TEXT.YES'):t('GENERIC.TEXT.NO')}</span>
					</Container>
				</Container>
				

			case 'actions':
				return (
					<>
						{permissions.includes(231) && 
							<Button warn sm
								title={t('GENERIC.BUTTON.EDIT.TEXT')}
								icon="edit"
								className={styles['table-item-action']}
								action={() => setSituationTypeToEdit(row)} />
						}
						{permissions.includes(232) && 
							<Button danger sm
								title={filters.showDisabledFilter ? t('GENERIC.BUTTON.UNHIDE.TEXT') : t('GENERIC.BUTTON.HIDE.TEXT')}
								icon={filters.showDisabledFilter ? "eye" : "eye-slash"}
								className={styles['table-item-action']}
								loading={loadingButtonHide}
								action={() => hideSituationType(row.id)} />
						}
					</>
				)
			default:
				return columnValue;
		}
	};

	const situationTypeEvent = (event:any) => {
		if(event.name==='success'){
			let st = situationTypes;
			st = st?.concat(event.data);
			setSituationTypes(st);
		}
		setShowModalNewSituationType(false);
	}

	const situationTypeEditEvent = (event: any) => {
		if (!!event && event.name === 'success') {
			let types: SituationType[] = [];
			let st: SituationType = event.data;

			situationTypes?.forEach(situationType => {
				types.push(situationType.id === st.id ? st : situationType);
			});

			setSituationTypes(types);
		}

		setSituationTypeToEdit(null);
	};

	const handleChangeSelectESocial = (key: string) => {
		setSelectESocial(selectESocial.map(eSocial => {
			return { ...eSocial, selected: (eSocial.key === key ? !eSocial.selected : eSocial.selected) };
		}));
	};

	const handleChangeRadio = (key: string) => {
		let disabled = key === 'disabled' ? true : false;
		
		setFilters({ ...filters, showDisabledFilter: disabled });
		setOptions([
			{
				key: 'able',
				value: t('GENERIC.TEXT.ACTIVE'),
				selected: !disabled
			},
			{
				key: 'disabled',
				value: t('GENERIC.TEXT.HIDDEN'),
				selected: disabled
			}
		]);

		findSituationTypes(disabled);
	};

	return(
		<Container className={styles['situation-types-list-rt']}>
			<Row>
				<Row.Item sm={12} md={12} lg={12}>
					<PageTitle.Small title={t('ABSENCE.TEXT.ABSENCE_TYPE')}/>
				</Row.Item>

				<Row.Item lg={12} md={12} sm={12}>
					<Panel title="Filtros" noTitleBorder>
						<Row>
							<Row.Item lg={6} md={6} sm={12}>
								<Input
									id="nameFilter"
									medium
									fullWidth
									name="nameFilter"
									title={t('SHADOWCAT.SITUATION_TYPE.NAME')}
									placeholder={t('SHADOWCAT.SITUATION_TYPE.NAME')}
									value={!!filters.nameFilter ? filters.nameFilter : ''}
									handleChange={event => setFilters({ ...filters, nameFilter: event.target.value.length === 0 ? null : event.target.value })} />
							</Row.Item>
							<Row.Item lg={3} md={3} sm={12}>
								<Select
									medium
									multiple
									fullWidth
									name="selectESocial"
									title={`${t('ABSENCE.TEXT.ABSENCE_TYPE')} eSocial`}
									value={`${t('ABSENCE.TEXT.ABSENCE_TYPE')} eSocial`}
									selectedItemNameLength={13}
									selectItemsToShowLength={1}
									options={selectESocial}
									disabled={selectESocial.length === 0}
									handleChange={handleChangeSelectESocial} />
							</Row.Item>
							<Row.Item lg={2} md={2} sm={12}>
								<Radio
									small
									inline
									disableAll={false}
									name="showDisabledFilter"
									title={t('GENERIC.TEXT.STATUS')}
									options={options}
									handleChange={event => handleChangeRadio(event.target.value)} />
							</Row.Item>
							<Row.Item lg={1} md={1} sm={12}>
								<Button 
									primary 
									md
									title={t('GENERIC.BUTTON.SEARCH.TEXT')}
									icon="search"
									loading={situationTypeStatus === 'waiting' ? true : false}
									className={styles['button-action']}
									action={() => findSituationTypes(null)} />
							</Row.Item>
						</Row>
					</Panel>
				</Row.Item>
			</Row>

			{(situationTypeStatus === 'waiting') && 
				<Row>
					<Row.Item lg={12} md={12} sm={12}>
						<Panel>
							<Loading text={t('GENERIC.TEXT.LOADING')}/>
						</Panel>
					</Row.Item>
				</Row>
			}

			{(situationTypeStatus === 'error') && 
				<Row>
					<Row.Item lg={12} md={12} sm={12}>
						<Panel>
							<TextInformation 
								icon='exclamation-circle'
								text={t('GENERIC.TEXT.UNABLE_RETURN')} />
						</Panel>
					</Row.Item>
				</Row>
			}

			{situationTypes &&  situationTypeStatus === 'success' &&
				<Row>
					<Row.Item lg={12} md={12} sm={12}>
						<Panel noPadding>
							<Row>
								<Row.Item lg={12} md={12} sm={12}>
									<Container className={styles['situation-types-list-rt-actions']}>
										<>
											{permissions.includes(233) && 
												<Button 
													className={styles['button-rt-new-situation']} 
													primary
													icon='file-csv'
													title={t('GENERIC.BUTTON.DOWNLOAD.TEXT_WITH_ARGS', undefined, 'CSV')}
													loading={loadingButtonCsv}
													action={() => generateCsv()} />
											}
											{permissions.includes(230) &&
												<Button
													className={styles['button-rt-new-situation']}
													primary
													icon='plus'
													title={t('SHADOWCAT.SITUATION_TYPE.ADD_NEW',undefined)}
													action={() => setShowModalNewSituationType(true)} />
											}
										</>
									</Container>
									
								</Row.Item>
								<Row.Item lg={12} md={12} sm={12}>
									<>
										{situationTypes.length === 0 &&
											<TextInformation 
												icon="search"
												text={t('SHADOWCAT.SITUATION_TYPE.NO_SITUATION_FOUND')} />
										}
										{situationTypes.length > 0 &&
										
											<TableListPagination list={situationTypes}
															headers={[t('GENERIC.TEXT.CODE'),t('SHADOWCAT.SITUATION_TYPE.NAME'),`${t('ABSENCE.TEXT.ABSENCE_TYPE')} eSocial`, t('GENERIC.TEXT.ADDITIONAL_DATA'),t('GENERIC.TEXT.STATUS'), t('GENERIC.TEXT.ACTIONS')]}
															columns={['code','name','eSocialSituationTypeId', 'additionalData','disabled', 'actions']}
															handleColumnRender={handleColumnRender} />
										
										}
									</>
								</Row.Item>
							</Row>
						</Panel>
					</Row.Item>
				</Row>
			}

			{showModalNewSituationType &&
                <Modal
                    title={t('SHADOWCAT.SITUATION_TYPE.NEW_ABSENCE_TYPE')}
                    md
					className={styles['modal-new-situation-type']}
                    showClose
                    handleClose={() => { setShowModalNewSituationType(false) }}>

                    <New 
						noPanel
                        saveAction={situationTypeEvent}
                        cancelAction={situationTypeEvent} /> 

                </Modal>
            }

			{!!situationTypeToEdit &&
				<Modal
					title={t('SHADOWCAT.SITUATION_TYPE.EDIT')}
					md
					className={styles['modal-new-situation-type']}
					showClose
					handleClose={() => setSituationTypeToEdit(null)} >

						<Edit
							situationType={situationTypeToEdit}
							showPanel={false}
							callBack={situationTypeEditEvent}
							cancelAction={situationTypeEditEvent} />
				</Modal>
			}

			{toastAlert.show && 
				<Toast 
					success={!toastAlert.danger}
					danger={toastAlert.danger}
					title={toastAlert.title}
					icon={toastAlert.danger ? 'exclamation-circle' : 'check'}
					positionTopRight
					closeChange={() => setToastAlert({...toastAlert, show: false})}
					message={toastAlert.message} 
					showTime={5000} />
			}

		</Container>
	)
}

export default List;