import { WorkingHour } from "../models/WorkingHour";

interface SelectWorkingHours {
    key: string,
    value: string,
    selected: boolean
};

export const buildSelectWorkingHours = (workingHours: WorkingHour[]) => {
    let optionsSelect: SelectWorkingHours[] = [];

    workingHours.forEach(workingHour => {
        optionsSelect.push({
            key: workingHour.hours,
            value: buildValueToSelect(workingHour),
            selected: false
        });
    });

    return optionsSelect;
};

const buildValueToSelect = (workingHour: WorkingHour) => {
    let hours = workingHour.hours.split('-');
    let entranceAndExit = workingHour.hasLunchWindow ? workingHour.hours : `${hours[0]}-${hours[hours.length - 1]}`;
    let description = workingHour.hasLunchWindow ?
        `Jornada com janela de intervalo ${workingHour.lunchWindowStart}-${workingHour.lunchWindowEnd} duração ${workingHour.lunchDuration} minutos` :
        'Jornada fixa';

    return `${entranceAndExit} (${workingHour.workload}) ${workingHour.hours} - ${description}`;
};