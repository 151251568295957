import { SituationType } from "../models/SituationType";
import API, { defaultErrorMessage } from "./core";

export const fetchSituationsTypes = async (params?: any) => {
    try {
        return API.get('/rest/absencesAndHolidays/situationTypes', { params })
            .then(
                response => {
                    if (String(response.data.status).toLowerCase() === 'error') {
                        return null;
                    } else {
                        return response.data.data;
                    }
                }, error => {
                    console.log(error);
                    return null;
                }
            )
    } catch (error) {
        return error;
    }
};

export const fetchESocial = async () => {
    try {
        return API.get('/rest/absencesAndHolidays/situationTypes/getESocial', {})
            .then(
                response => {
                    if (String(response.data.status).toLowerCase() === 'error') {
                        return null;
                    } else {
                        return response.data.data;
                    }
                }, error => {
                    console.log(error);
                    return null;
                }
            )
    } catch (error) {
        return error;
    }
};

export const postSituationType = async (params: SituationType) => {
    try {
        return API.post('/rest/absencesAndHolidays/situationTypes', { ...params })
            .then(
                response => {
                    return response.data;
                }, error => {
                    console.log(error);
                    return defaultErrorMessage(error.response.status);
                }
            )
    } catch (error) {
        return error;
    }
};

export const putSituationType = async (id: string, params?: any) => {
    try {
        return API.put(`/rest/absencesAndHolidays/situationTypes/${id}`, { ...params })
            .then(
                response => {
                    return response.data;
                }, error => {
                    console.log(error);
                    return defaultErrorMessage(error.response.status);
                }
            );

    } catch (error) {
        return error;
    }
};

export const putHideSituationType = async (id: string, params?: any) => {
    try {
        return API.put(`/rest/absencesAndHolidays/situationTypes/hide/${id}`, {})
            .then(
                response => {
                    return response.data;
                }, error => {
                    console.log(error);
                    return defaultErrorMessage(error.response.status);
                }
            );
    } catch (error) {
        return error;
    }
};

export const generateReportSituationTypes = async (params: any) => {
    try {
        return API.get('/rest/absencesAndHolidays/situationTypes/downloadReportCsvSituationTypes', { params: params, headers: { 'Content-type': 'application-json' }, responseType: 'arraybuffer' })
            .then(
                response => {
                    if (String(response.data.status).toLowerCase() === 'error') {
                        return null;
                    } else {
                        return response.data;
                    }
                }, error => {
                    console.log(error);
                    return null;
                }
            );
    } catch (error) {
        return error;
    }
};