import {abbreviation} from "@optimuminterfaces/revex-react-components/dist/utils/StringUtils";
import {translation as t} from '@optimuminterfaces/revex-react-components/dist/translation/index'

import { SituationType } from "../models/SituationType";

interface SelectSituationTypes {
    key: string;
    value: string;
    selected: boolean;
};

export const buildSituationTypesSelect = (situationTypes: SituationType[], keySelected: string | undefined | null) => {
    let optionsSelect: SelectSituationTypes[] = [];

    situationTypes.forEach(situationType => {
        optionsSelect.push({
            key: situationType.id!,
            value: abbreviation(situationType.name!, 200),
            selected: (!!keySelected && situationType.id === keySelected) ? true : false
        });
    });

    return optionsSelect;
};

export const translateEventType = (eventType: string | undefined | null) => {
    if (!!eventType) {
        if (eventType.toUpperCase() === 'ALLOWANCE') {
            return t('SHADOWCAT.SITUATION_TYPE.EVENT_TYPE.ALLOWANCE');

        } else if (eventType.toUpperCase() === 'PAYMENT') {
            return t('SHADOWCAT.SITUATION_TYPE.EVENT_TYPE.PAYMENT');

        } else if (eventType.toUpperCase() === 'DISCOUNT_ON_BANK_OF_HOURS') {
            return t('SHADOWCAT.SITUATION_TYPE.EVENT_TYPE.DISCOUNT_ON_BANK_OF_HOURS');

        }
    }

    return '-';
};