import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { AvatarName, Pagination, PerPage, Row, Table } from "@optimuminterfaces/revex-react-components/dist/components";

import imageUser from '../../../../../assets/images/avatar.png';
import { EmployeeSituationAdditionalData } from "../../../../../models/EmployeeSituationAdditionalData";
import { buildPeriondDescriptionTogether } from "../../../../../utils/DateTimeUtil";

import styles from './ListPagination.module.scss';

import {translation as t} from '@optimuminterfaces/revex-react-components/dist/translation/index'

interface ListPaginationProps {
    list: EmployeeSituationAdditionalData[];
    Container?: any;
};

interface Filters {
    currentPage: number;
    pageSize: number;
};

const ListPagination = ({ list, Container = 'div' }: ListPaginationProps) => {

    const URL_MICRO_FRONT = process.env.REACT_APP_URL;

    const [currentList, setCurrentList] = useState<EmployeeSituationAdditionalData[]>([]);
    const [listTotal, setListTotal] = useState<number>(list.length);
    const [quantityPerPage, setQuantityPerPage] = useState<number>(10);
    const [filters, setFilters] = useState<Filters>({
        currentPage: 1,
        pageSize: 10
    });

    useEffect(() => {
        setFilters({ ...filters, currentPage: 1 });
        setListTotal(list.length || 0);
        handlePageChanged(1, filters.pageSize);
    }, [list]);

    const handlePageChanged = (page: number, quantity: number) => {
        let size: number = !!quantity ? quantity : filters.pageSize;

        setFilters({
            currentPage: page,
            pageSize: size
        });

        let filteredList: EmployeeSituationAdditionalData[] = [];
        try {
            filteredList = list.slice(((page - 1) * size), (page * size));
        } catch (e) {
            filteredList = [];
        }

        setCurrentList(filteredList);
    };

    const handlePageSize = (size: number) => {
        setQuantityPerPage(size);
        handlePageChanged(1, size);
    };

    const renderEmployeeSituation = (employeeSituation: EmployeeSituationAdditionalData) => {
        return (
            <Table.BodyItem key={`employee-situation-${employeeSituation.id}`}>
                <Table.BodyItem.Item>
                    <AvatarName
                        color=""
                        image={`${URL_MICRO_FRONT}${imageUser}`}
                        size="m"
                        text={employeeSituation.employeeName}
                        subText={employeeSituation.occupationName} />
                </Table.BodyItem.Item>
                <Table.BodyItem.Item align="center">{employeeSituation.workShift}</Table.BodyItem.Item>
                <Table.BodyItem.Item align="center">{employeeSituation.scaleNameName}</Table.BodyItem.Item>
                <Table.BodyItem.Item align="center">
                    <abbr title={employeeSituation.situationTypeName}>
                        <Container className={styles['text-table']}>
                            {employeeSituation.situationTypeName} 
                        </Container>
                    </abbr>
                </Table.BodyItem.Item>
                <Table.BodyItem.Item align="center">
                    <abbr title={employeeSituation.eSocialId}>
                        <Container className={styles['text-table']}>
                            {employeeSituation.eSocialId}
                        </Container>
                    </abbr>
                </Table.BodyItem.Item>
                <Table.BodyItem.Item align="center">{buildPeriondDescriptionTogether(employeeSituation.startDate, employeeSituation.endDate, employeeSituation.haveSchedule)}</Table.BodyItem.Item>
            </Table.BodyItem>
        );
    };

    return (
        <Container className={styles['list-all-by-instance-pagination-rt']}>
            <Row>
                <Row.Item lg={12} md={12} sm={12}>
                    <Container className={styles['pagination']}>
                        <PerPage
                            selected={quantityPerPage}
                            handlePerPageChanged={(value) => handlePageSize(value)} />
                        
                        <Pagination.Compact
                            currentPage={filters.currentPage}
                            pageLimit={filters.pageSize}
                            totalRecords={listTotal}
                            handlePageChanged={handlePageChanged} />
                    </Container>
                </Row.Item>

                {(!!currentList && currentList.length > 0) &&
                    <Row.Item lg={12} md={12} sm={12}>
                        <Table primary striped>
                            <Table.Header>
                                <Table.HeaderItem>
                                    <Table.HeaderItem.Item>{t('GENERIC.TEXT.EMPLOYEE')}</Table.HeaderItem.Item>
                                    <Table.HeaderItem.Item>{t('GENERIC.TEXT.SHIFT')}</Table.HeaderItem.Item>
                                    <Table.HeaderItem.Item>{t('GENERIC.TEXT.SCALE')}</Table.HeaderItem.Item>
                                    <Table.HeaderItem.Item>{t('ABSENCE.TEXT.ABSENCE_TYPE')}</Table.HeaderItem.Item>
                                    <Table.HeaderItem.Item>{t('ABSENCE.TEXT.ESOCIAL_ID')}</Table.HeaderItem.Item>
                                    <Table.HeaderItem.Item>{t('GENERIC.TEXT.PERIOD')}</Table.HeaderItem.Item>
                                </Table.HeaderItem>
                            </Table.Header>
                            <Table.Body>
                                {currentList.map(employeeSituation => {
                                    return renderEmployeeSituation(employeeSituation);
                                })}
                            </Table.Body>
                        </Table>
                    </Row.Item>
                }  
            </Row>
        </Container>
    );

};

ListPagination.propTypes = {
    list: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default ListPagination;