import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Table, Pagination, PerPage } from '@optimuminterfaces/revex-react-components/dist/components/index';

import styles from './TableListPagination.module.scss';

interface TableListPaginationProps{
	list: any, 
	headers?: string[], 
	columns?: string[], 
	handleColumnRender?: Function, 
	className?: string, 
	Container?: any
}

const TableListPagination = ({ list, headers, columns, handleColumnRender, className, Container = 'div' }: TableListPaginationProps) => {
    const [currentList, setCurrentList] = useState([]);
    const [listTotal, setListTotal] = useState(list.length);
	const [quantityPerPage, setQuantityPerPage] = useState(10);

    const [filters, setFilters] = useState({
        currentPage: 1,
        pageSize: 12,
    });

	const handlePageSize = (size: number) => {
        setQuantityPerPage(size);
        handlePageChanged(1, size);
    };

	const handlePageChanged = (page: number, quantity: number) => {
        let size = !!quantity ? quantity : filters.pageSize;
        setFilters({ currentPage: page, pageSize: size });
        let filteredList = [];

        try {
            filteredList = list.slice(((page - 1) * size), (page * size));
        } catch (e) {
            filteredList = [];
        }

        setCurrentList(filteredList);
    };

    const parseColumn = (columnValue: string, columnName: string, columnIndex: number, row: any ) => {
        if(handleColumnRender instanceof Function){
            return handleColumnRender(columnValue, columnName, columnIndex, row);
        }
        return columnValue;
		
    };

	const renderBodyItem = () => {

		if(currentList.length > 0){
			return currentList.map((row, rowIndex) => (
					<Table.BodyItem key={`rowIndex-${rowIndex}`}>
						{columns?.map((column, columnIndex) => (
							<Table.BodyItem.Item key={`columnIndex-${columnIndex}`} 
								className={`table-list-pagination-body-column-${columnIndex}`}>
								{parseColumn(row[column], column, columnIndex, row)}
							</Table.BodyItem.Item>
						))}
					</Table.BodyItem>
				   ));
			
		}else{
			return (
				<Table.BodyItem>
					<Table.BodyItem.Item colSpan={headers!.length}>
						<Container style={{ textAlign: 'center' }}>
							Não existem registros
						</Container>
					</Table.BodyItem.Item>
				</Table.BodyItem>
			)
		}
	}

    useEffect(() => {
        setFilters({ currentPage: 1, pageSize: 12 });
        setListTotal(list.length || 0);
        handlePageChanged(1, filters.pageSize);
    }, [list]);
    return (
        <Container className={`${styles['table-list-pagination-rt']} ${className || ''}`.trim()}>
			{listTotal/filters.pageSize > 1 &&
			<Container className={styles['actions']}>
				<Pagination.Compact
					currentPage={filters.currentPage}
					pageLimit={filters.pageSize}
					totalRecords={listTotal}
					handlePageChanged={handlePageChanged} />

				<PerPage
					selected={quantityPerPage}
					handlePerPageChanged={(value) => handlePageSize(value)} />
			</Container>
			}
            <Table striped primary >

                <Table.Header>
                    <Table.HeaderItem>
                        {headers?.map((header, i) => (
                            <Table.HeaderItem.Item key={`header-${i}`}>{header}</Table.HeaderItem.Item>
                        ))}
                    </Table.HeaderItem>
                </Table.Header>

                <Table.Body>
                    {renderBodyItem()}
                </Table.Body>
            </Table>
        </Container>
    );
};

TableListPagination.propTypes = {
    list: PropTypes.arrayOf(PropTypes.object),
    headers: PropTypes.arrayOf(PropTypes.string),
    columns: PropTypes.arrayOf(PropTypes.string),
    handleColumnRender: PropTypes.func,
	Container: PropTypes.any
};

export default TableListPagination;
