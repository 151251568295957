import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { getToken } from './auth.services';

export const formatDataToFormData = (data: any) => {
    let formData = new FormData();
    Object.keys(data).forEach(key => formData.append(key, data[key]));

    return formData;
}

const API = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

API.defaults.headers.post['Content-Type'] = 'application/json';
API.defaults.headers['Content-Type'] = 'application/json';

const onRequest = async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
    let token: string | null = await getToken();

    if (process.env.NODE_ENV === 'development' && !token) {
        token = process.env.REACT_APP_TOKEN!;
    }

    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;

}

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
    return Promise.reject(error);
}

const onResponse = (response: AxiosResponse): AxiosResponse => {
    if (response.status === 200) {
        return response;
    } else {
        console.log(response);
        return response;
    }

}

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
    if(error.response?.status===401){
		window.location.href = '/#/auth';
	}
    return Promise.reject(error);
}

const setupInterceptorsTo = (axiosInstance: AxiosInstance): AxiosInstance => {
    axiosInstance.interceptors.request.use(onRequest, onRequestError);
    axiosInstance.interceptors.response.use(onResponse, onResponseError);
    return axiosInstance;
}

setupInterceptorsTo(API);

export const defaultErrorMessage = (errorCode: string) => {
	return {status: 'ERROR', message: `Problema encontrado: Código ${errorCode}`}
}



export default API;
