import React from 'react';

import TextInformation from '@optimuminterfaces/revex-react-components/dist/components/atoms/TextInformation/TextInformation';

import styles from './Dashboard.module.scss';

interface DashboardProps {
    Container?: any
};

const Dashboard = ({ Container = 'div' }: DashboardProps) => {

    return (
        <Container className={styles['dashboard-shadowcat-rt']}>
            <TextInformation text="Projeto Shadowcat" subText="Serviço de ausências, atestados e feriados" icon="island-tropical" />
        </Container>
    )

};

export default Dashboard;