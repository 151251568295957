import React, { useEffect, useState } from 'react';

import { Button, ButtonDropdown, DateTimePicker, Loading, Modal, PageTitle, Pagination, Panel, PerPage, Row, Select, Table, TextArea, TextInformation, Timeline, Toast } from '@optimuminterfaces/revex-react-components/dist/components';

import { translation as t } from '@optimuminterfaces/revex-react-components/dist/translation';

import { formatLocalDate } from '@optimuminterfaces/revex-react-components/dist/utils/DateTimeUtil';

import Edit from '../Edit/Edit';
import New from '../New/New';

import { Cid } from '../../../../models/Cid';
import { MedicalCertificate } from '../../../../models/MedicalCertificate';
import { Employee } from '../../../../models/Employee';

import { getEnvironment } from '../../../../services/auth.services';
import { deleteMedicalCertificate, fetchAdditionalData, fetchMedicalCertificates, getPhoto, postUpdateStatus } from '../../../../services/medicalCertificates.services';

import { buildPeriods } from '../../../../utils/DateTimeUtil';
import { userIsOnlyEmployee } from '../../../../utils/DeveloperUtil';
import { translateStatus } from '../../../../utils/MedicalCertificateUtil';

import styles from './List.module.scss';

interface ListProps {
    Container?: any;
}

interface FilterProps {
    startDate: string | null;
    endDate: string | null;
    employeeIds: string | null;
    cidIds: string | null;
    status: string;
    statusName: string;
    pageNumber: number;
    quantityPerPage: number;
    totalElements: number;
}

interface SelectProps {
    key: string;
    value: string;
    selected: boolean;
}

interface ReturnSelectHandleChanges {
    name: string;
    value: string;
}

interface ModalShowPhoto {
    show: boolean;
    status: string;
    text: string;
    medicalCertificate: MedicalCertificate | null;
    descriptions: Description[];
}

interface ModalConfirmation {
    show: boolean;
    loading: boolean;
    question: string;
    medicalCertificateId: string;
    status: string;
    description: string;
}

interface ModalEdit {
    show: boolean;
    medicalCertificateToEdit: MedicalCertificate | null;
}

interface Description {
    description: string;
    type: string;
    created: string;
    createdBy: string;
    userName: string;
}

interface ToastMessage {
    show: boolean;
    title: string;
    message: string;
    danger: boolean;
}

const List = ({ Container = 'div' }: ListProps) => {

    const [medicalCertificatesStatus, setMedicalCertificatesStatus] = useState<string>('WAITING');
    const [medicalCertificates, setMedicalCertificates] = useState<MedicalCertificate[]>([]);
    const [medicalCertificatePhoto, setMedicalCertificatePhoto] = useState<any>(null);
    const [modalShowPhoto, setModalShowPhoto] = useState<ModalShowPhoto>({
        show: false,
        status: 'WAITING',
        text: '',
        medicalCertificate: null,
        descriptions: []
    });
    const [modalConfirmation, setModalConfirmation] = useState<ModalConfirmation>({
        show: false,
        loading: false,
        question: t('SHADOWCAT.MEDICAL_CERTIFICATE.LIST.APPROVED_CONFIRMATION'),
        medicalCertificateId: '',
        status: 'APPROVED',
        description: ''
    });
    const [showModalNew, setShowModalNew] = useState<boolean>(false);
    const [modalEdit, setModalEdit] = useState<ModalEdit>({
        show: false,
        medicalCertificateToEdit: null
    });
    const [cids, setCids] = useState<SelectProps[]>([]);
    const [employees, setEmployees] = useState<SelectProps[]>([]);
    const [statusOptions, setStatusOptions] = useState<SelectProps[]>([
        {
            key: 'ALL',
            value: `${t('GENERIC.TEXT.DISPLAY')} ${t('GENERIC.TEXT.ALL')}`,
            selected: true
        },
        {
            key: 'WITHOUT_STATUS',
            value: t('GENERIC.TEXT.WITHOUT_STATUS'),
            selected: false
        },
        {
            key: 'APPROVED',
            value: t('GENERIC.TEXT.APPROVED'),
            selected: false
        },
        {
            key: 'DISAPPROVED',
            value: t('GENERIC.TEXT.DISAPPROVED'),
            selected: false
        },
        {
            key: 'CANCELED',
            value: t('GENERIC.TEXT.CANCELED'),
            selected: false
        },
        {
            key: 'CERTIFICATE_OF_JUSTIFICATION',
            value: t('SHADOWCAT.MEDICAL_CERTIFICATE.GENERIC.CERTIFICATE_OF_JUSTIFICATION'),
            selected: false
        }
    ]);
    const [filters, setFilters] = useState<FilterProps>({
        startDate: null,
        endDate: null,
        employeeIds: null,
        cidIds: null,
        status: "ALL",
        statusName: `${t('GENERIC.TEXT.DISPLAY')} ${t('GENERIC.TEXT.ALL')}`,
        pageNumber: 1,
        quantityPerPage: 30,
        totalElements: 0
    });
    const [toastAlert, setToastAlert] = useState<ToastMessage>({
        show: false,
        title: '',
        message: '',
        danger: false
    });

    // Lista de ações permitidas
    const environment = getEnvironment();
    const permissions = environment.actions;
    const saveAbsencesWithTime = environment.saveAbsencesWithTime;
    const employeeId = environment.employeeId;
    const isOnlyEmployee = userIsOnlyEmployee(environment.groupName, environment.groupIsDefault);

    useEffect(() => {
        findByParams(filters);
        findAdditionalData();
    }, []);

    const findByParams = async (params: FilterProps) => {
        try {
            setMedicalCertificatesStatus('WAITING');

            if (isOnlyEmployee) {
                params = { ...params, employeeIds: employeeId };
                handleChangeFilters('employeeIds', employeeId);
            }

            const jsonReturned = await fetchMedicalCertificates(params);

            if (jsonReturned) {
                setMedicalCertificates(jsonReturned.medicalCertificates);
                handleChangeFilters('totalElements', jsonReturned.medicalCertificatesQuantity);
                setMedicalCertificatesStatus(jsonReturned.medicalCertificates.length > 0 ? 'SUCCESS' : 'EMPTY_LIST');

            } else {
                setMedicalCertificatesStatus('ERROR');
            }

        } catch (error) {
            setMedicalCertificatesStatus('ERROR');
            console.log(error);
        }
    };

    const findAdditionalData = async () => {
        try {
            const jsonReturned = await fetchAdditionalData();

            if (jsonReturned) {
                // Lista de CIDs
                let dataCids: Cid[] = jsonReturned.cids;
                let listCids: SelectProps[] = [];
                dataCids.forEach(cid => {
                    listCids.push({
                        key: cid.id,
                        value: `${cid.code} - ${cid.description}`,
                        selected: false
                    });
                });
                setCids(listCids);

                // Lista de colaboradores
                let dataEmployees: Employee[] = jsonReturned.employees;
                let listEmployees: SelectProps[] = [];
                dataEmployees.forEach(employee => {
                    listEmployees.push({
                        key: employee.id,
                        value: employee.name,
                        selected: false
                    });
                });
                setEmployees(listEmployees);
            }

        } catch (error) {
            console.log(error);
        }
    };

    const viewPhoto = async (medicalCertificate: MedicalCertificate) => {
        let caseError: ModalShowPhoto = {
            show: true,
            status: 'ERROR',
            text: t('SHADOWCAT.MEDICAL_CERTIFICATE.LIST.ERROR_SHOW_PHOTO'),
            medicalCertificate: medicalCertificate,
            descriptions: medicalCertificate.descriptionMap.descriptions
        };

        try {
            setMedicalCertificatePhoto(null);
            setModalShowPhoto({
                ...modalShowPhoto,
                show: true,
                status: 'WAITING',
                medicalCertificate: medicalCertificate,
                descriptions: medicalCertificate.descriptionMap.descriptions
            });

            const jsonReturned = await getPhoto(medicalCertificate.id!, medicalCertificate.fileContentType!);

            if (jsonReturned) {
                setMedicalCertificatePhoto(jsonReturned.file);
                setModalShowPhoto({
                    ...modalShowPhoto,
                    show: true,
                    status: 'SUCCESS',
                    medicalCertificate: medicalCertificate,
                    descriptions: medicalCertificate.descriptionMap.descriptions
                });

            } else {
                setModalShowPhoto(caseError);
            }

        } catch (error) {
            console.log(error);
            setModalShowPhoto(caseError);
        }
    };

    const deleteById = async (medicalCertificateId: string) => {
        let toast: ToastMessage = {
            show: true,
            title: t('GENERIC.TEXT.ERROR_OCURRED'),
            message: t('GENERIC.TEXT.ERROR_OCURRED'),
            danger: true
        };

        try {
            setModalConfirmation({ ...modalConfirmation, loading: true });

            const jsonReturned = await deleteMedicalCertificate(medicalCertificateId);

            if (!!jsonReturned) {
                if (String(jsonReturned.status).toLowerCase() === 'success') {
                    updateList(modalConfirmation.medicalCertificateId, null, true);

                    toast.danger = false;
                    toast.title = t('GENERIC.TEXT.OPERATION_PERFORMED');
                    toast.message = jsonReturned.message;
                    setToastAlert(toast);
                    setModalConfirmation({ ...modalConfirmation, show: false });


                } else {
                    toast.message = jsonReturned.message;
                    setToastAlert(toast);
                    setModalConfirmation({ ...modalConfirmation, loading: false });
                }

            } else {
                setToastAlert(toast);
                setModalConfirmation({ ...modalConfirmation, loading: false });
            }


        } catch (error) {
            console.log(error);
            setToastAlert(toast);
            setModalConfirmation({ ...modalConfirmation, loading: false });
        }
    };

    const updateStatus = async (medicalCertificateId: string,
        medicalCertificateStatus: string,
        description: string) => {

        let toast: ToastMessage = {
            show: true,
            title: t('GENERIC.TEXT.ERROR_OCURRED'),
            message: t('GENERIC.TEXT.ERROR_OCURRED'),
            danger: true
        };

        try {
            setModalConfirmation({ ...modalConfirmation, loading: true });

            const jsonReturned: any = await postUpdateStatus(
                medicalCertificateId,
                {
                    medicalCertificateStatus,
                    description
                });

            if (!!jsonReturned) {
                if (String(jsonReturned.status).toLowerCase() === 'success') {
                    updateList(medicalCertificateId, jsonReturned.data, false);

                    toast.danger = false;
                    toast.title = t('GENERIC.TEXT.OPERATION_PERFORMED');
                    toast.message = '';
                    setToastAlert(toast);
                    setModalConfirmation({ ...modalConfirmation, show: false });
                } else {
                    toast.message = jsonReturned.message;
                    setToastAlert(toast);
                    setModalConfirmation({ ...modalConfirmation, loading: false });
                }

            } else {
                setToastAlert(toast);
                setModalConfirmation({ ...modalConfirmation, loading: false });
            }


        } catch (error) {
            console.log(error);
            setToastAlert(toast);
            setModalConfirmation({ ...modalConfirmation, loading: false });
        }
    };

    const showModalConfirmation = (medicalCertificateId: string,
        status: string) => {

        setModalShowPhoto({ ...modalShowPhoto, show: false });

        setModalConfirmation({
            show: true,
            loading: false,
            medicalCertificateId: medicalCertificateId,
            status: status,
            question: status === 'CANCELED' ? t('SHADOWCAT.MEDICAL_CERTIFICATE.LIST.CANCEL_CONFIRMATION') :
                (status === 'APPROVED' ? t('SHADOWCAT.MEDICAL_CERTIFICATE.LIST.APPROVED_CONFIRMATION') :
                    t('SHADOWCAT.MEDICAL_CERTIFICATE.LIST.DISAPPROVED_CONFIRMATION')),
            description: ''
        });
    };

    const updateList = (medicalCertificateId: string,
        medicalCertificate: MedicalCertificate | null,
        isExclusion: boolean) => {
        let list: MedicalCertificate[] = medicalCertificates;
        let index: number = -1;

        list.forEach((md, i) => {
            if (md.id === medicalCertificateId) {
                index = i;

                if (!!medicalCertificate) {
                    medicalCertificate.employeeName = md.employeeName;
                }
            }
        });

        if (index >= 0) {
            if (isExclusion) {
                list.splice(index, 1);
            } else {
                list.splice(index, 1, medicalCertificate!);
            }
        }

        setMedicalCertificates(list);
    };

    const handleChangeFilters = (key: string, value: any) => {
        setFilters({ ...filters, [key]: value });
    };

    const handleChangeSelectEmployees = (key: string) => {
        let newList: SelectProps[] = [];
        let listKeys: string[] = [];

        employees.forEach(employee => {
            if (employee.key === key) {
                employee.selected = !employee.selected;
            }

            if (employee.selected) {
                listKeys.push(employee.key);
            }

            newList.push(employee);
        });

        setEmployees(newList);
        handleChangeFilters('employeeIds', (listKeys.length > 0 ? listKeys.join(',') : null));
    };

    const handleDeleteKeyEmployee = (event: ReturnSelectHandleChanges) => {
        if (event.name === 'deleteKey') {
            handleChangeSelectEmployees(event.value);
        }
    };

    const handleChangeSelectCids = (key: string) => {
        let newList: SelectProps[] = [];
        let listKeys: string[] = [];

        cids.forEach(cid => {
            if (cid.key === key) {
                cid.selected = !cid.selected;
            }

            if (cid.selected) {
                listKeys.push(cid.key);
            }

            newList.push(cid);
        });

        setCids(newList);
        handleChangeFilters('cidIds', (listKeys.length > 0 ? listKeys.join(',') : null));
    };

    const handleDeleteKeyCid = (event: ReturnSelectHandleChanges) => {
        if (event.name === 'deleteKey') {
            handleChangeSelectCids(event.value);
        }
    };

    const handleChangeStatus = (key: string) => {
        let newList: SelectProps[] = [];
        statusOptions.forEach(status => {
            newList.push({
                key: status.key,
                value: status.value,
                selected: status.key === key ? true : false
            });

            if (status.key === key) {
                setFilters({ ...filters, status: key, statusName: status.value });
            }
        });
        setStatusOptions(newList);
    };

    const handleChangeQuantityPerPage = (size: number) => {
        // Atualiza parâmetros
        let filtersUpdated = filters;
        filtersUpdated.quantityPerPage = size;
        setFilters(filtersUpdated);

        // Busca dados
        findByParams(filtersUpdated);
    };

    const handleChangePage = (page: number) => {
        // Atualiza parâmetros
        let filtersUpdated = filters;
        filtersUpdated.pageNumber = page;
        setFilters(filtersUpdated);

        // Busca dados
        findByParams(filtersUpdated);
    };

    const handleCloseModalShowPhoto = () => {
        setModalShowPhoto({ ...modalShowPhoto, show: false, descriptions: [], medicalCertificate: null });
        setMedicalCertificatePhoto(null);
    };

    const handleCloseModalNew = (newMedicalCertificate: MedicalCertificate | null | undefined) => {
        if (!!newMedicalCertificate) {
            medicalCertificates.unshift(newMedicalCertificate);
        }

        setShowModalNew(false);
    };

    const handleCloseModalEdit = (updatedMedicalCertificate: MedicalCertificate | null | undefined) => {
        if (!!updatedMedicalCertificate) {
            updateList(
                updatedMedicalCertificate.id!,
                updatedMedicalCertificate,
                false);
        }

        setModalEdit({
            show: false,
            medicalCertificateToEdit: null
        });
    };

    const renderLineMedicalCertificate = (medicalCertificate: MedicalCertificate) => {
        // Descrição
        let descriptions: Description[] = medicalCertificate.descriptionMap.descriptions;
        descriptions = descriptions.filter(d => d.type === 'CREATION' || d.type === 'EDIT' || d.type === 'JUSTIFICATION');


        let cidName: string = !!medicalCertificate.cid ?
            `${medicalCertificate.cid?.code} - ${medicalCertificate.cid?.description}` : "-";

        let period: string[] = buildPeriods(medicalCertificate.startDate,
            medicalCertificate.endDate,
            medicalCertificate.haveSchedule);

        let showUpdateStatusButtons = (medicalCertificate.medicalCertificateStatus === 'WITHOUT_STATUS' &&
            permissions.includes(116));

        let showUpdateButton = (medicalCertificate.medicalCertificateStatus === 'WITHOUT_STATUS' || medicalCertificate.medicalCertificateStatus === 'DISAPPROVED');

        let showCancelButton = !isOnlyEmployee && (medicalCertificate.medicalCertificateStatus === 'WITHOUT_STATUS' || medicalCertificate.medicalCertificateStatus === 'DISAPPROVED');

        return (
            <Table.BodyItem key={`medical-certificate-${medicalCertificate.id}`}>
                {!isOnlyEmployee &&
                    <Table.BodyItem.Item className={styles['medical-certificate-td']}>
                        {medicalCertificate.employeeName}
                    </Table.BodyItem.Item>
                }
                <Table.BodyItem.Item className={styles['medical-certificate-td']}>
                    <p><strong>{`${t('GENERIC.TEXT.START')}: `}</strong>{period[0]}</p>
                    <p><strong>{`${t('GENERIC.TEXT.END')}: `}</strong>{period[1]}</p>
                </Table.BodyItem.Item>
                <Table.BodyItem.Item className={styles['medical-certificate-td']} align={cidName === '-' ? 'center' : 'left'}>
                    {cidName}
                </Table.BodyItem.Item>
                <Table.BodyItem.Item className={styles['medical-certificate-td']}>
                    {!!medicalCertificate.descriptionMap ? descriptions[descriptions.length - 1].description : medicalCertificate.description}
                </Table.BodyItem.Item>
                <Table.BodyItem.Item className={styles['medical-certificate-td']}>
                    <p><strong>{`${t('SHADOWCAT.MEDICAL_CERTIFICATE.GENERIC.PROFESSIONAL_NAME')}: `}</strong>{medicalCertificate.professionalName}</p>
                    <p><strong>{`${t('SHADOWCAT.MEDICAL_CERTIFICATE.GENERIC.PROFESSIONAL_REGISTRATION')}: `}</strong>{medicalCertificate.countyNumber}</p>
                </Table.BodyItem.Item>
                <Table.BodyItem.Item align="center">{translateStatus(medicalCertificate)}</Table.BodyItem.Item>
                <Table.BodyItem.Item align="center" style={{ paddingRight: "10px" }}>
                    <Container>
                        <Button
                            primary
                            md
                            title={t('SHADOWCAT.MEDICAL_CERTIFICATE.LIST.SHOW_CERTIFICATE')}
                            icon="file-image"
                            action={() => viewPhoto(medicalCertificate)} />

                        {(showUpdateStatusButtons || showUpdateButton || showCancelButton) &&
                            <ButtonDropdown title={t('GENERIC.TEXT.OPTIONS')} icon="cogs" md outlined className={styles['medical-certificate-options']}>
                                <ButtonDropdown.Session>
                                    {showUpdateStatusButtons &&
                                        <>
                                            <ButtonDropdown.Session.Item
                                                title={t('GENERIC.TEXT.APPROVE')}
                                                icon="thumbs-up"
                                                action={() => showModalConfirmation(medicalCertificate.id!, 'APPROVED')} />

                                            <ButtonDropdown.Session.Item
                                                title={t('GENERIC.TEXT.DISAPPROVE')}
                                                icon="thumbs-down"
                                                action={() => showModalConfirmation(medicalCertificate.id!, 'DISAPPROVED')} />
                                        </>
                                    }

                                    {showUpdateButton &&
                                        <ButtonDropdown.Session.Item
                                            title={t('GENERIC.BUTTON.UPDATE.TEXT')}
                                            icon="edit"
                                            action={() => setModalEdit({
                                                show: true,
                                                medicalCertificateToEdit: medicalCertificate
                                            })} />
                                    }

                                    {showCancelButton &&
                                        <ButtonDropdown.Session.Item
                                            title={t('GENERIC.BUTTON.CANCEL.TEXT')}
                                            icon="times"
                                            action={() => showModalConfirmation(medicalCertificate.id!, 'CANCELED')} />
                                    }
                                </ButtonDropdown.Session>
                            </ButtonDropdown>
                        }
                    </Container>
                </Table.BodyItem.Item>
            </Table.BodyItem>
        )
    };

    const renderTableMedicalCertificates = (listMedicalCertificate: MedicalCertificate[]) => {
        return (
            <Table primary striped responsible>
                <Table.Header>
                    <Table.HeaderItem>
                        {!isOnlyEmployee &&
                            <Table.HeaderItem.Item>{t('GENERIC.TEXT.EMPLOYEE')}</Table.HeaderItem.Item>
                        }
                        <Table.HeaderItem.Item>{t('GENERIC.TEXT.PERIOD')}</Table.HeaderItem.Item>
                        <Table.HeaderItem.Item>{t('SHADOWCAT.MEDICAL_CERTIFICATE.GENERIC.CID')}</Table.HeaderItem.Item>
                        <Table.HeaderItem.Item>{t('GENERIC.TEXT.DESCRIPTION')}</Table.HeaderItem.Item>
                        <Table.HeaderItem.Item>
                            {t('SHADOWCAT.MEDICAL_CERTIFICATE.GENERIC.PROFESSIONAL')}
                        </Table.HeaderItem.Item>
                        <Table.HeaderItem.Item>{t('GENERIC.TEXT.STATUS')}</Table.HeaderItem.Item>
                        <Table.HeaderItem.Item>{t('GENERIC.TEXT.OPTIONS')}</Table.HeaderItem.Item>
                    </Table.HeaderItem>
                </Table.Header>
                <Table.Body>
                    {listMedicalCertificate.map(medicalCertificate => {
                        return renderLineMedicalCertificate(medicalCertificate);
                    })}
                </Table.Body>
            </Table>
        );
    };

    const renderMedicalCertificateInformation = (medicalCertificate: MedicalCertificate) => {
        let cidName: string = !!medicalCertificate.cid ?
            `${medicalCertificate.cid?.code} - ${medicalCertificate.cid?.description}` : "-";
        let period: string[] = buildPeriods(medicalCertificate.startDate,
            medicalCertificate.endDate,
            medicalCertificate.haveSchedule);

        return (
            <Panel title={t('GENERIC.TEXT.ADDITIONAL_DATA')} actions={(medicalCertificate.medicalCertificateStatus === 'WITHOUT_STATUS' && !isOnlyEmployee) ? [
                {
                    title: t('GENERIC.TEXT.DISAPPROVE'),
                    icon: "thumbs-down",
                    danger: true,
                    action: () => { showModalConfirmation(medicalCertificate.id!, 'DISAPPROVED') }
                },
                {
                    title: t('GENERIC.TEXT.APPROVE'),
                    icon: "thumbs-up",
                    success: true,
                    action: () => { showModalConfirmation(medicalCertificate.id!, 'APPROVED') }
                }
            ] : []}>
                <Row>
                    <Row.Item sm={12} md={4} lg={3}>
                        <p><strong>{`${t('GENERIC.TEXT.NAME')}: `}</strong>{medicalCertificate.employeeName}</p>
                        <p><strong>{`${t('GENERIC.TEXT.START_DATE')}: `}</strong>{period[0]}</p>
                        <p><strong>{`${t('GENERIC.TEXT.END_DATE')}: `}</strong>{period[1]}</p>
                    </Row.Item>
                    <Row.Item sm={12} md={4} lg={3}>
                        <p><strong>{`${t('SHADOWCAT.MEDICAL_CERTIFICATE.GENERIC.PROFESSIONAL_NAME')}: `}</strong>{medicalCertificate.professionalName}</p>
                        <p><strong>{`${t('SHADOWCAT.MEDICAL_CERTIFICATE.GENERIC.PROFESSIONAL_REGISTRATION')}: `}</strong>{medicalCertificate.countyNumber}</p>
                    </Row.Item>
                    <Row.Item sm={12} md={4} lg={3}>
                        <p><strong>{`${t('SHADOWCAT.MEDICAL_CERTIFICATE.GENERIC.CID')}: `}</strong>{cidName}</p>
                        <p><strong>{`${t('GENERIC.TEXT.STATUS')}: `}</strong>{translateStatus(medicalCertificate)}</p>
                    </Row.Item>
                </Row>
            </Panel>
        );
    };

    return (
        <Container className={styles['medical-certificate-list-rt']}>
            <PageTitle.Small title={t('GENERIC.TEXT.MEDICAL_CERTIFICATES')} />

            <Panel title={t('GENERIC.TEXT.FILTERS')}
                actions={[{
                    title: t('GENERIC.BUTTON.FILTER.TEXT'),
                    icon: "search",
                    primary: true,
                    disabled: medicalCertificatesStatus === 'WAITING' ? true : false,
                    loading: medicalCertificatesStatus === 'WAITING' ? true : false,
                    action: () => { findByParams(filters) }
                }]}>
                <Row>
                    <Row.Item sm={12} md={3} lg={2}>
                        <Container className={styles['medical-certificate-date-input']}>
                            <DateTimePicker
                                title={t('GENERIC.TEXT.START_DATE')}
                                name="startDate"
                                type="date"
                                mask
                                fullWidth
                                value={filters.startDate}
                                handleChange={(event) => handleChangeFilters('startDate', event)} />
                        </Container>
                    </Row.Item>

                    <Row.Item sm={12} md={3} lg={2}>
                        <Container className={styles['medical-certificate-date-input']}>
                            <DateTimePicker
                                title={t('GENERIC.TEXT.END_DATE')}
                                name="endDate"
                                type="date"
                                mask
                                fullWidth
                                value={filters.endDate}
                                handleChange={(event) => handleChangeFilters('endDate', event)} />
                        </Container>
                    </Row.Item>

                    {!isOnlyEmployee &&
                        <Row.Item sm={12} md={3} lg={2}>
                            <Select
                                medium
                                multiple
                                fullWidth
                                title={t('GENERIC.TEXT.EMPLOYEES')}
                                name="selectEmployees"
                                value={t('GENERIC.TEXT.EMPLOYEES')}
                                selectedItemNameLength={13}
                                selectItemsToShowLength={1}
                                options={employees}
                                disabled={employees.length === 0 ? true : false}
                                handleChange={handleChangeSelectEmployees}
                                handleChanges={handleDeleteKeyEmployee} />
                        </Row.Item>
                    }

                    <Row.Item sm={12} md={3} lg={2}>
                        <Select
                            medium
                            multiple
                            fullWidth
                            title={t('SHADOWCAT.MEDICAL_CERTIFICATE.GENERIC.CID')}
                            name="selectCids"
                            value={t('SHADOWCAT.MEDICAL_CERTIFICATE.GENERIC.CID')}
                            selectedItemNameLength={13}
                            selectItemsToShowLength={1}
                            options={cids}
                            disabled={cids.length === 0 ? true : false}
                            handleChange={handleChangeSelectCids}
                            handleChanges={handleDeleteKeyCid} />
                    </Row.Item>

                    <Row.Item sm={12} md={3} lg={2}>
                        <Select
                            medium
                            fullWidth
                            title={t('GENERIC.TEXT.STATUS')}
                            name="statusFilter"
                            value={filters.statusName}
                            options={statusOptions}
                            handleChange={handleChangeStatus} />
                    </Row.Item>
                </Row>
            </Panel>

            <Panel noPadding title={t('GENERIC.TEXT.MEDICAL_CERTIFICATES')}
                actions={permissions.includes(115) ? [{
                    title: t('GENERIC.TEXT.REGISTER'),
                    icon: "plus",
                    primary: true,
                    disabled: medicalCertificatesStatus === 'WAITING' ? true : false,
                    loading: medicalCertificatesStatus === 'WAITING' ? true : false,
                    action: () => { setShowModalNew(true) }
                }] : []}>
                <>
                    {medicalCertificatesStatus === 'WAITING' &&
                        <Loading />
                    }

                    {medicalCertificatesStatus === 'ERROR' &&
                        <TextInformation
                            icon='times'
                            text={t('SHADOWCAT.MEDICAL_CERTIFICATE.LIST.ERROR_SEARCHING')}
                            subText={t('GENERIC.TEXT.MODIFY_FILTERS')} />
                    }

                    {medicalCertificatesStatus === 'EMPTY_LIST' &&
                        <TextInformation
                            icon='search'
                            text={t('SHADOWCAT.MEDICAL_CERTIFICATE.LIST.EMPTY_LIST')}
                            subText={t('GENERIC.TEXT.MODIFY_FILTERS')} />
                    }

                    {medicalCertificatesStatus === 'SUCCESS' &&
                        <Row>
                            <Row.Item lg={12} md={12} sm={12}>
                                <Container className={styles['medical-certificate-pagination']}>
                                    <PerPage
                                        selected={filters.quantityPerPage}
                                        handlePerPageChanged={(value) => handleChangeQuantityPerPage(value)} />

                                    <Pagination.Compact
                                        currentPage={filters.pageNumber}
                                        pageLimit={filters.quantityPerPage}
                                        totalRecords={filters.totalElements}
                                        handlePageChanged={handleChangePage} />
                                </Container>
                            </Row.Item>
                            <Row.Item lg={12} md={12} sm={12}>
                                {renderTableMedicalCertificates(medicalCertificates)}
                            </Row.Item>
                        </Row>
                    }
                </>
            </Panel>

            {modalShowPhoto.show &&
                <Modal title={t('GENERIC.TEXT.MEDICAL_CERTIFICATE')} bg showClose
                    handleClose={() => handleCloseModalShowPhoto()}>

                    <Row>
                        <Row.Item sm={12} md={12} lg={12}>
                            {renderMedicalCertificateInformation(modalShowPhoto.medicalCertificate!)}
                        </Row.Item>

                        <Row.Item sm={12} md={4} lg={3}>
                            <Panel title={t('GENERIC.TEXT.RECENT_ACTIVITIES')}>
                                <Timeline>
                                    {modalShowPhoto.descriptions.map((description: Description, index: number) => (
                                        <Timeline.Item
                                            key={`timeline-${index}`}
                                            date={formatLocalDate(description.created, false, 'YYYY-MM-DDTHH:mm:SS[.sss]', 'DD/MM/YYYY HH:mm')}
                                            description={description.description}
                                            userName={description.userName}
                                            type="edit" />
                                    ))}
                                </Timeline>
                            </Panel>
                        </Row.Item>

                        <Row.Item sm={12} md={8} lg={9}>
                            <Panel title={t('GENERIC.TEXT.MEDICAL_CERTIFICATE')} >
                                <>
                                    {modalShowPhoto.status === 'WAITING' &&
                                        <Container className={styles['modal-show-photo']}>
                                            <Loading />
                                        </Container>
                                    }

                                    {modalShowPhoto.status === 'ERROR' &&
                                        <Container className={styles['modal-show-photo']}>
                                            <TextInformation
                                                icon='times'
                                                text={modalShowPhoto.text} />
                                        </Container>
                                    }

                                    {modalShowPhoto.status === 'SUCCESS' && medicalCertificatePhoto != null &&
                                        <Container className={styles['modal-show-photo']}>
                                            {modalShowPhoto.medicalCertificate?.fileContentType === 'application/pdf' &&
                                                <embed src={`data:application/pdf;base64,${medicalCertificatePhoto}`} 
                                                    type="application/pdf" 
                                                    width="100%" 
                                                    height="500px" />
                                                ||
                                                <img alt='' src={`data:image/jpeg;base64,${medicalCertificatePhoto}`} />
                                            }
                                        </Container>
                                    }
                                </>
                            </Panel>
                        </Row.Item>
                    </Row>
                </Modal>
            }

            {modalConfirmation.show &&
                <Modal
                    title={t('GENERIC.TEXT.CONFIRMATION')}
                    sm
                    showClose
                    handleClose={() => setModalConfirmation({ ...modalConfirmation, show: false })}>

                    <p>{modalConfirmation.question}</p>

                    {modalConfirmation.status === 'CANCELED' &&
                        <TextArea
                            name="description"
                            title={t('GENERIC.TEXT.DESCRIPTION')}
                            placeholder={t('GENERIC.TEXT.DESCRIPTION')}
                            fullWidth
                            rows={5}
                            value={modalConfirmation.description}
                            handleChange={(e) => setModalConfirmation({
                                ...modalConfirmation,
                                description: e.target.value
                            })} />
                    }

                    <Container className={styles['button-actions']}>
                        <Button
                            danger
                            md
                            title={t('GENERIC.TEXT.NO')}
                            icon="times"
                            loading={modalConfirmation.loading}
                            action={() => setModalConfirmation({ ...modalConfirmation, show: false })} />

                        <Button
                            primary
                            md
                            title={t('GENERIC.TEXT.YES')}
                            icon="save"
                            className={styles['last-button']}
                            loading={modalConfirmation.loading}
                            action={() => updateStatus(
                                modalConfirmation.medicalCertificateId,
                                modalConfirmation.status,
                                modalConfirmation.description)} />
                    </Container>
                </Modal>
            }

            {showModalNew &&
                <Modal title={`${t('GENERIC.TEXT.NEW')} ${t('GENERIC.TEXT.MEDICAL_CERTIFICATE')}`} lg showClose
                    handleClose={() => handleCloseModalNew(null)}>

                    <New
                        saveAbsencesWithTime={saveAbsencesWithTime}
                        isOnlyEmployee={isOnlyEmployee}
                        employeeId={employeeId}
                        listEmployees={employees}
                        listCids={cids}
                        handleClose={handleCloseModalNew} />
                </Modal>
            }

            {modalEdit.show && !!modalEdit.medicalCertificateToEdit &&
                <Modal title={`${t('GENERIC.TEXT.EDIT')} ${t('GENERIC.TEXT.MEDICAL_CERTIFICATE')}`} lg showClose
                    handleClose={() => handleCloseModalEdit(null)}>

                    <Edit
                        medicalCertificateToEdit={modalEdit.medicalCertificateToEdit}
                        listCids={cids}
                        handleClose={handleCloseModalEdit} />
                </Modal>
            }

            {!!toastAlert && toastAlert.show &&
                <Toast
                    danger={toastAlert.danger}
                    success={!toastAlert.danger}
                    icon={toastAlert.danger ? "times" : "check"}
                    title={toastAlert.title}
                    positionTopRight
                    message={toastAlert.message}
                    closeChange={() => setToastAlert({ ...toastAlert, show: false })}
                    showTime={5000} />
            }

        </Container>
    );
};

export default List;