import { library } from '@fortawesome/fontawesome-svg-core';

import {
    faCheck,
    faEdit,
    faExclamationCircle,
    faExclamationTriangle,
    faFileCsv,
    faHospital,
    faPlus,
    faSave,
    faSearch,
    faTachometerAlt,
    faTimes,
    faTrash,
	faCog,
    faFileImage,
    faThumbsUp,
    faThumbsDown,
    faCogs
} from '@fortawesome/free-solid-svg-icons';

import {
    faIslandTropical as fasIslandTropical,
	faEye as fasEye,
    faEyeSlash as fasEyeSlash
} from '@fortawesome/pro-solid-svg-icons';

library.add(
    faCheck as any, faEdit as any, faExclamationCircle as any, faExclamationTriangle as any,
	faHospital as any, faPlus as any, faSave as any, faSearch as any, faTachometerAlt as any, 
	faTimes as any, faTrash as any, faCog as any, faFileCsv as any, faFileImage as any,
    faThumbsUp as any, faThumbsDown as any, faCogs as any,


    fasIslandTropical as any,fasEye as any, fasEyeSlash as any
);