import { translation as t } from "@optimuminterfaces/revex-react-components/dist/translation";

import { MedicalCertificate } from "../models/MedicalCertificate";

import { isBeforeDate } from "@optimuminterfaces/revex-react-components/dist/utils/DateTimeUtil";

export const translateStatus = (medicalCertificate: MedicalCertificate) => {
    if (medicalCertificate.medicalCertificateStatus === 'APPROVED') {
        return t('GENERIC.TEXT.APPROVED');

    } else if (medicalCertificate.medicalCertificateStatus === 'DISAPPROVED') {
        return t('GENERIC.TEXT.DISAPPROVED');

    } else if (medicalCertificate.medicalCertificateStatus === 'CANCELED') {
        return t('GENERIC.TEXT.CANCELED');

    } else if (medicalCertificate.medicalCertificateStatus === 'CERTIFICATE_OF_JUSTIFICATION') {
        return t('SHADOWCAT.MEDICAL_CERTIFICATE.GENERIC.CERTIFICATE_OF_JUSTIFICATION');

    } else {
        return "-";
    }
};

export const validateMedicalCertificateData = (medicalCertificateData: MedicalCertificate, isUpdate: boolean) => {
    const minimumDescriptionSize = 10;

    if (!isUpdate && medicalCertificateData.file === null) {
        return t('SHADOWCAT.MEDICAL_CERTIFICATE.NEW.NO_IMAGE_SELECTED');

    } else if (!!medicalCertificateData) {
        if (medicalCertificateData.startDate === null || medicalCertificateData.startDate.trim().length === 0) {
            return t('ABSENCE.DURATION_OPTIONS.INFORM_START_DATE');

        } else if (medicalCertificateData.endDate === null || medicalCertificateData.endDate.trim().length === 0) {
            return medicalCertificateData.haveSchedule ? t('ABSENCE.DURATION_OPTIONS.INFORM_END_DATE_AND_TIME') :
                t('ABSENCE.DURATION_OPTIONS.INFORM_END_DATE');

        } else if (isBeforeDate(medicalCertificateData.endDate, medicalCertificateData.startDate, medicalCertificateData.haveSchedule ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD')) {
            return medicalCertificateData.haveSchedule ?
                t('SHADOWCAT.EMPLOYEE_SITUATION.END_DATE_TIME_BEFORE_START_DATE_TIME') :
                t('SHADOWCAT.EMPLOYEE_SITUATION.END_DATE_BEFORE_START_DATE');

        } else if (medicalCertificateData.professionalName === null ||
            medicalCertificateData.professionalName.trim().length === 0) {

            return t('SHADOWCAT.MEDICAL_CERTIFICATE.NEW.PROFESSIONAL_NAME_NOT_INFORMED');

        } else if (medicalCertificateData.countyNumber === null ||
            medicalCertificateData.countyNumber.trim().length === 0) {

            return t('SHADOWCAT.MEDICAL_CERTIFICATE.NEW.PROFESSIONAL_REGISTRATION_NOT_INFORMED');

        } else if (medicalCertificateData.description === null ||
            medicalCertificateData.description.trim().length < minimumDescriptionSize) {

            return t('SHADOWCAT.MEDICAL_CERTIFICATE.NEW.SHORT_DESCRIPTION', undefined, minimumDescriptionSize);
        }

        return null;
    }

    return t('COMPONENTS.TOAST.TITLE.INVALID_DATA');
};