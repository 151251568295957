import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { Button, DateTimePicker, Loading, PageTitle, Panel, Row, Select, TextInformation } from '@optimuminterfaces/revex-react-components/dist/components';
import { copyObjectJSON } from '@optimuminterfaces/revex-react-components/dist/utils/DeveloperUtil';
import { formatLocalDate } from '@optimuminterfaces/revex-react-components/dist/utils/DateTimeUtil';
import { downloadCsv } from '@optimuminterfaces/revex-react-components/dist/utils/FileUtil';
import { abbreviation } from '@optimuminterfaces/revex-react-components/dist/utils/StringUtils';

import ListPagination from './ListPagination/ListPagination';
import { EmployeeSituationAdditionalData } from '../../../../models/EmployeeSituationAdditionalData';
import { fetchEmployeeSituations, fetchAdditionalData, generateReportEmployeeSituations } from '../../../../services/employeeSituations.services';
import { getEnvironment } from '../../../../services/auth.services'; 

import styles from './ListAllByInstance.module.scss';

import {translation as t} from '@optimuminterfaces/revex-react-components/dist/translation/index'

interface ListAllByInstanceProps {
    Container?: any;
};

interface OptionForSelect {
    key: string;
    value: string;
    selected: boolean;
};

interface ReturnSelectHandleChanges {
    name: string;
    value: string;
};

const ListAllByInstance = ({ Container = 'div' }: ListAllByInstanceProps) => {

    const [employeeSituations, setEmployeeSituations] = useState<EmployeeSituationAdditionalData[]>([]);
    const [filterEmployeeSituations, setFilterEmployeeSituations] = useState<EmployeeSituationAdditionalData[]>([]);
    const [employeeSituationsStatus, setEmployeeSituationsStatus] = useState<string>('waiting');
    const [loadingButtonCSV, setLoadingButtonCSV] = useState<boolean>(false);
    const [startDate, setStartDate] = useState<string | null>(null);
    const [endDate, setEndDate] = useState<string | null>(null);
    const [situationTypes, setSituationTypes] = useState<OptionForSelect[]>([]);
    const [employees, setEmployees] = useState<OptionForSelect[]>([]);
    const [occupations, setOccupations] = useState<OptionForSelect[]>([]);
    const [scaleNames, setScaleNames] = useState<OptionForSelect[]>([]);
    const [workShifts, setWorkShifts] = useState<OptionForSelect[]>([
        {
            key: 'morning',
            value: t('GENERIC.SHIFTS.MORNING'),
            selected: false
        },
        {
            key: 'evening',
            value: t('GENERIC.SHIFTS.EVENING'),
            selected: false
        },
        {
            key: 'night',
            value: t('GENERIC.SHIFTS.NIGHT'),
            selected: false
        },
        {
            key: 'dawn',
            value: t('GENERIC.SHIFTS.DAWN'),
            selected: false
        }
    ]);

    // Lista de ações permitidas
    const permissions = getEnvironment().actions;

    useEffect(() => {
        let dateString = moment().format('DD/MM/YYYY');

        setStartDate(dateString);
        setEndDate(dateString);

        // Busca ausências
        getEmployeeSituations(dateString, dateString);

        // Busca dados adicionais
        getAdditionalData();

    }, []);

    // Busca ausências pela data
    const getEmployeeSituations = (startDate: string | null, endDate: string | null) => {
        setEmployeeSituationsStatus('waiting');

        (async () => {
            try {
                const jsonReturned = await fetchEmployeeSituations({ startDate, endDate });

                if (jsonReturned) {
                    setEmployeeSituations(copyObjectJSON(jsonReturned.employeeSituations));
                    filterList(copyObjectJSON(jsonReturned.employeeSituations), null, null, null, null, null);
                } else {
                    defaultActionsWhenAnErrorOccurs();
                }

            } catch (error) {
                defaultActionsWhenAnErrorOccurs();
                console.log(error);
            }
        })();
    };

    // Busca os dados adicionais e constrói os selects
    const getAdditionalData = () => {
        (async () => {
            try {
                const jsonReturned = await fetchAdditionalData();

                if (jsonReturned) {
                    // Lista de colaboradores
                    let listEmployees: OptionForSelect[] = [];
                    let employees: any[] = jsonReturned.employees;
                    employees.forEach(employee => {
                        let employeeName = abbreviation(employee.name, 32);
                        let transferDate = employee.transferDate ? ' (' + t('GENERIC.TEXT.TRANSFER') + ': ' + formatLocalDate(employee.transferDate, true, 'YYYY-MM-DD', 'DD/MM/YY') + ')' : '';
                        let demissionDate = employee.demissionDate ? ' (' + t('GENERIC.TEXT.DEMISSION') + ': ' + formatLocalDate(employee.demissionDate, true, 'YYYY-MM-DD', 'DD/MM/YY') + ')' : '';

                        listEmployees.push({
                            key: employee.id,
                            value: `${employeeName}${transferDate}${demissionDate}`,
                            selected: false
                        });
                    });
                    setEmployees(copyObjectJSON(listEmployees));

                    // Lista de funções
                    let listOccupations: OptionForSelect[] = [];
                    let occupations: any[] = jsonReturned.occupations;
                    occupations.forEach(occupation => {
                        listOccupations.push({
                            key: occupation.id,
                            value: occupation.name,
                            selected: false
                        });
                    });
                    setOccupations(copyObjectJSON(listOccupations));

                    // Lista de tipos de ausências
                    let listSituationTypes: OptionForSelect[] = [];
                    let situationTypes: any[] = jsonReturned.situationTypes;
                    situationTypes.forEach(situationType => {
                        listSituationTypes.push({
                            key: situationType.id,
                            value: situationType.name,
                            selected: false
                        });
                    });
                    setSituationTypes(copyObjectJSON(listSituationTypes));

                    // Lista de escalas
                    let listScaleNames: OptionForSelect[] = [];
                    let scaleNames: any[] = jsonReturned.scaleNames;
                    scaleNames.forEach(scaleName => {
                        listScaleNames.push({
                            key: scaleName.id,
                            value: scaleName.name,
                            selected: false
                        });
                    });
                    setScaleNames(copyObjectJSON(listScaleNames));
                }

            } catch (error) {
                console.log(error);
            }
        })();
    };

    // Gerar relatório CSV
    const downloadCSVEmployeeSituations = async () => {
        setLoadingButtonCSV(true);

        try {
            let fileName = t('SHADOWCAT.EMPLOYEE_SITUATION.ABSENCES_FROM_TO',undefined,startDate,endDate);

            // Listas auxiliares
            let filterSituationTypes = situationTypes
                .filter(situationType => situationType.selected === true)
                .map(situationType => situationType.key);
            let filterEmployees = employees
                .filter(employee => employee.selected === true)
                .map(employee => employee.key);
            let filterOccupations = occupations
                .filter(occupation => occupation.selected === true)
                .map(occupation => occupation.key);
            let filterScaleNames = scaleNames
                .filter(scaleName => scaleName.selected === true)
                .map(scaleName => scaleName.key);
            let filterWorkShifts = workShifts
                .filter(workShift => workShift.selected === true)
                .map(workShift => workShift.key);
            const jsonReturned = await generateReportEmployeeSituations({
                startDate: startDate,
                endDate: endDate,
                situationTypeIds: filterSituationTypes.length > 0 ? filterEmployeeSituations.map(f => f.situationTypeId).toString() : null,
                employeeIds: filterEmployees.length > 0 ? filterEmployees.toString() : null,
                occupationIds: filterOccupations.length > 0 ? filterOccupations.toString() : null,
                scaleNameIds: filterScaleNames.length > 0 ? filterScaleNames.toString() : null,
                workShifts: filterWorkShifts.length > 0 ? filterWorkShifts.toString() : null
            });

            if (jsonReturned) {
                downloadCsv(jsonReturned, fileName);
                setLoadingButtonCSV(false);
            }
        } catch (error) {
            console.log(error);
        }
    };

    // Filtra a lista de acordo com os filtros
    const filterList = (absences: EmployeeSituationAdditionalData[] | null | undefined,
        notEmployeeKey: string | null | undefined, notOccupationKey: string | null | undefined,
        notSituationTypeId: string | null | undefined, notScaleNameKey: string | null | undefined,
        notWorkShiftKey: string | null | undefined) => {

        setEmployeeSituationsStatus('waiting');

        let listEmployeeSituations: EmployeeSituationAdditionalData[] = !!absences ? absences :
            copyObjectJSON(employeeSituations);

        if (listEmployeeSituations.length === 0) {
            setFilterEmployeeSituations([]);
            setEmployeeSituationsStatus('emptyList');
        } else {
            // Listas auxiliares
            let filterEmployees: string[] = employees
                .filter(employee => employee.selected === true && employee.key !== notEmployeeKey)
                .map(employee => employee.key);
            let filterOccupations: string[] = occupations
                .filter(occupation => occupation.selected === true && occupation.key !== notOccupationKey)
                .map(occupation => occupation.key);
            let filterSituationTypes: string[] = situationTypes
                .filter(situationType => situationType.selected === true && situationType.key !== notSituationTypeId)
                .map(situationType => situationType.key);
            let filterScaleNames: string[] = scaleNames
                .filter(scaleName => scaleName.selected === true && scaleName.key !== notScaleNameKey)
                .map(scaleName => scaleName.key);
            let filterWorkShifts: string[] = workShifts
                .filter(workShift => workShift.selected === true && workShift.key !== notWorkShiftKey)
                .map(workShift => workShift.value);

            if (filterEmployees.length === 0 && filterOccupations.length === 0 &&
                filterSituationTypes.length === 0 && filterScaleNames.length === 0 &&
                filterWorkShifts.length === 0) {

                setFilterEmployeeSituations(copyObjectJSON(listEmployeeSituations));
                setEmployeeSituationsStatus('success');

            } else {
                // Nova lista
                let listResult: EmployeeSituationAdditionalData[] = [];

                listEmployeeSituations.forEach(employeeSituation => {
                    let addEmployeeSituation: boolean = true;

                    if (filterEmployees.length > 0 && !filterEmployees.includes(employeeSituation.employeeId)) {
                        addEmployeeSituation = false;
                    }

                    if (addEmployeeSituation && filterOccupations.length > 0 && !!employeeSituation.occupationId &&
                        !filterOccupations.includes(employeeSituation.occupationId)) {
                        addEmployeeSituation = false;
                    }

                    if (addEmployeeSituation && filterSituationTypes.length > 0 &&
                        !filterSituationTypes.includes(employeeSituation.situationTypeId)) {
                        addEmployeeSituation = false;
                    }

                    if (addEmployeeSituation && filterScaleNames.length > 0 && !!employeeSituation.scaleNameId &&
                        !filterScaleNames.includes(employeeSituation.scaleNameId)) {
                        addEmployeeSituation = false;
                    }

                    if (addEmployeeSituation && filterWorkShifts.length > 0 && !!employeeSituation.workShift &&
                        !filterWorkShifts.includes(employeeSituation.workShift)) {
                        addEmployeeSituation = false;
                    }

                    if (addEmployeeSituation) {
                        listResult.push(employeeSituation);
                    }
                });

                setFilterEmployeeSituations(copyObjectJSON(listResult));
                setEmployeeSituationsStatus(listResult.length > 0 ? 'success' : 'emptyList');
            }
        }
    };

    // Ações padrões a serem executadas caso ocorra erro na busca de ausências
    const defaultActionsWhenAnErrorOccurs = () => {
        setEmployeeSituations([]);
        setFilterEmployeeSituations([]);
        setEmployeeSituationsStatus('error');
    };

    // Handle change dateTimePicker
    const handleChangeDateTimePicker = (start: string | null, end: string | null) => {
        if (!!start && start !== startDate) {
            setStartDate(start);
        } else {
            start = startDate;
        }

        if (!!end && end !== endDate) {
            setEndDate(end);
        } else {
            end = endDate;
        }

        getEmployeeSituations(start, end);
    };

    // Handle blur dos selects
    const handleBlur = () => {
        filterList(null, null, null, null, null, null);
    };

    // Handle change select employees
    const handleChangeEmployees = (key: string) => {
        setEmployees(employees.map(employee => {
            return {
                key: employee.key,
                value: employee.value,
                selected: (employee.key === key ? !employee.selected : employee.selected)
            }
        }));
    };

    // Handle change remoção item select employees
    const handleDeleteKeyEmployee = (event: ReturnSelectHandleChanges) => {
        if (event.name === 'deleteKey') {
            filterList(null, event.value, null, null, null, null);
        }
    };

    // Handle change select occupations
    const handleChangeOccupations = (key: string) => {
        setOccupations(occupations.map(occupation => {
            return {
                key: occupation.key,
                value: occupation.value,
                selected: (occupation.key === key ? !occupation.selected : occupation.selected)
            }
        }));
    };

    // Handle change remoção item select occupations
    const handleDeleteKeyOccupation = (event: ReturnSelectHandleChanges) => {
        if (event.name === 'deleteKey') {
            filterList(null, null, event.value, null, null, null);
        }
    };

    // Handle change select workShifts
    const handleChangeWorkShifts = (key: string) => {
        setWorkShifts(workShifts.map(workShift => {
            return {
                key: workShift.key,
                value: workShift.value,
                selected: (workShift.key === key ? !workShift.selected : workShift.selected)
            }
        }));
    };

    // Handle change remoção item select workShifts
    const handleDeleteKeyWorkShift = (event: ReturnSelectHandleChanges) => {
        if (event.name === 'deleteKey') {
            filterList(null, null, null, null, null, event.value);
        }
    };

    // Handle change select situationTypes
    const handleChangeSituationTypes = (key: string) => {
        setSituationTypes(situationTypes.map(situationType => {
            return {
                key: situationType.key,
                value: situationType.value,
                selected: (situationType.key === key ? !situationType.selected : situationType.selected)
            }
        }));
    };

    // Handle change remoção item select situationTypes
    const handleDeleteKeySituationTypes = (event: ReturnSelectHandleChanges) => {
        if (event.name === 'deleteKey') {
            filterList(null, null, null, event.value, null, null);
        }
    };

    // Handle change select scaleNames
    const handleChangeScaleNames = (key: string) => {
        setScaleNames(scaleNames.map(scaleName => {
            return {
                key: scaleName.key,
                value: scaleName.value,
                selected: (scaleName.key === key ? !scaleName.selected : scaleName.selected)
            }
        }));
    };

    // Handle change remoção item select scaleNames
    const handleDeleteKeyScaleNames = (event: ReturnSelectHandleChanges) => {
        if (event.name === 'deleteKey') {
            filterList(null, null, null, null, event.value, null);
        }
    };

    return (
        <Container className={styles['employee-situation-list-instance-rt']}>
            <PageTitle.Small title={t('DASHBOARD.TEXT.EMPLOYEE_SITUATIONS')} />

            <Panel title={t('GENERIC.TEXT.FILTERS')} noTitleBorder>
                <Row>
                    <Row.Item sm={6} md={3} lg={3}>
                        <Row>
                            <Row.Item sm={12} md={12} lg={12}>
                                <DateTimePicker
                                    title={t('GENERIC.TEXT.START_DATE')}
                                    name="startDate"
                                    type="date"
                                    mask
                                    fullWidth
                                    handleChange={(event) => handleChangeDateTimePicker(event, null)}
                                    value={startDate} />
                            </Row.Item>
                            <Row.Item sm={12} md={12} lg={12}>
                                <DateTimePicker
                                    title={t('GENERIC.TEXT.END_DATE')}
                                    name="endDate"
                                    type="date"
                                    mask
                                    fullWidth
                                    handleChange={(event) => handleChangeDateTimePicker(null, event)}
                                    value={endDate} />
                            </Row.Item>
                        </Row>
                    </Row.Item>
                    <Row.Item sm={6} md={3} lg={3}>
                        <Select
                            medium
                            multiple
                            fullWidth
                            title={t('GENERIC.TEXT.EMPLOYEES')}
                            name="selectEmployees"
                            value={t('GENERIC.TEXT.EMPLOYEES')}
                            selectedItemNameLength={13}
                            selectItemsToShowLength={1}
                            options={employees}
                            disabled={employeeSituations.length === 0 || employees.length === 0}
                            handleChange={handleChangeEmployees}
                            handleChanges={handleDeleteKeyEmployee}
                            handleBlur={handleBlur} />
                    </Row.Item>
                    <Row.Item sm={6} md={3} lg={3}>
                        <Select
                            medium
                            multiple
                            fullWidth
                            title={t('GENERIC.TEXT.OCCUPATIONS')}
                            name="selectOccupations"
                            value={t('GENERIC.TEXT.OCCUPATIONS')}
                            selectedItemNameLength={13}
                            selectItemsToShowLength={1}
                            options={occupations}
                            disabled={employeeSituations.length === 0 || occupations.length === 0}
                            handleChange={handleChangeOccupations}
                            handleChanges={handleDeleteKeyOccupation}
                            handleBlur={handleBlur} />
                    </Row.Item>
                    <Row.Item sm={6} md={3} lg={3}>
                        <Select
                            medium
                            multiple
                            fullWidth
                            title={t('GENERIC.TEXT.SHIFTS')}
                            name="selectWorkShifts"
                            value={t('GENERIC.TEXT.SHIFTS')}
                            selectedItemNameLength={13}
                            selectItemsToShowLength={1}
                            options={workShifts}
                            disabled={employeeSituations.length === 0 || workShifts.length === 0}
                            handleChange={handleChangeWorkShifts}
                            handleChanges={handleDeleteKeyWorkShift}
                            handleBlur={handleBlur} />
                    </Row.Item>
                    <Row.Item sm={6} md={3} lg={3}>
                        <Select
                            medium
                            multiple
                            fullWidth
                            title={t('GENERIC.TEXT.TYPES_OF_ABSENCES')}
                            name="selectSituationTypes"
                            value={t('GENERIC.TEXT.TYPES_OF_ABSENCES')}
                            selectedItemNameLength={13}
                            selectItemsToShowLength={1}
                            options={situationTypes}
                            disabled={employeeSituations.length === 0 || situationTypes.length === 0}
                            handleChange={handleChangeSituationTypes}
                            handleChanges={handleDeleteKeySituationTypes}
                            handleBlur={handleBlur} />
                    </Row.Item>
                    <Row.Item sm={6} md={3} lg={3}>
                        <Select
                            medium
                            multiple
                            fullWidth
                            title={t('GENERIC.TEXT.SCALES')}
                            name="selectScaleNames"
                            value={t('GENERIC.TEXT.SCALES')}
                            selectedItemNameLength={13}
                            selectItemsToShowLength={1}
                            options={scaleNames}
                            disabled={employeeSituations.length === 0 || scaleNames.length === 0}
                            handleChange={handleChangeScaleNames}
                            handleChanges={handleDeleteKeyScaleNames}
                            handleBlur={handleBlur} />
                    </Row.Item>
                </Row>
            </Panel>

            {employeeSituationsStatus === 'waiting' &&
                <Panel title={t('DASHBOARD.TEXT.EMPLOYEE_SITUATIONS')} noTitleBorder>
                    <Loading />
                </Panel>
            }

            {employeeSituationsStatus === 'emptyList' &&
                <Panel title={t('SHADOWCAT.EMPLOYEE_SITUATION.EMPLOYEE_SITUATIONS_DATES',undefined,startDate,endDate)} noTitleBorder>
                    <TextInformation
                        icon="search"
                        text={t('GENERIC.TEXT.NO_ABSENCES_FOUND')}
                        subText={t('GENERIC.TEXT.MODIFY_FILTERS')} />
                </Panel>
            }

            {employeeSituationsStatus === 'error' &&
                <Panel title={t('DASHBOARD.TEXT.EMPLOYEE_SITUATIONS')} noTitleBorder >
                    <TextInformation
                        icon="times"
                        text={t('ABSENCE.TEXT.ERROR_SEARCHING')}
                        subText={t('GENERIC.TEXT.MODIFY_FILTERS')}/>
                </Panel>
            }

            {employeeSituationsStatus === 'success' &&
                <Panel title={t('SHADOWCAT.EMPLOYEE_SITUATION.EMPLOYEE_SITUATIONS_DATES',undefined,startDate,endDate)} noTitleBorder noPadding>
                    <Row>
                        {permissions.includes(206) && 
                            <Row.Item sm={12} md={12} lg={12}>
                                <Container className={styles['button-report']}>
                                    <Button
                                        primary
                                        md
                                        title={t('GENERIC.BUTTON.DOWNLOAD.TEXT_WITH_ARGS', undefined, 'CSV')}
                                        icon="file-csv"
                                        loading={loadingButtonCSV}
                                        action={() => downloadCSVEmployeeSituations()} />
                                </Container>
                            </Row.Item>
                        }
                        <Row.Item sm={12} md={12} lg={12}>
                            <ListPagination list={filterEmployeeSituations} />
                        </Row.Item>
                    </Row>
                </Panel>
            }

        </Container>
    );

};

export default ListAllByInstance;