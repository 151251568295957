import API from "./core";

import { EmployeeSituation } from "../models/EmployeeSituation";

export const fetchEmployeeSituations = async (params?: any) => {
    try {
        return API.get('/rest/absencesAndHolidays/employeeSituations', { params })
            .then(
                response => {
                    if (String(response.data.status).toLowerCase() === 'error') {
                        return null;
                    } else {
                        return response.data.data;
                    }
                }, error => {
                    console.log(error);
                    return null;
                }
            )
    } catch (error) {
        return error;
    }
};

export const fetchEmployeeSituationsByEmployeeId = async (params?: any) => {
    try {
        return API.get('/rest/absencesAndHolidays/employeeSituations/listByEmployee', { params })
            .then(
                response => {
                    if (String(response.data.status).toLowerCase() === 'error') {
                        return null;
                    } else {
                        return response.data.data;
                    }
                }, error => {
                    console.log(error);
                    return null;
                }
            )
    } catch (error) {
        return error;
    }
};

export const postEmployeeSituation = async (params: EmployeeSituation) => {
    try {
        return API.post('/rest/absencesAndHolidays/employeeSituations', { ...params })
            .then(
                response => {
                    return response.data;
                }, error => {
                    console.log(error);
                    return null;
                }
            )
    } catch (error) {
        return error;
    }
};

export const putEmployeeSituation = async (id: string, params: EmployeeSituation) => {
    try {
        return API.put(`/rest/absencesAndHolidays/employeeSituations/${id}`, { ...params })
            .then(
                response => {
                    return response.data;
                }, error => {
                    console.log(error);
                    return null;
                }
            )
    } catch (error) {
        return error;
    }
};

export const deleteEmployeeSituation = async (id: string, params?: any) => {
    try {
        return API.delete(`/rest/absencesAndHolidays/employeeSituations/${id}`, { params })
            .then(
                response => {
                    if (String(response.data.status).toLowerCase() === 'error') {
                        return null;
                    } else {
                        return response.data;
                    }
                }, error => {
                    console.log(error);
                    return null;
                }
            )
    } catch (error) {
        return error;
    }
};

export const fetchAdditionalData = async () => {
    try {
        return API.get('/rest/absencesAndHolidays/employeeSituations/getAdditionalData', {})
            .then(
                response => {
                    if (String(response.data.status).toLowerCase() === 'error') {
                        return null;
                    } else {
                        return response.data.data;
                    }
                }, error => {
                    console.log(error);
                    return null;
                }
            )
    } catch (error) {
        return error;
    }
};

export const generateReportEmployeeSituations = async (params: any) => {
    try {
        return API.get('/rest/absencesAndHolidays/employeeSituations/downloadReportCsvEmployeeSituations', { params: params, headers: { 'Content-type': 'application-json' }, responseType: 'arraybuffer' })
            .then(
                response => {
                    if (String(response.data.status).toLowerCase() === 'error') {
                        return null;
                    } else {
                        return response.data;
                    }
                }, error => {
                    console.log(error);
                    return null;
                }
            );
    } catch (error) {
        return error;
    }
};