import React, { useEffect, useState } from 'react';
import ApplicationRouter from '../../../router';

import '../../../configs/awesomeFontLibrary';

import { Loading, Sidebar } from '@optimuminterfaces/revex-react-components/dist/components/index';
import { translation as t } from '@optimuminterfaces/revex-react-components/dist/translation/index';

import { fetchEnvironment, setEnvironment } from '../../../services/auth.services';

import './App.scss';
import styles from './App.module.scss';

export interface AppProps {
    history?: any;
    showMenu?: boolean;
    Container?: any
};

const renderSidebar = () => {

    return (
        <Sidebar>
            <Sidebar.Menu name="Dashboard" icon="tachometer-alt" url="#/absencesAndHolidaysMF" />
            <Sidebar.Menu name="Ausências" icon="exclamation-triangle">
                <Sidebar.SubMenu
                    icon="island-tropical"
                    url="#/absencesAndHolidaysMF/employeeSituations/listByEmployee/82026af7-ac7d-4d3c-ae5b-c17891b992c7"
                    title="Ausências do colaborador"
                    subtitle="Listagem das ausências do colaborador" />
                <Sidebar.SubMenu
                    icon="island-tropical"
                    url="#/absencesAndHolidaysMF/employeeSituations"
                    title="Ausências da Instância"
                    subtitle="Listagem das ausências dos colaboradores da Instância" />
                <Sidebar.SubMenu
                    icon="cog"
                    url="#/absencesAndHolidaysMF/situationTypes"
                    title="Tipos de Ausências"
                    subtitle="Listagem das dos tipos de ausêcias temporária da Instância" />
            </Sidebar.Menu>
            <Sidebar.Menu name="Atestados" icon="hospital" url="#/absencesAndHolidaysMF/medicalCertificates"></Sidebar.Menu>
            <Sidebar.Menu name="Feriados" icon="island-tropical">

            </Sidebar.Menu>
        </Sidebar>
    )

};

function App({ history, showMenu, Container = 'div' }: AppProps) {

    const [environmentLoaded, setEnvironmentLoaded] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                setEnvironmentLoaded(false);

                const jsonReturned = await fetchEnvironment();

                if (jsonReturned) {
                    setEnvironment(jsonReturned);
                    setEnvironmentLoaded(true);

                } else {
                    setEnvironment(true);
                }

            } catch (error) {
                console.log(error);
            }
        })();
    }, []);

    return (
        <>
            <div className={`${styles['app']} ${showMenu ? styles['sidebar'] : ''} shadowcat-app`}>
                {showMenu &&
                    <Container className={styles['container-sidebar']}>
                        {renderSidebar()}
                    </Container>
                }
                {environmentLoaded === true &&
                    <Container className={styles['container-body']}>
                        <ApplicationRouter history={history} />
                    </Container>
                }
                {environmentLoaded != true &&
                    <Loading text={t('GENERIC.TEXT.LOADING_MODULE_SETTINGS')} />
                }
            </div>
        </>
    )
};

export default App;