import moment from "moment";

import { formatLocalDate } from "@optimuminterfaces/revex-react-components/dist/utils/DateTimeUtil";

import { translation as t } from "@optimuminterfaces/revex-react-components/dist/translation";

export const isBeforeDate = (firstDate: string, secondDate: string, dateFormat = 'YYYY-MM-DD') => {
    let date1 = moment(firstDate, dateFormat);
    let date2 = moment(secondDate, dateFormat);

    if (date1.isBefore(date2)) {
        return true;
    } else {
        return false;
    }
};


export const isAfterYear = (firstDate: string, secondDate: string, separator = '-', year = 2000) => {
    //Não pode usar o moment pois ele não consegue trabalhar com anos de 1900, por isso foi realizado manualmente
    if (!!firstDate && (parseInt(firstDate.split(separator)[0]) < year)) {
        return true;
    }

    if (!!secondDate && (parseInt(secondDate.split(separator)[0]) < year)) {
        return true;
    }

    return false;
};

export const buildPeriondDescriptionTogether = (startDate: string,
    endDate: string | null | undefined,
    haveSchedule: boolean) => {

    let periods: string[] = buildPeriodDescription(
        startDate,
        endDate,
        haveSchedule);

    return `${periods[0]} - ${periods[1]}`;
};

export const buildPeriodDescription = (startDate: string,
    endDate: string | null | undefined,
    haveSchedule: boolean) => {

    let periods: string[] = buildPeriods(
        startDate,
        endDate,
        haveSchedule);

    return [`${t('GENERIC.TEXT.START')}: ${periods[0]}`, `${t('GENERIC.TEXT.END')}: ${periods[1]}`];
};

export const buildPeriods = (startDate: string,
    endDate: string | null | undefined,
    haveSchedule: boolean) => {

    let dateTimeFormat: string = haveSchedule ? 'DD/MM/yyyy HH:mm' : 'DD/MM/yyyy';

    let start = formatLocalDate(startDate, true, 'yyyy-MM-DDTHH:mm:ss.sss', dateTimeFormat);
    let end = !!endDate ? formatLocalDate(endDate, true, 'yyyy-MM-DDTHH:mm:ss.sss', dateTimeFormat) :
        t('SHADOWCAT.EMPLOYEE_SITUATION.UNINFORMED');

    return [start, end];
};