import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Button, DateTimePicker, Input, Radio, Row, Select, Toast } from '@optimuminterfaces/revex-react-components/dist/components';
import { formatLocalDate } from '@optimuminterfaces/revex-react-components/dist/utils/DateTimeUtil';
import { copyObjectJSON } from '@optimuminterfaces/revex-react-components/dist/utils/DeveloperUtil';

import { EmployeeSituation } from '../../../../models/EmployeeSituation';
import { SituationType } from '../../../../models/SituationType';
import { putEmployeeSituation } from '../../../../services/employeeSituations.services';
import { fetchSituationsTypes } from '../../../../services/situationTypes.services';
import { validateEmployeeSituation, setWindowShadowcatUpdateAbsence } from '../../../../utils/EmployeeSituationUtil';
import { buildSituationTypesSelect } from '../../../../utils/SituationTypeUtil';

import styles from './Edit.module.scss';

import {translation as t} from '@optimuminterfaces/revex-react-components/dist/translation/index'

interface EditProps {
    employeeSituation: EmployeeSituation;
    workingHoursList: SelectModel[];
    handleClose: Function;
    Container?: any;
};

interface SelectModel {
    key: string;
    value: string;
    selected: boolean;
}

interface ToastMessage {
    show: boolean;
    title: string;
    message: string;
    danger: boolean;
};

const initialOptionsWithoutTime = [
    { key: "1", value: t('ABSENCE.DURATION_OPTIONS.INFORM_END_DATE'), selected: true },
    { key: "2", value: t('ABSENCE.DURATION_OPTIONS.INFORM_DAYS'), selected: false },
    { key: "3", value: t('ABSENCE.DURATION_OPTIONS.NO_INFORM'), selected: false }
];

const initialOptionsWithTime = [
    { key: "1", value: t('ABSENCE.DURATION_OPTIONS.INFORM_END_DATE_AND_TIME'), selected: true },
    { key: "2", value: t('ABSENCE.DURATION_OPTIONS.INFORM_HOURS'), selected: false }
];

const Edit = ({ employeeSituation, workingHoursList, handleClose, Container = 'div' }: EditProps) => {

    const [employeeSituationEdit, setEmployeeSituationEdit] = useState<EmployeeSituation>(employeeSituation);
    const [situationTypes, setSituationTypes] = useState<SelectModel[]>([]);
    const [situationTypeSelected, setSituationTypeSelected] = useState<SituationType>();
    const [optionsWithoutTime, setOptionsWithoutTime] = useState(copyObjectJSON(initialOptionsWithoutTime));
    const [optionsWithTime, setOptionsWithTime] = useState(copyObjectJSON(initialOptionsWithTime));
    const [loadingButtonSave, setLoadingButtonSave] = useState<boolean>(false);
    const [workingHours, setWorkingHours] = useState<SelectModel[]>(workingHoursList);
    const [toastAlert, setToastAlert] = useState<ToastMessage>({
        show: false,
        title: '',
        message: '',
        danger: false
    });

    useEffect(() => {
        if (!!employeeSituation) {
            let employeeSituationAux: EmployeeSituation = employeeSituation;
            let dateTimeFormat = employeeSituation.haveSchedule ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD';

            employeeSituationAux.startDate = formatLocalDate(employeeSituation.startDate, true, 'YYYY-MM-DDTHH:mm:ss.sss', dateTimeFormat);

            if (!!employeeSituation.endDate) {
                employeeSituationAux.endDate = formatLocalDate(employeeSituation.endDate, true, 'YYYY-MM-DDTHH:mm:ss.sss', dateTimeFormat);
            }

            // default values
            employeeSituationAux.endDateDays = 1;
            employeeSituationAux.endDateHours = 1;

            setEmployeeSituationEdit(employeeSituationAux);

            (async () => {
                try {
                    const jsonReturned = await fetchSituationsTypes({ showDisabled: false });

                    if (jsonReturned) {
                        let situationTypesInDataBase: SituationType[] = jsonReturned.situationTypes;
                        let buildSelect = buildSituationTypesSelect(situationTypesInDataBase, employeeSituation.situationType?.id);
                        setSituationTypes(buildSelect);

                        if (!!employeeSituation.situationType) {
                            setSituationTypeSelected(employeeSituation.situationType);
                        }

                    } else {
                        setSituationTypes([]);
                    }

                } catch (error) {
                    console.log(error);
                    setSituationTypes([]);
                }
            })();

            setEmployeeSituationEdit(copyObjectJSON(employeeSituationAux));
        }
    }, [employeeSituation]);

    const handleSave = async () => {
        setLoadingButtonSave(true);

        let toast: ToastMessage = {
            show: true,
            title: t('SHADOWCAT.EMPLOYEE_SITUATION.VALIDATION_ERROR'),
            message: "",
            danger: true
        };

        if (!!employeeSituationEdit && !!employeeSituationEdit.id) {
            let messageReturn = validateEmployeeSituation(employeeSituationEdit, true);

            if (!!messageReturn) {
                toast.message = messageReturn;
            } else {
                try {
                    const jsonReturned = await putEmployeeSituation(employeeSituationEdit.id, employeeSituationEdit);

                    if (String(jsonReturned.status).toLowerCase() === 'success') {
                        toast.danger = false;
                        toast.title = t('GENERIC.TEXT.OPERATION_PERFORMED');

                        // Sinaliza atualização de dados no Revex
                        setWindowShadowcatUpdateAbsence(true);
                    }

                    toast.message = jsonReturned.message;
                } catch (error) {
                    console.log(error);
                    toast.message = t('SHADOWCAT.EMPLOYEE_SITUATION.ERROR_TRYING_EDIT');
                }
            }
        } else {
            toast.message = t('SHADOWCAT.EMPLOYEE_SITUATION.FILL_REQUIRED_FIELDS');
        }

        setToastAlert(toast);

        if (!toast.danger) {
            setTimeout(() => {
                handleClose(true);
            }, 5000);
        } else {
            setLoadingButtonSave(false);
        }
    };

    const handleChange = (e?: React.ChangeEvent<HTMLInputElement> | undefined, name?: string, value?: string | number | boolean) => {
        if (e) {
            const target = e.target;
            value = target.type === 'checkbox' ? target.checked : (target.type === 'number' && !!target.value) ? parseInt(target.value) : target.value;
            name = target.name;
        }

        setEmployeeSituationEdit({ ...employeeSituationEdit!, [name!]: value });
    };

    const handleChangeSelectSituationTypes = (key: string) => {
        for (var i = 0; i < situationTypes.length; i++) {
            if (situationTypes[i].key === key) {
                situationTypes[i].selected = true;

                let situationType: SituationType = {
                    id: key,
                    name: situationTypes[i].value,
                    legend: null,
                    disabled: false
                };

                setSituationTypeSelected(situationType);
                setEmployeeSituationEdit({ ...employeeSituationEdit!, situationType: situationType });
            } else {
                situationTypes[i].selected = false;
            }
        }
    };

    const handleChangeSelectWorkingHours = (key: string) => {
        for (var i = 0; i < workingHours.length; i++) {
            if (workingHours[i].key === key) {
                workingHours[i].selected = true;
                handleChange(undefined, 'dailyHours', key);
            } else {
                workingHours[i].selected = false;
            }
        }
    };

    const handleChangeRadioWithTime = (key: string) => {
        let options = copyObjectJSON(optionsWithTime);

        for (var i = 0; i < options.length; i++) {
            if (options[i].key === key) {
                options[i].selected = true;
                handleChange(undefined, "typeCalculateEnd", key);
            } else {
                options[i].selected = false;
            }
        }

        setOptionsWithTime(copyObjectJSON(options));
    };

    const handleChangeRadioWithoutTime = (key: string) => {
        let options = copyObjectJSON(optionsWithoutTime);

        for (var i = 0; i < options.length; i++) {
            if (options[i].key === key) {
                options[i].selected = true;
                handleChange(undefined, "typeCalculateEnd", key);
            } else {
                options[i].selected = false;
            }
        }

        setOptionsWithoutTime(copyObjectJSON(options));
    };

    const renderForm = (haveSchedule: boolean) => {
        if (haveSchedule) {
            return renderAbsenceWithTime();
        } else {
            return renderAbsenceWithoutHours();
        }
    };

    const renderAbsenceWithoutHours = () => {
        return (
            <Container className={styles['form-employee-situation']}>
                <Row>
                    <Row.Item sm={12} md={12} lg={12}>
                        <DateTimePicker inline
                            title={t('SHADOWCAT.EMPLOYEE_SITUATION.START_DATE_ABSENCE')}
                            name="startDate"
                            value={!!employeeSituationEdit && !!employeeSituationEdit.startDate ? employeeSituationEdit?.startDate : null}
                            type="date"
                            mask
                            handleChange={(e) => { handleChange(undefined, "startDate", e) }} />
                    </Row.Item>

                    <Row.Item sm={12} md={12} lg={12}>
                        <Radio inlineAll 
                            small={false}
                            medium
                            name="selectTypeEndAbsence"
                            title={t('GENERIC.TEXT.DURATION')}
                            options={optionsWithoutTime}
                            handleChange={(e) => { handleChangeRadioWithoutTime(e.target.id) }}
                            disableAll={false} />
                    </Row.Item>

                    {!!optionsWithoutTime && optionsWithoutTime[0].selected &&
                        <Row.Item sm={12} md={12} lg={12}>
                            <DateTimePicker inline
                                title={t('SHADOWCAT.EMPLOYEE_SITUATION.END_DATE_ABSENCE')}
                                name="endDate"
                                value={!!employeeSituationEdit && !!employeeSituationEdit.endDate ? employeeSituationEdit?.endDate : null}
                                type="date"
                                mask
                                disabled={!!employeeSituationEdit && !!employeeSituationEdit?.startDate ? false : true}
                                handleChange={(e) => { handleChange(undefined, "endDate", e) }} />
                        </Row.Item>
                    }

                    {!!optionsWithoutTime && optionsWithoutTime[1].selected &&
                        <Row.Item sm={12} md={12} lg={12}>
                            <Input
                                id="endDateDays" 
                                medium 
                                inline
                                name="endDateDays"
                                title={t('SHADOWCAT.EMPLOYEE_SITUATION.DAYS_TO_THE_END')}
                                value={employeeSituationEdit?.endDateDays}
                                type="number"
                                minValue={1}
                                maxValue={2000}
                                status={!!employeeSituationEdit?.endDateDays && employeeSituationEdit.endDateDays > 0 ? "" : "error"}
                                disabled={!!employeeSituationEdit && !!employeeSituationEdit?.startDate ? false : true}
                                handleChange={(e) => handleChange(e)} />
                        </Row.Item>
                    }
                </Row>
            </Container>
        )
    };

    const renderAbsenceWithTime = () => {
        return (
            <Container className={styles['form-employee-situation']}>
                <Row>
                    <Row.Item sm={12} md={12} lg={12}>
                        <DateTimePicker inline
                            title={t('SHADOWCAT.EMPLOYEE_SITUATION.START_DATE_AND_TIME')}
                            name="startDate"
                            value={!!employeeSituationEdit && !!employeeSituationEdit.startDate ? employeeSituationEdit?.startDate : null}
                            type="datetime"
                            mask
                            noSeconds
                            handleChange={(e) => { handleChange(undefined, "startDate", e) }} />
                    </Row.Item>

                    <Row.Item sm={12} md={12} lg={12}>
                        <Radio inlineAll 
                            small={false}
                            medium
                            name="selectTypeEndAbsence"
                            title={t('GENERIC.TEXT.DURATION')}
                            options={optionsWithTime}
                            handleChange={(e) => { handleChangeRadioWithTime(e.target.id) }}
                            disableAll={false} />
                    </Row.Item>

                    {!!optionsWithTime && optionsWithTime[0].selected &&
                        <Row.Item sm={12} md={12} lg={12}>
                            <DateTimePicker inline
                                title={t('SHADOWCAT.EMPLOYEE_SITUATION.END_DATE_AND_TIME')}
                                name="endDate"
                                value={!!employeeSituationEdit && !!employeeSituationEdit.endDate ? employeeSituationEdit?.endDate : null}
                                type="datetime"
                                mask
                                noSeconds
                                disabled={!!employeeSituationEdit && !!employeeSituationEdit?.startDate ? false : true}
                                handleChange={(e) => { handleChange(undefined, "endDate", e) }} />
                        </Row.Item>
                    }

                    {!!optionsWithTime && optionsWithTime[1].selected &&
                        <Row.Item sm={12} md={12} lg={12}>
                            <Input 
                                id="endDateHours"
                                medium 
                                inline
                                name="endDateHours"
                                title={t('SHADOWCAT.EMPLOYEE_SITUATION.HOURS_TO_THE_END')}
                                value={employeeSituationEdit?.endDateHours}
                                type="number"
                                minValue={1}
                                maxValue={24}
                                status={!!employeeSituationEdit?.endDateHours && employeeSituationEdit.endDateHours > 0 ? "" : "error"}
                                disabled={!!employeeSituationEdit && !!employeeSituationEdit?.startDate ? false : true}
                                handleChange={(e) => handleChange(e)} />
                        </Row.Item>
                    }
                </Row>
            </Container>
        )
    };

    return (
        <Container className={styles['employee-situation-edit-rt']}>
            <Row>
                <Row.Item sm={12} md={12} lg={12}>
                    <Select medium fullWidth
                        name="situationTypes"
                        title={t('ABSENCE.TEXT.ABSENCE_TYPE')}
                        options={situationTypes!}
                        value={situationTypeSelected?.name}
                        disabled={!!situationTypes && situationTypes.length > 0 ? false : true}
                        handleChange={handleChangeSelectSituationTypes} />
                </Row.Item>

                <Row.Item sm={12} md={12} lg={12}>
                    {renderForm(employeeSituation ? employeeSituation.haveSchedule : false)}
                </Row.Item>

                {!employeeSituationEdit.haveSchedule &&
                    <Row.Item sm={12} md={12} lg={12}>
                        <Container className={styles['daily-hours-edit-scale']}>
                            <Select medium fullWidth
                                name="dailyHours"
                                title={t('SHADOWCAT.EMPLOYEE_SITUATION.EDIT_ABSENCE_INFO')}
                                options={workingHours}
                                value={employeeSituationEdit.dailyHours}
                                disabled={!!workingHours && workingHours.length > 0 ? false : true}
                                handleChange={handleChangeSelectWorkingHours} />
                        </Container>
                    </Row.Item>
                }

                <Row.Item sm={12} md={12} lg={12}>
                    <Container className={styles['group-buttons']}>
                        <Button danger md
                            title={t('GENERIC.BUTTON.CANCEL.TEXT')}
                            icon="times"
                            className={styles['button-action']}
                            action={() => handleClose(false)} />
                        <Button primary md
                            title={t('GENERIC.BUTTON.SAVE.TEXT')}
                            icon="save"
                            className={styles['button-action']}
                            loading={loadingButtonSave}
                            action={() => handleSave()} />
                    </Container>
                </Row.Item>

            </Row>

            {!!toastAlert && toastAlert.show &&
                <Toast
                    danger={toastAlert.danger}
                    success={!toastAlert.danger}
                    icon={toastAlert.danger ? "times" : "check"}
                    title={toastAlert.title}
                    positionTopRight
                    message={toastAlert.message}
                    closeChange={() => setToastAlert({ ...toastAlert, show: false })}
                    showTime={5000} />
            }
        </Container>
    );

};

Edit.propTypes = {
    employeeSituation: PropTypes.object.isRequired,
    workingHoursList: PropTypes.arrayOf(PropTypes.object).isRequired,
    handleClose: PropTypes.func.isRequired
};

export default Edit;