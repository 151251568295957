import API from './core';

export const fetchAllWorkingHoursByInstanceId = async () => {
    try {
        return API.get('/rest/absencesAndHolidays/employeeSituations/workingHours', {})
            .then(
                response => {
                    if (String(response.data.status).toLowerCase() === 'error') {
                        return null;
                    } else {
                        return response.data.data;
                    }
                }, error => {
                    console.log(error);
                    return null;
                }
            );
    } catch (error) {
        console.log(error);
        return null;
    }
};