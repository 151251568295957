import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';

import { translation as t } from "@optimuminterfaces/revex-react-components/dist/translation";

import { formatLocalDate } from "@optimuminterfaces/revex-react-components/dist/utils/DateTimeUtil";

import { Button, DateTimePicker, ImportPicture, Input, Row, Select, Switch, TextArea, Toast } from "@optimuminterfaces/revex-react-components/dist/components";

import { Cid } from "../../../../models/Cid";
import { MedicalCertificate } from "../../../../models/MedicalCertificate";

import { putMedicalCertificate } from "../../../../services/medicalCertificates.services";

import { validateMedicalCertificateData } from "../../../../utils/MedicalCertificateUtil";

import styles from './Edit.module.scss';

interface EditProps {
    Container?: any;
    medicalCertificateToEdit: MedicalCertificate;
    listCids: SelectProps[];
    handleClose: Function;
}

interface SelectProps {
    key: string;
    value: string;
    selected: boolean;
}

interface Description {
    description: string;
    type: string;
    created: string;
    createdBy: string;
    userName: string;
}

interface ToastMessage {
    show: boolean;
    title: string;
    message: string;
    danger: boolean;
}

const Edit = ({
    Container = 'div',
    medicalCertificateToEdit,
    listCids,
    handleClose
}: EditProps) => {

    const [medicalCertificate, setMedicalCertificate] = useState<MedicalCertificate>(medicalCertificateToEdit);
    const [haveCid, setHaveCid] = useState<boolean>(false);
    const [cids, setCids] = useState<SelectProps[]>(listCids);
    const [cidSelected, setCidSelected] = useState<Cid | null>(null);
    const [newPhoto, setNewPhoto] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [toastAlert, setToastAlert] = useState<ToastMessage>({
        show: false,
        title: '',
        message: '',
        danger: false
    });

    useEffect(() => {
        let md: MedicalCertificate = medicalCertificateToEdit;

        // Período
        let dateTimeFormat = medicalCertificateToEdit.haveSchedule ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD';

        md.startDate = formatLocalDate(
            medicalCertificateToEdit.startDate,
            true,
            'YYYY-MM-DDTHH:mm:ss.sss',
            dateTimeFormat);

        md.endDate = formatLocalDate(
            medicalCertificateToEdit.endDate,
            true,
            'YYYY-MM-DDTHH:mm:ss.sss',
            dateTimeFormat);

        // Descrição
        let descriptions: Description[] = medicalCertificateToEdit.descriptionMap.descriptions;
        descriptions = descriptions.filter(description => description.type === 'CREATION' || description.type === 'EDIT');
        md.description = descriptions[descriptions.length - 1].description;

        setMedicalCertificate(md);

        if (!!medicalCertificateToEdit.cid) {
            handleChangeCid(medicalCertificateToEdit.cid.id);
            setHaveCid(true);

        } else {
            // Caso a lista seja enviada com algum valor selecionado
            handleChangeCid('');
        }
    }, []);

    const updateMedicalCertificate = async (medicalCertificateToSave: MedicalCertificate) => {
        setLoading(true);

        let toast: ToastMessage = {
            show: true,
            title: t('GENERIC.TEXT.ERROR_OCURRED'),
            message: t('GENERIC.TEXT.ERROR_OCURRED'),
            danger: true
        };

        try {
            const returnValidateData: string | null = validateMedicalCertificateData(medicalCertificateToSave, true);

            if (returnValidateData === null) {
                const jsonReturned = await putMedicalCertificate(
                    medicalCertificateToSave.id!,
                    medicalCertificateToSave);

                if (jsonReturned) {
                    if (String(jsonReturned.status).toLowerCase() === 'success') {
                        toast.danger = false;
                        toast.title = t('GENERIC.TEXT.OPERATION_PERFORMED');
                        toast.message = '';
                        setToastAlert(toast);
                        setTimeout(() => {
                            medicalCertificateToSave.medicalCertificateStatus = jsonReturned.data.medicalCertificateStatus;
                            medicalCertificateToSave.description = jsonReturned.data.description;
                            medicalCertificateToSave.descriptionMap = jsonReturned.data.descriptionMap;
                            medicalCertificateToSave.fileName = jsonReturned.data.fileName;
                            medicalCertificateToSave.fileContentType = jsonReturned.data.fileContentType;
                            handleClose(medicalCertificateToSave);
                        }, 5000);

                    } else {
                        toast.message = jsonReturned.message;
                        setToastAlert(toast);
                        setLoading(false);
                    }

                } else {
                    setToastAlert(toast);
                    setLoading(false);
                }

            } else {
                toast.message = returnValidateData;
                setToastAlert(toast);
                setLoading(false);
            }

        } catch (error) {
            setToastAlert(toast);
            setLoading(false);
            console.log(error);
        }
    };

    const handleChangeEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
        const target = e.target;
        const name = target.name;
        let value;

        if (target.type === 'checkbox') {
            value = target.checked;

        } else if (target.type === 'number' && !!target.value) {
            value = parseInt(target.value);

        } else {
            value = target.value;
        }

        handleChange(name, value);
    };

    const handleChangeImportPicture = ({ name, value }: any) => {
        try {
            if (name === 'ImportPicture.imgBase64') {
                setMedicalCertificate(medicalCertificate => ({ ...medicalCertificate!, file: value }));
            }else if (name === 'ImportPicture.file') {
                setMedicalCertificate(medicalCertificate => ({ ...medicalCertificate!, fileName: value.name }));
            }

        } catch (error) {
            console.log(error);
        }
    };

    const handleChange = (name: string, value: string | number | boolean | Cid | null) => {
        setMedicalCertificate({ ...medicalCertificate, [name]: value });
    };

    const handleChangeHaveCid = (checkHaveCid: boolean) => {
        if (!checkHaveCid) {
            handleChangeCid('');
        }

        setHaveCid(checkHaveCid);
    };

    const handleChangeCid = (key: string) => {
        let list: SelectProps[] = cids;
        let listContainsKey: boolean = false;

        list.forEach(item => {
            if (key === item.key) {
                listContainsKey = true;
                item.selected = true;

                let cidDescription = item.value.split(' - ');

                let cid: Cid = {
                    id: item.key,
                    code: cidDescription[0],
                    description: cidDescription[1]
                };

                setCidSelected(cid);
                handleChange('cid', cid);

            } else {
                item.selected = false;
            }
        });

        if (!listContainsKey) {
            setCidSelected(null);
            handleChange('cid', null);
        }

        setCids(list);
    };

    return (
        <Container className={styles['medical-certificate-edit-rt']}>
            {!!medicalCertificate &&
                <Row>
                    <Row.Item sm={12} md={12} lg={12}>
                        <Row>
                            <Row.Item sm={12} md={6} lg={6}>
                                <DateTimePicker
                                    inline
                                    fullWidth
                                    title={t('GENERIC.TEXT.START_DATE')}
                                    name="startDate"
                                    value={!!medicalCertificate && !!medicalCertificate.startDate ? medicalCertificate?.startDate : null}
                                    type={medicalCertificate?.haveSchedule ? "datetime" : "date"}
                                    mask
                                    noSeconds
                                    handleChange={(e) => { handleChange("startDate", e) }} />
                            </Row.Item>

                            <Row.Item sm={12} md={6} lg={6}>
                                <DateTimePicker
                                    inline
                                    fullWidth
                                    title={t('GENERIC.TEXT.END_DATE')}
                                    name="endDate"
                                    value={!!medicalCertificate && !!medicalCertificate.endDate ? medicalCertificate?.endDate : null}
                                    type={medicalCertificate?.haveSchedule ? "datetime" : "date"}
                                    mask
                                    noSeconds
                                    handleChange={(e) => { handleChange("endDate", e) }} />
                            </Row.Item>
                        </Row>
                    </Row.Item>

                    <Row.Item sm={12} md={12} lg={12}>
                        <Input
                            id="professionalName"
                            type="text"
                            medium
                            fullWidth
                            name="professionalName"
                            title={t('SHADOWCAT.MEDICAL_CERTIFICATE.GENERIC.PROFESSIONAL_NAME')}
                            placeholder={t('SHADOWCAT.MEDICAL_CERTIFICATE.GENERIC.PROFESSIONAL_NAME')}
                            value={medicalCertificate?.professionalName || ''}
                            handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                e?.preventDefault?.();
                                handleChangeEvent(e);
                            }} />
                    </Row.Item>

                    <Row.Item sm={12} md={12} lg={12}>
                        <Input
                            id="countyNumber"
                            type="text"
                            medium
                            fullWidth
                            name="countyNumber"
                            title={t('SHADOWCAT.MEDICAL_CERTIFICATE.GENERIC.PROFESSIONAL_REGISTRATION')}
                            placeholder={t('SHADOWCAT.MEDICAL_CERTIFICATE.GENERIC.PROFESSIONAL_REGISTRATION')}
                            value={medicalCertificate?.countyNumber || ''}
                            handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                e?.preventDefault?.();
                                handleChangeEvent(e);
                            }} />
                    </Row.Item>

                    <Row.Item sm={12} md={12} lg={12}>
                        <Switch
                            sm
                            name="haveCid"
                            text={t('SHADOWCAT.MEDICAL_CERTIFICATE.NEW.HAVE_CID_QUESTION')}
                            value={haveCid}
                            iconOff="times"
                            iconOn="check"
                            colorOn="#3b51ed"
                            handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeHaveCid(e.target.checked)} />
                    </Row.Item>

                    {haveCid &&
                        <Row.Item sm={12} md={12} lg={12}>
                            <Select
                                medium
                                fullWidth
                                title={t('SHADOWCAT.MEDICAL_CERTIFICATE.GENERIC.CID')}
                                name="selectCids"
                                value={cidSelected != null ? `${cidSelected.code} - ${cidSelected.description}` : ''}
                                options={cids}
                                disabled={cids.length === 0 ? true : false}
                                handleChange={handleChangeCid} />
                        </Row.Item>
                    }

                    <Row.Item sm={12} md={12} lg={12}>
                        <TextArea
                            name="description"
                            title={t('GENERIC.TEXT.DESCRIPTION')}
                            placeholder={t('GENERIC.TEXT.DESCRIPTION')}
                            fullWidth
                            rows={5}
                            value={!!medicalCertificate && !!medicalCertificate.description ? medicalCertificate.description : ''}
                            handleChange={(e) => handleChangeEvent(e)} />
                    </Row.Item>

                    <Row.Item sm={12} md={12} lg={12}>
                        <Switch
                            sm
                            name="newPhoto"
                            text={t('SHADOWCAT.MEDICAL_CERTIFICATE.EDIT.NEW_IMAGE_QUESTION')}
                            value={newPhoto}
                            iconOff="times"
                            iconOn="check"
                            colorOn="#3b51ed"
                            handleChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewPhoto(e.target.checked)} />
                    </Row.Item>

                    {newPhoto &&
                        <Row.Item sm={12} md={12} lg={12}>
                            <Container className={styles['select-photo']}>
                                <ImportPicture 
                                    accept={'image/*,application/pdf'} 
                                    canvasWidth={400}
                                    canvasHeight={500}
                                    croppedImageWidth={400}
                                    croppedImageHeigth={500}
                                    imagePreviewWidth={400}
                                    imagePreviewHeight={500}
                                    imageSelectedHeight={500}
                                    showButtons={false}
                                    handleChanges={handleChangeImportPicture} />
                            </Container>
                        </Row.Item>
                    }

                    <Row.Item sm={12} md={12} lg={12}>
                        <Container className={styles['button-actions']}>
                            <Button
                                danger
                                md
                                title={t('GENERIC.BUTTON.CANCEL.TEXT')}
                                icon="times"
                                loading={loading}
                                action={() => handleClose()} />

                            <Button
                                primary
                                md
                                title={t('GENERIC.BUTTON.SAVE.TEXT')}
                                icon="save"
                                className={styles['last-button']}
                                loading={loading}
                                action={() => updateMedicalCertificate(medicalCertificate)} />
                        </Container>
                    </Row.Item>

                </Row>
            }

            {!!toastAlert && toastAlert.show &&
                <Toast
                    danger={toastAlert.danger}
                    success={!toastAlert.danger}
                    icon={toastAlert.danger ? "times" : "check"}
                    title={toastAlert.title}
                    positionTopRight
                    message={toastAlert.message}
                    closeChange={() => setToastAlert({ ...toastAlert, show: false })}
                    showTime={5000} />
            }
        </Container>
    );

};

Edit.propTypes = {
    medicalCertificateToEdit: PropTypes.object.isRequired,
    listCids: PropTypes.arrayOf(PropTypes.object).isRequired,
    handleClose: PropTypes.func.isRequired
};

export default Edit;